import { Component } from '@angular/core';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'reset-password.component.html'
})
export class ResetPasswordComponent {

  formsubmitted = false;
  passwordformsubmitted = false;
  editPasswordForm: FormGroup;
  email: any;

  verification_code:any;
  constructor(private router: Router, private apiService: ApiService, private activatedRoute: ActivatedRoute) {
    this.email = localStorage.getItem('email_id');
    this.verification_code = this.activatedRoute.snapshot.paramMap.get('id');

  }

  ngOnInit() {
    this.editPasswordForm = new FormGroup({
      email: new FormControl(''),
      c_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)]),
      conPwd: new FormControl('', [Validators.required, this.checkPasswords.bind(this), Validators.minLength(6), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)])
    })
    this.editPasswordForm.controls.c_password.valueChanges.subscribe(() => {

      if (
        this.editPasswordForm &&
        (this.editPasswordForm.controls.c_password.value !== this.editPasswordForm.controls.conPwd.value)
      ) {
        this.editPasswordForm.get('conPwd').setErrors({passwordNotMatch: true}) ;
      }
    });
    this.verifiedCode();

  }
  private checkPasswords(control: FormControl): { [s: string]: boolean } {
    if (
      this.editPasswordForm &&
      (control.value !== this.editPasswordForm.controls.c_password.value)
    ) {
      return { passwordNotMatch: true };
    }
    return null;
  }
  get p() {
    return this.editPasswordForm.controls;
  }
  verifiedCode() {

    var data = {verified_code:this.verification_code};
    this.apiService.postwithouttoken('verifyuser', data)
      .subscribe(
        result => {
          if (result['success']) {
            let data = result['user'];
            if (data != null) {
              this.editPasswordForm.patchValue({'email': data.c_email_id});
              localStorage.setItem('email_id', data.c_email_id);
            } else {
              this.apiService.toastrMsg('User not found', 'error');
              this.router.navigate(['/']);
            }
          } else {
            this.router.navigate(['/']);
          }
        },
        fail => {
          this.router.navigate(['/']);
        }
      );
  }

  // Function to save Setting
  saveSetting() {
    this.passwordformsubmitted = true;

    if (this.editPasswordForm.invalid) {
      return;
    }
    var data = this.editPasswordForm.value;
    this.apiService.postwithouttoken('resetpassword', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.apiService.toastrMsg(result['message'], 'success');
            localStorage.clear();
            this.router.navigate(['/']);

          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

}
