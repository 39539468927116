import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConstants } from '../../constants';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {StripeCardElementOptions} from '@stripe/stripe-js';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @ViewChild('owlMac') owlMac: CarouselComponent;
  @ViewChild('owlCat') owlCat: CarouselComponent;

  formsubmitted = false;
  paymentForm: FormGroup;
  countriesList: any;
  stateList: any;
  assessmentInfo: any;
  priceInfo: any;
  number_of_license: any;
  customer_id: any;
  private url = AppConstants.imageURL;
  private websales_id = AppConstants.websales_id;
  current_year = new Date().getFullYear();
  years = [];
  months = [];
  customerInfo: any;
  assessment_id: any;
  customerMeta: any;
  clicked = false;
  days: any;
  count: any;

  categoriesOptions: any = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    stagePadding: 2,
    autoplay: false,
    autoWidth: false,
    margin: 25,
    startPosition: 1,
    nav: true,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    dotsSpeed: 600,
    responsive: {
      0: {
        items: 4,
        loop: true,
      },
      400: {
        items: 4,
        loop: true,
      },
      740: {
        items: 4,
        loop: true,
      },
      940: {
        items: 4,
        loop: true,
      }
    }
  };

  carouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    startPosition: 1,
    autoplay: true,
    dotsSpeed: 600,
    center: true,
    items: 1,

    // responsive: {
    //   0: {
    //     items: 1,
    //     loop: true,
    //   },
    //   400: {
    //     items: 1,
    //     loop: true,
    //   },
    //   740: {
    //     items: 1,
    //     loop: true,
    //   },
    //   940: {
    //     items: 1,
    //     loop: true,
    //   }
    nav: true
    // }
  };

  categories: any = {
    items: [{
      id: 'slide-1',
      displayedName: 'Slide 1'
    },
      {
        id: 'slide-2',
        displayedName: 'Slide 2'
      },
      {
        id: 'slide-3',
        displayedName: 'Slide 3'
      },
      {
        id: 'slide-4',
        displayedName: 'Slide 4'
      },
      {
        id: 'slide-5',
        displayedName: 'Slide 5'
      },
      // {
      //   id: 'slide-6',
      //   displayedName: 'Slide 6'
      // },
      // {
      //   id: 'slide-7',
      //   displayedName: 'Slide 7'
      // },
      // {
      //   id: 'slide-8',
      //   displayedName: 'Slide 8'
      // }
    ]
  };

  title = 'owl-carousel-libdemo';
  owlNext = '→';
  owlPrev = '←';

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    // items: '10',
    margin: 55,
    // slideBy: 'page',
    merge: true,
    autoplay: false,
    autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    autoplaySpeed: 2000,
    dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    smartSpeed: 400,

    // fluidSpeed: 499,
    dragEndSpeed: 350,
    // dotsEach: 4,
    center: true,

    // rewind: true,
    // rtl: true,
    startPosition: 1,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }

    },
    //stagePadding: 100,
    nav: true
  }
  activeSlides: any;
  city: any;
  state: any;
  country: any;
  currency: any;
  price_id: any;


  // payment options
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        lineHeight: '30px',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }

      }
    }
  };
  stripeKey = '';
  error: any;
  element: any;
  complete = false;
  returnUrl:any;
  validitydays:any;
  taxDetails:any;
  sgst:any;
  cgst:any;
  validity_days:any;
  constructor( private spinner: NgxSpinnerService,private router: Router, private apiService: ApiService, private stripeService: StripeService) {
    if (this.apiService.checkTokenExpiry()) {
      this.router.navigate(['/']);
    }
    this.customer_id = localStorage.getItem('customer_id');
    this.assessment_id = localStorage.getItem('assessment_id');
    this.price_id = localStorage.getItem('price_id');
    this.getAssessmentByid(this.assessment_id);
    this.getCustomerById(this.customer_id);
    this.number_of_license = localStorage.getItem('number_of_license');

    var currentYear = new Date().getFullYear();
    for (var i = currentYear; i <= currentYear + 20; i++) {
      this.years.push(i);
    }

  }

  slideTo(category: string) {
    this.owlMac.moveByDot(category);
  }

  changeSlide($event) {
    if (this.owlCat) {
      // this.category$.next($event.slides[0].id);
      this.owlCat.moveByDot(this.owlCat.dotsData.dots[$event.startPosition].id);
    }
  }

  goBack(){
    window.history.back();
  }

  ngOnInit() {
    AOS.init({
      once: true
    });
    this.paymentForm = new FormGroup({
      c_name: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      // last_name: new FormControl('', [Validators.required]),
      c_email_id: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      zip_code: new FormControl('', [Validators.required]),
      country_id: new FormControl('', [Validators.required]),
      gstn: new FormControl('', [Validators.required, Validators.pattern('[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}')]),
      state: new FormControl('', [Validators.required]),
      cardholder_name: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      accept_terms: new FormControl(false, [Validators.required]),
      currency: new FormControl(''),
    })
    this.CountryList();
    this.getassessmentSettingDetails();
  }

  get f() {
    return this.paymentForm.controls;
  }

  getPassedData(data: any) {
    this.activeSlides = data;
  }

  async  createPayment(){
    this.formsubmitted = true;
    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }
    if (!this.paymentForm.value.accept_terms) {
      this.apiService.toastrMsg('Please Accept the Recruitonic Terms and Service', 'error');
      return;
    }
    this.clicked = true;

    const name = this.paymentForm.value.cardholder_name;
    const email = this.paymentForm.value.c_email_id;
    const postal_code = this.paymentForm.value.zip_code;
    const currency = this.paymentForm.value.currency;
    console.log(currency,'ccc');
    // var formatter = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: currency,
    // });
    // const amount = formatter.format(this.priceInfo.rate);
    this.spinner.show();
    const amount = (this.priceInfo.rate)*100;
     this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          let data = {amount:amount, currency: currency?currency:'INR'
                     ,stripeToken: result.token.id
                     ,email:email
                     ,postal_code:postal_code
                     ,city:''
                     ,state:this.state
                     ,country:this.country
          };

          this.apiService.postwithouttoken('genratepaymentintent',data).subscribe(value => {
            if(value['success']){
                 this.savePaymentInfo(value['payment'].id);
            }else{
              this.spinner.hide();
              this.clicked = false;
              this.apiService.toastrMsg(value['error'], 'error');

            }

          },
            fail => {
              this.spinner.hide();
              this.clicked = false;
              this.apiService.toastErrorMsg(fail);
            });

        } else if (result.error) {
          // Error creating the token
          this.spinner.hide();
          this.clicked = false;
          this.apiService.toastrMsg(result.error.message, 'error');
        }
      });

  }
  savePaymentInfo(paymetID) {
    let svalid_upto = new Date();
    if(this.validity_days){
      svalid_upto.setDate(svalid_upto.getDate() + (this.validity_days ? parseInt(this.validity_days) : 1));

    }else {
      svalid_upto.setDate(svalid_upto.getDate() + (this.validitydays ? parseInt(this.validitydays.meta_value) : 1));
    }
    // var data = this.paymentForm.value;

    let s_sgst = 0;
    let s_cgst = 0;

    if(this.priceInfo){
      let tax  =  this.taxDetails.find(a=>a.country_id==this.priceInfo.country_id);
      this.priceInfo.tax_p = tax?tax.tax_rate:0;
      this.priceInfo.tax_rate = 0;
      let amount = this.priceInfo.rate;
      let taxamount = amount * (100 / (100 + parseInt(this.priceInfo.tax_p)));
      this.priceInfo.tax_rate=amount-taxamount;
      this.priceInfo.tax_rate=this.priceInfo.tax_rate.toFixed(0);
      s_sgst = this.priceInfo.tax_rate/2;
      s_cgst = this.priceInfo.tax_rate/2;

    }

    var data = {
      payment: this.paymentForm.value,
      assessment_id: this.assessment_id,
      transaction_id: paymetID,
      no_of_licenses: this.number_of_license,
      validity_days: this.validity_days ? this.validity_days : (this.validitydays?parseInt(this.validitydays.meta_value):2),
      rate: this.priceInfo.rate,
      discount: 0,
      amount: this.priceInfo.rate,
      s_sales_person: this.websales_id,
      s_customer_id: this.customer_id,
      svalid_from: new Date(),
      svalid_upto: svalid_upto,
      totao_invoice: this.priceInfo.rate,
      s_total: this.priceInfo.rate,
      s_sgst: this.sgst,
      s_cgst: this.cgst,
      currency_id: this.priceInfo.Currency_master.id,
      permitted_attempt: this.count.meta_value,
      permitted_days: this.days.meta_value
    }
    this.apiService.postwithtoken('savepaymentinfo', data)
      .subscribe(
        result => {
          this.spinner.hide();
          if (result['success']) {
            localStorage.removeItem('assessment_id');
            localStorage.removeItem('number_of_license');
            localStorage.setItem('thankyou_display', '1');
            this.apiService.toastrMsg(result['message'], 'success');
            this.router.navigateByUrl('my-assessment');
          }
        },
        fail => {
          this.spinner.hide();
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  CountryList() {
    var data = {
      // internal: 1
    }
    this.apiService.post('countrymasterlist', data)
      .subscribe(
        result => {
          this.countriesList = result['countrylist'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  monthSelect() {
    this.months = [];
    var currentMonth = new Date().getMonth() + 1;
    if (this.current_year == this.paymentForm.value.expiry_year) {
      for (var i = currentMonth; i <= 12; i++) {
        this.months.push(i);
      }
    } else {
      for (var i = 1; i <= 12; i++) {
        this.months.push(i);
      }
    }
  }

  StateList() {
    var data = {
      country_id: this.paymentForm.value.country_id
    }
    if(this.countriesList) {
      if (this.countriesList.length != 0) {
        var country = this.countriesList.findIndex(a => a.id == this.paymentForm.value.country_id);
        this.country = country !=-1 ? this.countriesList[country].c_title : '';
        if(country!=-1){
          this.paymentForm.patchValue({'currency':this.countriesList[country].currency});
        }

      }
    }
    this.apiService.postwithouttoken('statelist', data)
      .subscribe(
        result => {
          this.stateList = result['statelist'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  getStateName() {
    if(this.stateList) {
      if (this.stateList.length != 0) {
        var state = this.stateList.findIndex(a => a.id == this.paymentForm.value.state);
        this.state = state !=-1 ? this.stateList[state].state_title : '';
      }
    }

  }

  getAssessmentByid(assessment_id) {
    var data = {
      assessment_id: assessment_id,
      price_id:this.price_id
    }
    this.apiService.postwithouttoken('getassessmentbyidfromweb', data)
      .subscribe(
        result => {
          this.assessmentInfo = result['assessmentInfo'];
          this.priceInfo = result['PriceInfo'];
          this.taxDetails = result['Tax'];
          if(this.priceInfo){
            this.validity_days =  this.priceInfo.validity_days;

            let tax  =  this.taxDetails.find(a=>a.country_id==this.priceInfo.country_id);
            this.cgst = tax?tax.cgst:0;
            this.sgst = tax?tax.tax_rate:0;

            let total_tex = this.cgst + this.sgst;

            this.priceInfo.tax_p = total_tex;
            this.priceInfo.tax_rate = 0;
            this.priceInfo.cgst = this.cgst;
            this.priceInfo.sgst = this.sgst;

            let amount = this.priceInfo.rate;
            let cgst_value = (amount * parseInt(this.cgst) )/ (100 + (parseInt(this.cgst) + parseInt(this.sgst)));
            let sgst_value = (amount * parseInt(this.sgst) )/ (100 + (parseInt(this.cgst) + parseInt(this.sgst)));
            // let cgst_value = amount * (100 / (100 + parseInt(this.cgst)));
            // let sgst_value = amount * (100 / (100 + parseInt(this.sgst)));

            // let taxamount = amount * (100 / (100 + parseInt(this.priceInfo.tax_p)));

            this.priceInfo.tax_rate = cgst_value + sgst_value;
            this.priceInfo.actual_price = amount -  this.priceInfo.tax_rate;
            this.priceInfo.actual_price = this.priceInfo.actual_price.toFixed(2);

            this.priceInfo.cgst_value = cgst_value.toFixed(2);
            this.priceInfo.sgst_value = sgst_value.toFixed(2);

          }

        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  //get customer by id
  getCustomerById(customer_id) {
    var data = {
      customer_id: customer_id
    }
    this.apiService.postwithouttoken('getcustomerbyid', data)
      .subscribe(
        result => {
          this.customerInfo = result['customerInfo'];
          this.customerMeta = result['customerMeta'];
          this.paymentForm.patchValue(this.customerInfo);
          if (this.customerMeta.length != 0) {
            for(let val of this.customerMeta){
              if(val.meta_key=='zip_code'){
                this.paymentForm.patchValue({
                  'zip_code': val['meta_value'],

                })
              }else if(val.meta_key=='country_id'){
                this.paymentForm.patchValue({
                  'country_id': val['meta_value'],

                })
              }
              else if(val.meta_key=='state'){
                this.paymentForm.patchValue({
                  'state': val['meta_value'],

                })
              } else if(val.meta_key=='gstn'){
                this.paymentForm.patchValue({
                  'gstn': val['meta_value'],

                })
              }
            }
          }
          this.StateList();
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  // addData() {
  // 	let info = this.paymentForm.value;
  // 	this.paymentForm.patchValue({
  // 		'bill_name': info.contact_name,
  // 		'bill_address': info.contact_address,
  // 		'bill_country': info.contact_country,
  // 		'bill_state': info.contact_state,
  // 		'bill_city': info.contact_city,
  // 		'bill_pincode': info.contact_pincode,
  // 	});
  // }

  getassessmentSettingDetails() {
    var data = {
    }
    this.apiService.post('getassessmentdetails', data)
      .subscribe(
        result => {
          this.count = result['count'];
          this.validitydays = result['validitydays'];
          this.days = result['days'];
        },
        fail => {
          if (fail.status == 401) {
            this.router.navigateByUrl('/login');
          } else {
            this.apiService.toastErrorMsg(fail);
          }
        }
      );
  }
}
