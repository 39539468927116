import {Component, OnInit, TemplateRef, ElementRef, HostListener, ViewChild} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {CountdownComponent} from 'ngx-countdown';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-question-result',
  templateUrl: './question-result.component.html',
  styleUrls: ['./question-result.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)',
    '(window:blur)': 'onMovingWindow($event)'
  }
})
export class QuestionResultComponent implements OnInit {

  // Keyboard event restrictions
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
  	let keycodearr = [8, 18, 44, 116, 123];
    if (event.keyCode == 44) {
      this.stopPrntScr();
    }
  	if (keycodearr.includes(event.keyCode)) { // Specific keys restriction
  		event.returnValue = false;
  		event.preventDefault();
  	} else if ((event.ctrlKey || event.metaKey) && (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 82 || event.keyCode == 80 || event.keyCode == 79 || event.keyCode == 83)) { // Ctrl+C, Ctrl+V, Ctrl+R, Ctrl+P, Ctrl+O, Ctrl+S
  		event.returnValue = false;
  		event.preventDefault();
  	} else if (event.ctrlKey && event.shiftKey && event.keyCode == 74) { // Ctrl+Shift+J
  		event.returnValue = false;
  		event.preventDefault();
  	}
  }

  // // Right click event disable
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
  	event.preventDefault();
  }


  modalRef: BsModalRef;
  answerForm: FormGroup;
  recordId: any;
  formsubmitted = false;
  unique_url: any;
  assessmentInfo: any;
  questionarray: any;
  assessment_id: any;
  total_questions: any;
  CustomerAssessmentId: any;
  answerArray: any;
  ques_index = 0;
  next_button = true;
  back_button = false;
  option: any;
  scale = [];
  multi_answer = [];
  countdown: any;
  leftTime: any;
  right = [];
  left = [];
  sequenceType = [];
  answer_status: any;
  assessment_marks = 0;
  matchingAnswerArray: any;
  sequencyAnswerArray: any;
  marks = 0;
  parse_answer: any;
  single_answer: any;
  liker_scale_answer: any;
  sequence_answer: any;
  matching_answer: any;
  totalmarks: any;
  fullmarks: any;
  no_of_attempted :any;
  easy_per_ques_mark = 0;
  medium_per_ques_mark = 0;
  hard_per_ques_mark = 0;
  test_status = 'Completed';
  congnitive_per_ques_mark = 1;
  is_mobile:any = 2;
  question_score: any = 0 ;

  @ViewChild('getTime', { static: false }) private countdown1: CountdownComponent;
  languageDetails:any;
  constructor(private spinner: NgxSpinnerService,private modalService: BsModalService, private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {

    this.unique_url = this.activatedRoute.snapshot.paramMap.get('id');
    this.is_mobile = this.activatedRoute.snapshot.paramMap.get('id2');

    this.getAssessmentsForTest(this.unique_url);
    // get time for count down

   this.countdown = localStorage.getItem('countdown');
  }
  onMovingWindow(event) {

    event.preventDefault();

    this.onCheat();
  }
  stopPrntScr() {

    let inpFld = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove();
  }
  // submit test
  onCheat(){
    this.test_status  = 'Security Break';
    if(this.is_mobile == '2') {
      this.SubmitAssessment();
    }
  }
  onResize(event){
    window.resizeTo(window.screen.width, window.screen.height);
    event.preventDefault();

  }
  dropSequence(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sequenceType, event.previousIndex, event.currentIndex);
    console.log("========-------", this.sequenceType);
    console.log("========-option------", this.option);
    this.sequencyAnswerArray = this.sequenceType;
    this.sequence_answer = this.sequenceType;
    this.scoreCalculate(this.option[0],1);


  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.matchingAnswerArray = event.container.data;
    this.matching_answer = event.container.data;
    // event.container.data.forEach((element, i) => {
    // 	console.log("===---", element, this.option[i].answer );
    // 	if(element == this.option[i].answer) {
    // 		console.log("==000000000==", element , this.option[i].answer);
    // 	}
    // });
    this.scoreCalculate(this.option[0],1);

    console.log("==-op====", this.option);
  }


  ngOnInit() {
    this.answerForm = new FormGroup({
      answer: new FormControl(''),

    })
  }

  // edit question modal open
  editQuestionModal(template: TemplateRef<any>, rowindex) {
    this.ques_index = rowindex;
    this.recordId = rowindex;
    let q_id = this.questionarray[rowindex].id;
    let answer;
    this.answerArray.forEach(element => {
      if(element.question_id == q_id) {
        this.no_of_attempted = element.no_of_attempted;
        answer = element.answer;
      }
    });
    // this.no_of_attempted = this.answerArray[this.ques_index].no_of_attempted;
    // let answer = this.answerArray[this.ques_index].answer;
    this.questionFormating(this.ques_index, answer);
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('exam-modal-selected');
  }

  getAssessmentsForTest(url) {
    this.spinner.show();
    var data = {
      is_final : false,
      unique_url: url
    }
    this.apiService.postwithouttoken('getanswerlist', data)
      .subscribe(
        result => {
          this.spinner.hide();
          if (result['success']) {
            if (result['assessmentInfo'] == undefined || result['questionarray'] == undefined) {
              this.apiService.toastrMsg("Invalid", 'error');
              this.router.navigate(['/']);
            } else {
              this.assessmentInfo = result['assessmentInfo'];
              this.questionarray = result['questionarray'];
              this.answerArray = result['answerInfo'];
              this.total_questions = this.questionarray.length;
              this.CustomerAssessmentId = result['CustomerAssessmentId'];

              /*this.easy_per_ques_mark = this.assessmentInfo.easy_ques_marks / this.assessmentInfo.total_easy_ques;
              this.medium_per_ques_mark = this.assessmentInfo.hard_ques_marks / this.assessmentInfo.total_hard_ques;
              this.hard_per_ques_mark = this.assessmentInfo.medium_ques_marks / this.assessmentInfo.total_medium_ques;*/
              this.easy_per_ques_mark = this.assessmentInfo.easy_ques_marks;
              this.medium_per_ques_mark = this.assessmentInfo.hard_ques_marks;
              this.hard_per_ques_mark = this.assessmentInfo.medium_ques_marks;
              this.congnitive_per_ques_mark = this.assessmentInfo.congnitive_per_ques_mark;
                this.getLanguageDetails();
            }
          }
        },
        fail => {
          this.spinner.hide();
          // this.apiService.toastrMsg(fail['error']['error'], 'error');
        }
      );
  }
  getLanguageDetails() {
    var data = {
      language_id: this.assessmentInfo.language_id,

    }
    this.apiService.postwithouttoken('getlanguagedetails', data)
      .subscribe(
        result => {
          let data = result['record'];
          this.languageDetails={};
          for(let val of data) {
            this.languageDetails[val.meta_key]  = val.meta_value;
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
   SubmitAssessment() {

    let total_marks_obtained = 0;
    let customer_user_id;
    if(this.answerArray.length!=0){
      for(let val of this.answerArray) {
        customer_user_id = val.customer_user_id;
        total_marks_obtained += parseInt(val.mark_obtained);
      }
    }
    var time = "00:"+this.assessmentInfo.time+":00";
    // If countdown1 is not available, then set the default minutes based on the assesssment time

    if(this.countdown1 !== undefined) {
      time = this.countdown1.i.text;
    }

    var array = time.split(":");
    let seconds = (parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60) + parseInt(array[2], 10)
    let time_taken = this.countdown - seconds;

    var data = {
      unique: this.unique_url,
      assessment_id : this.assessmentInfo.id,
      customer_assessment_id :this.CustomerAssessmentId,
      no_of_attempted : this.answerArray[0].no_of_attempted,
      assessment_mark : this.assessmentInfo.assessment_type == 3 ? total_marks_obtained : this.assessmentInfo.total_mark_of_assessment,
      customer_user_id : this.answerArray[0].customer_user_id,
      total_marks_obtained : total_marks_obtained,
      total_time : time_taken,
      total_questions : this.total_questions,
      devices_details: this.apiService.getDeviceDetails(),
      test_status: this.test_status

    };

    this.apiService.postwithouttoken('marksupdate', data)
      .subscribe(
        result => {
          if (result['success']) {

            if(this.assessmentInfo.is_congnitive == 1) {
              if (this.test_status != 'Security Break') {
                var url = 'assessment-final/' + this.unique_url;
                this.router.navigate([url]);
              } else {
                window.close();
              }
            }else{
              if (this.test_status != 'Security Break') {
                this.apiService.toastrMsg('You have submitted the assessment successfully', 'success');
                setTimeout(function(){ window.close(); }, 3000);


              } else {
                // this.apiService.toastrMsg('You have submitted the assessment successfully', 'success');
                window.close();
              }

            }
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  answerChecking(current_index) {

    if (this.questionarray[current_index].q_type == 1) {
      this.saveAnswer(current_index);
    } else if (this.questionarray[current_index].q_type == 2) {

      // answer checking here
      let answers_array = [];
      this.option.forEach(element => {
        if (element.answer == true) {
          answers_array.push(element.option)
        }
      });
      if (this.multi_answer.length == 0) {
        this.option.forEach(element => {
          if (this.parse_answer.includes(element.option)) {
            this.SelectMultipleAns(element.option, element.answer);
          }
        });
      }
      this.multi_answer.forEach((e1, i) => {
        if (e1 == answers_array[i]) {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.multi_answer)
          })
          this.answer_status = 1;
        } else {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.multi_answer)
          })
          this.answer_status = 0;
        }
      })
      this.saveAnswer(current_index);

    } else if (this.questionarray[current_index].q_type == 3) {

      this.saveAnswer(current_index);

    } else if (this.questionarray[current_index].q_type == 4) {
      // answer checking here
      let ans = this.answerForm.value.answer;
      console.log("========", this.option);
      let as = this.option[0].answer;
      if (ans == this.option[0][as]) {
        /*this.answerForm.patchValue({
          answer: 'true'
        })*/
        this.answer_status = 1;
        this.assessment_marks = this.assessment_marks + this.questionarray[current_index].marks
      } else {
        /*this.answerForm.patchValue({
          answer: 'false'
        })*/
        this.answer_status = 0;
      }
      this.saveAnswer(current_index);

    } else if (this.questionarray[current_index].q_type == 5) {
      // answer checking here
      let ans = this.option[0].answer;
      if (ans == this.answerForm.value.answer) {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 1;
      } else {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 0;
      }
      this.saveAnswer(current_index);

    } else if (this.questionarray[current_index].q_type == 6) {
      let key = 0;
      this.option.forEach((element, i) => {
        this.sequencyAnswerArray = this.sequencyAnswerArray == undefined ? this.sequence_answer : this.sequencyAnswerArray
        if (this.option[element.answer - 1].option == this.sequencyAnswerArray[i]) {
        } else {
          key++;
        }
      });
      if (key == 0) {
        this.answerForm.patchValue({
          answer: JSON.stringify(this.sequencyAnswerArray)
        })
        this.answer_status = 1;
      } else {
        this.answerForm.patchValue({
          answer: JSON.stringify(this.sequencyAnswerArray)
        })
        this.answer_status = 0;
      }
      this.saveAnswer(current_index);

    } else if (this.questionarray[current_index].q_type == 7) {

      // answer checking here
      this.answerForm.patchValue({
        answer: this.answerForm.value.answer
      })
      this.answer_status = 1;
      this.saveAnswer(current_index);

    } else if (this.questionarray[current_index].q_type == 8) {

      // answer checking here
      let ans = this.option[0].answer;
      if (ans == this.answerForm.value.answer) {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 1;
      } else {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 0;
      }
      this.saveAnswer(current_index);

    } else { // if (this.questionarray[current_index].q_type == 9)
      // answer checking here
      let key = 0;
      console.log("===matching===", this.matchingAnswerArray);
      this.matchingAnswerArray = this.matchingAnswerArray == undefined ? this.matching_answer : this.matchingAnswerArray
      this.matchingAnswerArray.forEach((element, i) => {
        if (element == this.option[i].answer) {

        } else {
          key++;
        }
      });
      if (key == 0) {
        this.answerForm.patchValue({
          answer: JSON.stringify(this.matchingAnswerArray)
        })
        this.answer_status = 1;
      } else {
        this.answerForm.patchValue({
          answer: JSON.stringify(this.matchingAnswerArray)
        })
        this.answer_status = 0;
      }
      this.saveAnswer(current_index);
    }
  }

  questionFormating(next_index, answer) {
    console.log("==next_index, answer=", next_index, answer);
    this.question_score = 0 ;

    this.option = JSON.parse(this.questionarray[next_index].options);
    if (this.questionarray[next_index].q_type == 1) {
      this.single_answer = answer;
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }
    } else if (this.questionarray[next_index].q_type == 2) {
      console.log("========", answer);
      this.parse_answer = answer;
      if (answer != null) {
        this.answerForm.patchValue({
          answer: this.parse_answer
        })
      }
    } else if (this.questionarray[next_index].q_type == 3) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }
      console.log("======", this.answerForm.value);
    } else if (this.questionarray[next_index].q_type == 4) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }
    } else if (this.questionarray[next_index].q_type == 5) {
      this.liker_scale_answer = answer == '' ? null : answer;
      this.scale = [];
      let s_s = this.option[0].start_scale;
      let e_s = this.option[0].end_scale;
      for (let i = s_s; i <= e_s; i++) {
        this.scale.push(i);
      }
      console.log("========this.scale===", this.scale);
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }

    } else if (this.questionarray[next_index].q_type == 6) {
      if(answer != ''){
        this.sequence_answer = JSON.parse(answer);
        let option = JSON.parse(answer);
      }

      this.sequenceType = [];
      this.option.forEach(element => {
        this.sequenceType.push(element.option);
      });

    } else if (this.questionarray[next_index].q_type == 7) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }
      console.log("====888====", this.answerForm.value);
    } else if (this.questionarray[next_index].q_type == 8) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }
    } else { // if (this.questionarray[next_index].q_type == 9)
      this.left = [];
      this.right = [];
      this.option.forEach(element => {
        this.left.push(element.left_option);
        this.right.push(element.right_option);
      });
      console.log("---saws-------", answer)
      this.matching_answer = '';
      if(answer != ''){
        this.matching_answer = JSON.parse(answer);
      }
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer
        })
      }

    }
  }

  saveAnswer(current_index) {
    this.spinner.show();
    //pre_next_index
    let question_type_meta = this.assessmentInfo.is_congnitive == 1 ? JSON.parse(this.assessmentInfo.question_type_meta) : JSON.parse(this.assessmentInfo.assessment_topic_meta);

    let obtained_mark ;
    if(this.assessmentInfo.is_congnitive== 1) {

      if (this.questionarray[current_index].difficulty_level_id == 2) {
        obtained_mark = this.answer_status == 1 ? (this.easy_per_ques_mark).toFixed(3) : 0;
      } else if (this.questionarray[current_index].difficulty_level_id == 4) {
        obtained_mark = this.answer_status == 1 ? (this.medium_per_ques_mark).toFixed(3) : 0;
      } else {
        obtained_mark = this.answer_status == 1 ? (this.hard_per_ques_mark).toFixed(3) : 0;
      }
    }else{
      if(this.assessmentInfo.assessment_type == 2 ) {
        if (this.questionarray[current_index].difficulty_level_id == 2) {
          obtained_mark = this.answer_status == 1 ? (this.congnitive_per_ques_mark).toFixed(3) : 0;
        } else if (this.questionarray[current_index].difficulty_level_id == 4) {
          obtained_mark = this.answer_status == 1 ? (this.congnitive_per_ques_mark).toFixed(3) : 0;
        } else {
          obtained_mark = this.answer_status == 1 ? (this.congnitive_per_ques_mark).toFixed(3) : 0;
        }
      } else {
        obtained_mark = this.question_score;
      }

    }

    let ind_question_mark = 0;
    if(this.assessmentInfo.is_congnitive== 1) {
      if (this.questionarray[current_index].difficulty_level_id == 4) {
        ind_question_mark = this.assessmentInfo.hard_ques_marks;
      } else if (this.questionarray[current_index].difficulty_level_id == 5) {
        ind_question_mark = this.assessmentInfo.medium_ques_marks;
      } else {
        ind_question_mark = this.assessmentInfo.easy_ques_marks;
      }
    }else{
      if(this.assessmentInfo.assessment_type == 2 ) {
        if (this.questionarray[current_index].difficulty_level_id == 4) {
          ind_question_mark = this.congnitive_per_ques_mark;
        } else if (this.questionarray[current_index].difficulty_level_id == 5) {
          ind_question_mark = this.congnitive_per_ques_mark;
        } else {
          ind_question_mark = this.congnitive_per_ques_mark;
        }
      } else {
        ind_question_mark = this.question_score;

      }

    }

    var data = {
      answer: this.answerForm.value.answer,
      assessment_id: this.assessmentInfo.id,
      customer_assessment_id: this.CustomerAssessmentId,
      question_id: this.questionarray[current_index].id,
      answer_status: this.answer_status,
      time: 20,
      no_of_attempted: this.no_of_attempted,
      assessment_mark : ind_question_mark,
      difficulty_level_id :this.questionarray[current_index].difficulty_level_id,
      task_performance_id: this.assessmentInfo.is_congnitive== 1? this.questionarray[current_index].task_performance_id :'',
      mark_obtained : obtained_mark
      // pre_next_question_id: this.questionarray[pre_next_index].id
    }
    console.log("===data===", data);
    // if (this.answerForm.value.answer != "") {
    this.apiService.postwithouttoken('saveanswer', data)
      .subscribe(
        result => {
          this.spinner.hide();
          if (result['success']) {
            let answer = result['nextQuestionInfo']
            // this.ques_index = pre_next_index;
            this.answerForm.patchValue({ answer: "" });
            this.getAssessmentsForTest(this.unique_url);
            this.modalRef.hide();
            // this.questionFormating(this.ques_index, answer);
            return;
          }
          else {
            // this.ques_index = pre_next_index;
            // let answer = null;
            // this.questionFormating(this.ques_index, answer);
            return;
          }
        },
        fail => {
          this.spinner.hide();
        }
      );
    // } else {
    // 	this.ques_index = pre_next_index;
    // 	let answer = null
    // 	this.questionFormating(this.ques_index, answer);
    // 	// this.editAnswer();
    // }
  }


  SelectMultipleAns(option, answer) {
    console.log("--this.option[0].answer---", option, answer, this.option);
    let i = 0;
    let k;
    this.multi_answer.forEach(function (value, j) {
      if (value == option) {
        i = 1;
        k = j;
        this.scoreCalculate(option,2);

      }
    });
    if (k != undefined) {
      this.multi_answer.splice(k, 1);
    }
    if (i != 1) {
      this.multi_answer.push(option);
    }
    let answer_array = [];
    this.option.forEach(element => {
      if (element.answer == true) {
        answer_array.push(element.option)
      }
    });
    console.log("---answer_array---", answer_array);
  }

  SelectSingleAns(option, answer) {
    let check = true;
    console.log("==option,==", option, answer, answer == true);
    this.scoreCalculate(option,1);

    if (answer == true) {
      this.answerForm.patchValue({
        answer: option
      })
      this.answer_status = 1;
      console.log("==this.answer_status=", this.answer_status);
    } else {
      this.answerForm.patchValue({
        answer: option
      })
      this.answer_status = 0;
      console.log("==thielsewwwwwwwwwwatus=", this.answer_status);
    }
  }

  dropdownAns() {
    let ans;
    this.option.forEach(element => {
      if(this.answerForm.value.answer == element.option) {
        this.scoreCalculate(element.option, 1);
      }
      if (element.answer == true) {
        ans = element.option;
      }
    });
    if (ans == this.answerForm.value.answer) {
      this.answerForm.patchValue({
        answer: this.answerForm.value.answer
      })
      this.answer_status = 1;
    } else {
      this.answerForm.patchValue({
        answer: this.answerForm.value.answer
      })
      this.answer_status = 0;
    }
  }

  trueFalseAnsMatch(ans) {
    // let as = this.option[0].answer;
    // if (ans == this.option[0][as]) {
    //   this.answerForm.patchValue({
    //     answer: 'true'
    //   })
    //   this.answer_status = 1;
    //   this.assessment_marks = this.assessment_marks + this.questionarray[this.ques_index].marks
    // } else {
    //   this.answerForm.patchValue({
    //     answer: 'false'
    //   })
    //   this.answer_status = 0;
    // }

    console.log("=========ans======", ans);
    let as = this.option[0].answer;
    if (ans == this.option[0][as]) {
      /*this.answerForm.patchValue({
        answer: 'true'
      })*/
      this.answer_status = 1;
      this.scoreCalculate(this.option[0],1);

      // this.assessment_marks = this.assessment_marks + this.questionarray[this.ques_index].marks
    } else {
      /*this.answerForm.patchValue({
        answer: 'false'
      })*/
      this.answer_status = 0;
      this.scoreCalculate(this.option[0],1);

    }
  }

  likerScaleAnsLine(event) {
    console.log("=========", event.value)
    let ans = event.value;
    this.scoreCalculate(this.option[0],1);

    this.liker_scale_answer = ans;
    if (ans == this.answerForm.value.answer) {
      console.log("=====if====");
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 1;
    } else {
      console.log("=====else=====");
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 0;
    }
  }


  likerScaleAnsPoint(ans) {
    // let ans = this.option[0].answer;
    console.log("======likerScaleAns===", ans, this.liker_scale_answer)
    this.scoreCalculate(this.option[0],1);

    this.liker_scale_answer = ans;
    if (ans == this.answerForm.value.answer) {
      console.log("=====if====");
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 1;
    } else {
      console.log("=====else=====");
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 0;
    }
  }

  EssayAnswer() {
    this.scoreCalculate(this.option[0],1);

    this.answerForm.patchValue({
      answer: this.answerForm.value.answer
    })
    this.answer_status = 1;
  }
  scoreCalculate(option,type){
    if(type == 1) {
      this.question_score = option.score ? option.score : 0;
    }else{
      this.question_score += option.score ? option.score : 0;

    }

  }
}
