import { Component } from '@angular/core';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'terms-conditions.component.html'
})
export class TermsAndConditionComponent {


  constructor(private router: Router, private apiService: ApiService) {

  }
  ngOnInit() {
  
  }

}
