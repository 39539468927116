import { Component } from '@angular/core';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent {

  formsubmitted = false;
  forgotForm: FormGroup;
  check = false;
  email_id: any;

  constructor(private router: Router, private apiService: ApiService) {

  }

  ngOnInit() {
    this.forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required])
    })
  }

  get f() {
    return this.forgotForm.controls;
  }

  forgotPassword() {
    this.formsubmitted = true;

    if (this.forgotForm.invalid) {
      return;
    }
// email formatting
    var emilString = this.forgotForm.value.email;
    var splitEmail = emilString.split("@");
    var domain = splitEmail[1];
    var name = splitEmail[0];
    this.email_id = name.length > 3 ? name.substring(0, 3).concat(Array(name.length - 3).join("*")).concat("@").concat(domain) : name.substring(0, 1).concat(Array(name.length - 1).join("*")).concat("@").concat(domain);

    var data = this.forgotForm.value;
    this.apiService.postwithouttoken('forgotpassword', data)
      .subscribe(
        result => {
          if (result['success']) {
            let data = result['message'];
            if (data != null) {
              this.check = true;
            }
            this.apiService.toastrMsg(result['message'], 'success');

          } else {
            this.apiService.toastrMsg(result['error']['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

}
