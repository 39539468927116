import { Component, OnInit, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConstants } from '../../constants';
import { ApiService } from '../../app.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { JsonPipe } from '@angular/common';

@Component({
	selector: 'app-start-assessment',
	templateUrl: './start-assessment.component.html',
	styleUrls: ['./start-assessment.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})


export class StartAssessmentComponent implements OnInit {

	// Keyboard event restrictions
	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		let keycodearr = [8, 18, 44, 116, 123];

		if(keycodearr.includes(event.keyCode)) { // Specific keys restriction
			event.returnValue = false;
			event.preventDefault();
		} else if((event.ctrlKey || event.metaKey) && (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 82 || event.keyCode == 80 || event.keyCode == 79|| event.keyCode == 83)) { // Ctrl+C, Ctrl+V, Ctrl+R, Ctrl+P, Ctrl+O, Ctrl+S
			event.returnValue = false;
			event.preventDefault();
		} else if(event.ctrlKey && event.shiftKey && event.keyCode == 74) { // Ctrl+Shift+J
			event.returnValue = false;
			event.preventDefault();
		}
	}

	// Right click event disable
	@HostListener('contextmenu', ['$event'])
	onRightClick(event) {
		event.preventDefault();
	}
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

	private url = AppConstants.imageURL;
	isMeridian = false;
	showSpinners = true;
	myTime: Date = new Date();
	currentDate = new Date();
	bsValue = new Date();
	bsRangeValue: Date[];
	maxDate = new Date();

	modalRef: BsModalRef;
	acceptForm: FormGroup;
	recordId: any;
	formsubmitted = false;
	assessmentInfo: any;
	questionarray: any;
	assessment_id: any;
	duration: any;
	unique_url: any;
	types: any;

  device_id:any;
  customer_id:any;
  is_mobile:any;
	languageDetails:any;
	constructor(private modalService: BsModalService, private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {
		this.maxDate.setDate(this.maxDate.getDate() + 7);
		this.bsRangeValue = [this.bsValue, this.maxDate];
		this.unique_url = this.activatedRoute.snapshot.paramMap.get('id');
		this.device_id = this.activatedRoute.snapshot.paramMap.get('id2');
		this.customer_id = this.activatedRoute.snapshot.paramMap.get('id3');
    this.is_mobile = this.activatedRoute.snapshot.paramMap.get('id4');

    console.log(this.unique_url,this.device_id,this.customer_id);
    localStorage.setItem('customer_id',this.customer_id);

    this.getAssessmentsForTest(this.unique_url);
	}
  onResize(event){
    window.resizeTo(window.screen.width, window.screen.height);
    event.preventDefault();
	}
	ngOnInit() {

		this.acceptForm = new FormGroup({
			acceptCondition: new FormControl(false, [Validators.required])
		});
	}

	get f() {
		return this.acceptForm.controls;
	}

	AcceptConditions() {
		this.formsubmitted = true;
		if (this.acceptForm.value.acceptCondition == false) {
			this.apiService.toastrMsg("Please accept the conditions to start the assessment.", 'error');
		} else {
			this.router.navigate(['/assessment-questions', this.unique_url, this.device_id, this.customer_id, this.is_mobile]);
		}
	}


	getAssessmentsForTest(url) {
		var data = {
			unique_url: url
		}
		this.apiService.postwithouttoken('getassessmentfortest', data)
			.subscribe(
				result => {
					if (result['success']) {
						if (result['assessmentInfo'] == undefined || result['questionarray'] == undefined ) {
							this.apiService.toastrMsg("Invalid", 'error');
							// this.router.navigate(['/']);
						} else {
							this.assessmentInfo = result['assessmentInfo'];
							this.questionarray = result['questionarray'];
							this.types = result['type'];
							this.hoursCalculate(result['time']);
							this.getLanguageDetails();

						}
					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	getLanguageDetails() {
		var data = {
			language_id: this.assessmentInfo.language_id,

		}
		this.apiService.postwithouttoken('getlanguagedetails', data)
			.subscribe(
				result => {
					let data = result['record'];
					this.languageDetails={};
					for(let val of data) {
						this.languageDetails[val.meta_key]  = val.meta_value;
					}
				},
				fail => {
					this.apiService.toastErrorMsg(fail);
				}
			);
	}
	hoursCalculate(num) {
		console.log("===www=", num);
		var hours = Math.floor(num / 60);
		var minutes = num % 60;
		console.log("===qw=", hours, minutes);
		// this.duration = hours + ":" + minutes;
    this.duration = `${num / 60 ^ 0}`.slice(-2) + ':' + ('0' + num % 60).slice(-2);
		console.log("==this.duration==", this.duration);
	}
}
