import { Component, OnInit } from '@angular/core';
import {AppConstants} from '../../../constants';
import {ApiService} from '../../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as AOS from 'aos';

@Component({
  selector: 'app-blogcategory',
  templateUrl: './blogcategory.component.html',
  styleUrls: ['./blogcategory.component.css']
})
export class BlogcategoryComponent implements OnInit {


  blog_id: any;
  blogList: any= [];
  subArray = [];
  private url = AppConstants.imageURL;

  pageno: any = 0;
  pageSize = 10;
  category = '';
  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.blog_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getBlogList();
    this.category = this.activatedRoute.snapshot.paramMap.get('id');
    this.category = this.category.split("-").join(" ");

  }

  ngOnInit(): void {
    AOS.init({
      once: true
    });
  }
  goBack(){
    window.history.back();
  }
  goToDetails(blog_title,id){
    localStorage.setItem('blog-id',id);
    this.router.navigate(['/blog/', blog_title.split(" ").join("-")]);

  }
  changePage(event) {
    this.pageno = event.pageIndex;
    this.pageSize = event.pageSize;

    this.getBlogList();
  }
  goToCategory(blog_title){
    this.blog_id = blog_title.split(" ").join("-");
    this.pageno =0;
    this.pageSize =10;
    this.getBlogList();
  }
  getBlogList() {
    var data = {
      pageno: this.pageno,
      pageSize: this.pageSize,
      category: this.blog_id.split("-").join(" "),
    }
    this.apiService.postwithouttoken('bloglist', data)
      .subscribe(
        result => {
          this.blogList = result['bloglist'];

        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }


}
