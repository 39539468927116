import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import {AppConstants} from '../../constants';

@Component({
	selector: 'app-micro-skills-analysis-for-business',
	templateUrl: './micro-skills-analysis-for-business.component.html',
	styleUrls: ['./micro-skills-analysis-for-business.component.css']
})
export class MicroSkillsAnalysisForBusinessComponent implements OnInit {


	@ViewChild('owlMac') owlMac: CarouselComponent;
	@ViewChild('owlCat') owlCat: CarouselComponent;


	formsubmitted = false;
	contactForm: FormGroup;
	assessmentList: any;

	categoriesOptions: any = {
		loop: false,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		stagePadding: 2,
		autoplay: false,
		autoWidth: false,
		margin: 25,
		startPosition: 1,
		nav: true,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		dotsSpeed: 600,
		responsive: {
			0: {
				items: 1,
				loop: true,
			},
			600: {
				items: 1,
				loop: true,
			},
			740: {
				items: 4,
				loop: true,
			},
			940: {
				items: 4,
				loop: true,
			}

		}

	};


	carouselOptions: OwlOptions = {
		loop: true,

		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		startPosition: 1,
		autoplay: true,
		dotsSpeed: 600,
		center: true,
		items: 1,

		// responsive: {
		//   0: {
		//     items: 1,
		//     loop: true,
		//   },
		//   400: {
		//     items: 1,
		//     loop: true,
		//   },
		//   740: {
		//     items: 1,
		//     loop: true,
		//   },
		//   940: {
		//     items: 1,
		//     loop: true,
		//   }
		nav: true
		// }
	};


	customOptions3: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}


	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};
  subjectList: any;
  subArray = [];
  assessmentListBySubject: any;
  private url = AppConstants.imageURL;
  groupAssessmentList :any;
  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService) {
    this.getAssessmentList();
    this.getSubjectList();
	}

	slideTo(category: string) {
		this.owlMac.moveByDot(category);
	}

	changeSlide($event) {
		if (this.owlCat) {
			// this.category$.next($event.slides[0].id);
			this.owlCat.moveByDot(this.owlCat.dotsData.dots[$event.startPosition].id)
		}
	}

	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	customOptions: OwlOptions = {
		autoWidth: true,
		loop:true,
		// items: '10',
		margin: 55,
		// slideBy: 'page',
		merge: true,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		 mouseDrag: false,
		 touchDrag: false,
		 pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: true,

		// rewind: true,
		// rtl: true,
		startPosition: 1,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			900: {
				items: 3
			}

		},
		//stagePadding: 100,
		nav: true
	}
	activeSlides: any;

	ngOnInit() {

		AOS.init({
			once: true
		});
		this.contactForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
			mobile_no: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      city: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
    })

	}
	// convenience getter for easy access to form fields
	get f() {
		return this.contactForm.controls;
	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}

	saveContact() {
		this.formsubmitted = true;
		if (this.contactForm.invalid) {
			return;
		}
		var data = this.contactForm.value;
		this.apiService.postwithouttoken('downloadsamplereport', data)
			.subscribe(
				result => {
					if (result['success']) {
						this.formsubmitted = false;
						// this.apiService.toastrMsg(result['message'], 'success');
						this.contactForm.reset(); //clear form
            let path ='https://recruitonic.com/Recruitonic_Micro-skills_Sample_Report.pdf';
            let filename = path.substring(path.lastIndexOf('/') + 1);

            FileSaver.saveAs(path, filename);
					} else {
						this.apiService.toastrMsg(result['message'], 'error');
					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
  getAssessmentListBySubject(id) {
    let data = {
      assessment_id: id
    };
    this.apiService.postwithouttoken('getgroupassessments', data).subscribe(
      result => {
        this.assessmentListBySubject = result['groupAssessments'];
      },
      fail => {
        this.apiService.toastErrorMsg(fail);
      }
    );
  }
  getSubjectList() {
    var data = {
      internal: 1
    }
    this.apiService.postwithouttoken('parentlistfromweb', data)
      .subscribe(
        result => {
          this.subjectList = result['subjectslist'];
          let cont = 0;
          this.subjectList.forEach(element => {
            if (cont == 0) {
              let obj = { 'id': element.Assessment.id, 'sub_title': element.Assessment.assess_title }
              this.subArray.push(obj);
              cont = 1;
            } else {
              this.subArray.forEach(abc => {
                let found = this.subArray.some(ele => ele.id === element.Assessment.id);
                if(!found){
                  let obj = { 'id': element.Assessment.id, 'sub_title': element.Assessment.assess_title }
                  this.subArray.push(obj);
                }
              });
            }
          });
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  redirectPage(assessment_id,assess_title) {
    var data = {
      assessment_id: assessment_id
    }
    this.apiService.postwithouttoken('getgroupassessments', data)
      .subscribe(
        result => {
          this.groupAssessmentList = result['groupAssessments'];
          if(this.groupAssessmentList.length > 0){
            const url = this.router.createUrlTree(['/assessments-group/', assess_title.split(" ").join("-")]);

            window.open(url.toString(), '_blank');
          } else {

            const url =  this.router.createUrlTree(['/assessments-details/', assess_title.split(" ").join("-")]);
            window.open(url.toString(), '_blank');
          }

        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  getAssessmentList() {
    var data = {
    }
    this.apiService.postwithouttoken('assessmentlistfromweb', data)
      .subscribe(
        result => {
          this.assessmentList = result['assessmentList'];
          this.assessmentListBySubject = result['assessmentList'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
}
