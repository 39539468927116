import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {SeoService} from './seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
declare let gtag: Function;
declare let fbq: Function;
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit ,OnDestroy{
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  constructor(private ccService: NgcCookieConsentService, private router: Router, public _seoService: SeoService, private activatedRoute: ActivatedRoute) {
    router.events.subscribe((y: NavigationEnd) => {

      if(y instanceof NavigationEnd){
        gtag('config','UA-{ID}',{'page_path' : y.url});
        fbq('track', 'PageView');
      }
    })
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {

        this._seoService.updateTitle(event['title']);
        this._seoService.updateOgUrl(event['ogUrl']);
        this._seoService.updateDescription(event['description']);
        if(event['keyword']) {
          this._seoService.updateKeys(event['keyword']);
        }
      });
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        this.ccService.getConfig();
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        this.ccService.getConfig();
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        this.ccService.getConfig();
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        this.ccService.getConfig();
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        this.ccService.getConfig();
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        this.ccService.getConfig();
      });
  }
  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
