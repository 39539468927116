import { Component, OnInit, ViewChild } from '@angular/core';
import * as AOS from 'aos';
import { ApiService } from '../../../app.service';
import { AppConstants } from '../../../constants';
import { ActivatedRoute, Router } from '@angular/router';
import {SeoService} from '../../../seo.service';

@Component({
  selector: 'app-blogpost-detail',
  templateUrl: './blogpost-detail.component.html',
  styleUrls: ['./blogpost-detail.component.css']
})
export class BlogpostDetailComponent implements OnInit {
	priceInfo: any;
	subjectList: any;
	blogList: any;
	subArray = [];
	url = AppConstants.imageURL;
	blog_id: any;
	blogInfo:any;

	constructor(private apiService: ApiService, public _seoService: SeoService, private activatedRoute: ActivatedRoute, private router: Router) {
		// this.blog_id = this.activatedRoute.snapshot.paramMap.get('id');
		// this.getBlogList();

	}

	ngOnInit(): void {
    AOS.init({
      once: true
    });
		this.blog_id = this.activatedRoute.snapshot.paramMap.get('id');
		// this.blog_id = localStorage.getItem('blog-id');
		this.getBlogList();
	}

	getBlogList() {
		var data = {"blog_title":this.blog_id.split("-").join(" "), "customer_id": null}
		this.apiService.postwithouttoken('getblogbytitle', data)
			.subscribe(
				result => {
				//	console.log('55555555',result);
					this.blogInfo = result['blogInfo'];
          this.updateMetaBlog(this.blogInfo.name.split(" ").join("-"),this.blogInfo);

        },
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

  updateMetaBlog(blog_name,details){
    let title,ogUrl,description,updateKeys;
    title = details.meta_title ? details.meta_title : '';
    description = details.meta_desc ? details.meta_desc : '';
    updateKeys = details.meta_keywords ? details.meta_keywords : '';
    ogUrl = 'https://recruitonic.com/blog/' + blog_name;
    // if(blog_name == 'How-Benchmarking-of-Skills-Helps-in-Improving-Productivity-in-Employees'){
    //   title ='How Benchmarking of Skills Helps in Improving Productivity in Employees';
    //   ogUrl ='https://recruitonic.com/blog/How-Benchmarking-of-Skills-Helps-in-Improving-Productivity-in-Employees';
    //   description ='Recruitonic helps in gauging and benchmarking the digital & essential skills so organizations can further train the employees on advanced features and improve productivity';
    // }else if(blog_name == 'The-importance-of-MS-Excel-in-various-industries'){
    //   title ='The importance of MS Excel in various industries';
    //   ogUrl ='https://recruitonic.com/blog/The-importance-of-MS-Excel-in-various-industries';
    //   description ='Microsoft Excel is the powerhouse for employee’s essential skills. It helps improves efficiency and productivity. Recruitonic helps in gauging and benchmarking this skill, so you can customize the path for employee training programs.';
    // }
    this._seoService.updateTitle(title);
    this._seoService.updateOgUrl(ogUrl);
    this._seoService.updateDescription(description);
    this._seoService.updateKeys(updateKeys);
  }
  }
