export class AppConstants {
		/*public static get baseURL(): string { return "http://localhost:4200/"; } // Base admin url
    public static get apiURL(): string { return "http://localhost:3000/"; } //APi url
    public static get imageURL(): string { return "http://localhost:3000/uploads"; }*/ //APi url
    
    public static get baseURL(): string { return "https://www.recruitonic.com/"; } // Base admin url
    public static get apiURL(): string { return "https://www.recruitonic.com:3005/"; } //APi url
    public static get imageURL(): string { return "https://www.recruitonic.com:3005/uploads"; } //APi url
    public static get liveURL(): string { return "https://www.recruitonic.com/customer/dashboard"; }
    public static get customerURL(): string { return "https://www.recruitonic.com/customer/dashboard"; }
		public static get adminUrl(): string {return 'https://www.recruitonic.com/admin/';}
	
    public static get hide_course(): string {return "true";}
    public static get websales_id(): number {return 2};
    
}
