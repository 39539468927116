import { INavData } from '@coreui/angular';
export const navItems: INavData[] = [

{
    name: 'About Us',
    url: '/about-us',
    icon: 'fa fa-diamond ',
    class: 'admin_class',
  },


  {
    name: 'Assessments',
    url: '/assessments',
    icon: 'fa fa-diamond ',
    class: 'admin_class',
  },

{
    name: 'Recruitonic For Business',
    url: '/recruitonic-for-business',
      icon: 'fa fa-sort-amount-desc',

  },

  {
    name: 'My Assesssments',
    url: '/my-assessment',
      icon: 'fa fa-crosshairs',

  },{
    name: 'Contact',
    url: '/contact-us',
      icon: 'fa fa-crosshairs',

  },

  //
  //
  //
  // {
  //   name: 'Sign in',
  //   url: '/userpage',
  //     icon: 'fa fa-user-circle-o',
  //
  // },
  //
  // {
  //   name: 'Sign up',
  //   url: '/sign-up',
  //   icon: 'fa fa-sign-in',
  //   attributes: {
  //     onClick: function (e) {
  //       e.preventDefault();
  //       e.stopPropagation();
  //           alert(1);
  //     }
  //
  //   }
  //
  // },











];
