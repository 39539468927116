import { Component,	OnInit,	ViewChild } from '@angular/core';
import { CarouselComponent,	OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../../app.service';
import * as AOS from 'aos';
import { AppConstants } from '../../constants';

@Component({
	selector: 'app-thank-you',
	templateUrl: './thank-you.component.html',
	styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {

	// testimonial carasouel

	@ViewChild('owlMac') owlMac: CarouselComponent;
	@ViewChild('owlCat') owlCat: CarouselComponent;

	private url = AppConstants.imageURL;
	user_name:any;
	assessmentList :any;

	categoriesOptions: any = {
		loop: false,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		stagePadding: 2,
		autoplay: false,
		autoWidth: false,
		margin: 25,
		startPosition: 1,
		nav: true,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		dotsSpeed: 600,
		responsive: {
			0: {
				items: 4,
				loop: true,
			},
			400: {
				items: 4,
				loop: true,
			},
			740: {
				items: 4,
				loop: true,
			},
			940: {
				items: 4,
				loop: true,
			}
		}
	};


	carouselOptions: OwlOptions = {
		loop: true,

		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		startPosition: 1,
		autoplay: true,
		dotsSpeed: 600,
		center: true,
		items: 1,

		// responsive: {
		//   0: {
		//     items: 1,
		//     loop: true,
		//   },
		//   400: {
		//     items: 1,
		//     loop: true,
		//   },
		//   740: {
		//     items: 1,
		//     loop: true,
		//   },
		//   940: {
		//     items: 1,
		//     loop: true,
		//   }
		nav: true
		// }
	};

	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};


	constructor(private apiService: ApiService) {
		this.user_name = localStorage.getItem('c_name');
	}

	slideTo(category: string) {
		this.owlMac.moveByDot(category);
	}

	changeSlide($event) {
		if (this.owlCat) {
			// this.category$.next($event.slides[0].id);
			this.owlCat.moveByDot(this.owlCat.dotsData.dots[$event.startPosition].id)
		}
	}

	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	customOptions: OwlOptions = {
		autoWidth: true,
		loop: true,
		// items: '10',
		margin: 55,
		// slideBy: 'page',
		merge: true,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: true,

		// rewind: true,
		// rtl: true,
		startPosition: 1,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			900: {
				items: 3
			}

		},
		//stagePadding: 100,
		nav: true
	}
	activeSlides: any;


	ngOnInit() {
		this.getAssessmentList();
		AOS.init({
			once: true
		});

	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}

	getAssessmentList() {
		var data = {
			internal: 1
		}
		this.apiService.postwithouttoken('assessmentlistfromweb', data)
			.subscribe(
				result => {
					this.assessmentList = result['assessmentList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

}
