import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {AppConstants} from '../../constants';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactusComponent implements OnInit {

  @ViewChild('owlMac') owlMac: CarouselComponent;
  @ViewChild('owlCat') owlCat: CarouselComponent;

  formsubmitted = false;
  contactUsForm: FormGroup;
  assessmentList: any;

  is_contact_us: any;
  adminUrl = AppConstants.adminUrl;
  modalRef: BsModalRef;

  signupForm: FormGroup;
  constructor(private router: Router, private apiService: ApiService, private modalService: BsModalService, private activatedRoute: ActivatedRoute) {
    if(this.router.url == '/contact-us'){
      this.is_contact_us = 1;
    }else{
      this.is_contact_us = 2;

    }
  }

  ngOnInit() {
    AOS.init({
      once: true
    });
    if(this.router.url == '/contact-us'){
      this.is_contact_us = 1;
    }else{
      this.is_contact_us = 2;

    }
    this.signupForm = new FormGroup({
      u_name: new FormControl('', [Validators.required]),
      u_email_id: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      u_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)]),
      accept_terms: new FormControl(true, [Validators.required]),
      mobile_no: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),

    });
    this.contactUsForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      mobile_no: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      assessment_id: new FormControl('', [Validators.required]),
      license_required: new FormControl('', [Validators.required]),
      remark: new FormControl('')
    })
    this.getAssessmentList();
  }



  getAssessmentList() {
    var data = {
    }
    this.apiService.post('assessmentlistfromweb', data)
      .subscribe(
        result => {
          this.assessmentList = result['assessmentList'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.contactUsForm.controls;
  }
  get r() {
    return this.signupForm.controls;
  }

  saveContactUs() {
    this.formsubmitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }
    var data = this.contactUsForm.value;
    this.apiService.postwithouttoken('savecontactfromweb', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.formsubmitted = false;
            this.router.navigate(['/about-us']);
            this.apiService.toastrMsg(result['message'], 'success');
          } else {
            this.apiService.toastrMsg(result['message'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  ResetModel() {
    this.modalRef.hide();

    this.signupForm.reset();

    this.formsubmitted = false;
  }

  signUpReseller() {
    this.formsubmitted = true;
    if (this.signupForm.invalid) {
      return;
    }
    if (!this.signupForm.value.accept_terms) {
      this.apiService.toastrMsg('Please Accept the Recruitonic Terms of Service', 'error');
      return;
    }
    var data = this.signupForm.value;
    this.apiService.postwithouttoken('createreseller', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.apiService.toastrMsg(result['message'], 'success');
            window.location.href = this.adminUrl;


          } else {
            this.apiService.toastrMsg(result['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  sendConfirmModal(template: TemplateRef<any>) {

    this.formsubmitted = false;
    this.modalRef = this.modalService.show(template);

  }
}
