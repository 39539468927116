import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from 'ngx-bootstrap/modal';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { RegisterComponent } from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {AssessmentsComponent} from './views/assessments/assessments.component';
import {AssessmentsOverviewComponent} from './views/assessments-overview/assessments-overview.component';
import {PlansComponent} from './views/plans/plans.component';
import {AssessmentsDetailsComponent} from './views/assessments-details/assessments-details.component';
import {RecruitonicForBusinessComponent} from './views/recruitonic-for-business/recruitonic-for-business.component';
import {ContactusComponent} from './views/contact-us/contact-us.component';
import {ThankYouComponent} from './views/thank-you/thank-you.component';
import {PaymentComponent} from './views/payment/payment.component';
import {CoursesComponent} from './views/courses/courses.component';
import {UserpageComponent} from './views/userpage/userpage.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { TermsAndConditionComponent } from './views/terms-conditions/terms-conditions.component';
import {FaqComponent } from './views/faq/faq.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { StartAssessmentComponent } from './views/start-assessment/start-assessment.component';
import { QuestionResultComponent } from './views/question-result/question-result.component';
import { CourseResultComponent } from './views/course-result/course-result.component';
import { QuestionsComponent } from './views/questions/questions.component';
import { CountdownModule } from 'ngx-countdown';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { VerifiedComponent } from './views/verified/verified.component';
import { from } from 'rxjs';
import { GroupAssessmentsDetailsComponent } from './views/group-assessments-details/group-assessments-details.component';
import { UserAssessmentDetailsComponent } from './views/user-assessment-details/user-assessment-details.component';
import {MatSliderModule} from '@angular/material/slider';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FeedbackDialogComponent} from './containers/feedback-dialog/feedback-dialog.component';
import {NgxStripeModule} from 'ngx-stripe';
import { BlogpostListComponent } from './views/blog/blogpost-list/blogpost-list.component';
import { BlogpostDetailComponent } from './views/blog/blogpost-detail/blogpost-detail.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { BlogcategoryComponent } from './views/blog/blogcategory/blogcategory.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MomentPipe} from './moment.pipe';
import {MicroSkillsAnalysisForBusinessComponent} from './views/micro-skills-analysis-for-business/micro-skills-analysis-for-business.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": "recruitonic.com"
  },
  "position": "bottom-left",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#ff9900",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "https://recruitonic.com/privacy-policy",
    "policy": "Cookie Policy"
  }
}
@NgModule({
  imports: [
   CarouselModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
	ModalModule.forRoot(),
    AppAsideModule,
    HttpClientModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxInputStarRatingModule,
    ToastrModule.forRoot(),
    CountdownModule,
    DragDropModule,
   	MatExpansionModule,
    MatSliderModule,
    NgxSpinnerModule,
    NgxStripeModule.forRoot('pk_live_51HA8CVHbDsPhKzVxADBZOrLAh0LhLw7qHUyUN8BpwvL7l9LAgop533LuTB52QtiO2zAgPhiQj25kqFzBmfVBzuZ900hLWoXHPf'),
    MatPaginatorModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    // MatSliderHarness
  ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        ForgotPasswordComponent,
        VerifiedComponent,
        ResetPasswordComponent,
        RegisterComponent,
        AssessmentsDetailsComponent,
        GroupAssessmentsDetailsComponent,
        RecruitonicForBusinessComponent,
        HomeComponent,
        ContactusComponent,
        ThankYouComponent,
        PaymentComponent,
        PlansComponent,
        AssessmentsOverviewComponent,
        CoursesComponent,
        UserpageComponent,
        AssessmentsComponent,
        StartAssessmentComponent,
        QuestionsComponent,
        QuestionResultComponent,
        CourseResultComponent,

        TermsAndConditionComponent,
        FaqComponent,
        UserAssessmentDetailsComponent,
        FeedbackDialogComponent,
        BlogpostListComponent,
        BlogpostDetailComponent,
        BlogcategoryComponent,
        MomentPipe,
      MicroSkillsAnalysisForBusinessComponent
    ],
  providers: [],
  entryComponents: [FeedbackDialogComponent],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
