import {Injectable} from '@angular/core';
import { Meta, Title ,MetaDefinition} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ content: url} , 'name="og:url"' );

  }

  updateDescription(desc: string) {

    this.meta.updateTag({ content: desc} , 'name="description"' );

  }
  updateKeys(desc: string) {

    this.meta.updateTag({ content: desc} , 'name="keyword"' );

  }
}
