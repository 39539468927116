import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { ApiService } from '../../app.service';
import { AppConstants } from '../../constants';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
	selector: 'app-plans',
	templateUrl: './plans.component.html',
	styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {

	@ViewChild('owlMac') owlMac: CarouselComponent;
	@ViewChild('owlCat') owlCat: CarouselComponent;

	assessmentList: any;
	assessment_id: any;
	assessmentInfo: any;
	priceInfo: any;
	private url = AppConstants.imageURL;

	categoriesOptions: any = {
		loop: false,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		stagePadding: 2,
		autoplay: false,
		autoWidth: false,
		margin: 25,
		startPosition: 1,
		nav: true,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		dotsSpeed: 600,
		responsive: {
			0: {
				items: 4,
				loop: true,
			},
			400: {
				items: 4,
				loop: true,
			},
			740: {
				items: 4,
				loop: true,
			},
			940: {
				items: 4,
				loop: true,
			}
		}
	};

	carouselOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		startPosition: 1,
		autoplay: true,
		dotsSpeed: 600,
		center: true,
		items: 1,

		// responsive: {
		//   0: {
		//     items: 1,
		//     loop: true,
		//   },
		//   400: {
		//     items: 1,
		//     loop: true,
		//   },
		//   740: {
		//     items: 1,
		//     loop: true,
		//   },
		//   940: {
		//     items: 1,
		//     loop: true,
		//   }
		nav: true
		// }
	};

	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};

  assessment_completed: any;
  priceInfoAll: any;

	constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router) {
		let customer_id = localStorage.getItem('customer_id');
		if (this.apiService.checkTokenExpiry()) {
			this.router.navigate(['/']);
		} else {
			this.assessment_id = this.activatedRoute.snapshot.paramMap.get('id');
			this.getAssessmentByid(this.assessment_id);
		}
	}


	slideTo(category: string) {
		this.owlMac.moveByDot(category);
	}

	changeSlide($event) {
		if (this.owlCat) {
			// this.category$.next($event.slides[0].id);
			this.owlCat.moveByDot(this.owlCat.dotsData.dots[$event.startPosition].id)
		}
	}

	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	customOptions: OwlOptions = {
		autoWidth: true,
		loop: true,
		// items: '10',
		margin: 55,
		// slideBy: 'page',
		merge: true,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: true,

		// rewind: true,
		// rtl: true,
		startPosition: 1,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			900: {
				items: 3
			}

		},
		//stagePadding: 100,
		nav: true
	}
	activeSlides: any;


	ngOnInit() {

		AOS.init({
			once: true
		});

	}
  goBack(){
    window.history.back();
  }
	getPassedData(data: any) {
		this.activeSlides = data;
	}

	getAssessmentByid(assessment_id) {
		var data = {
      assess_title: assessment_id.split("-").join(" "),

  }
		this.apiService.postwithouttoken('getassessmentbyidfromwebbytitle', data)
			.subscribe(
				result => {
					this.assessmentInfo = result['assessmentInfo'];
          this.assessment_completed = Number(result['assessment_completed'].no_of_attempted) + Number(this.assessmentInfo.display_sales);

          this.priceInfo = result['PriceInfo'];
          this.priceInfoAll = result['PricinginfoAll'];

        },
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	selectPlan(licenses, assid, price_id) {
		localStorage.setItem('number_of_license', JSON.stringify(licenses));
		localStorage.setItem('assessment_id', assid);
    localStorage.setItem('price_id', price_id);

    this.router.navigate(['/payment']);
	}

}
