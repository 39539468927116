import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { RegisterComponent } from './views/register/register.component';
import { HomeComponent } from './views/home/home.component';
import { AssessmentsComponent } from './views/assessments/assessments.component';
import { AssessmentsDetailsComponent } from './views/assessments-details/assessments-details.component';
import { AssessmentsOverviewComponent } from './views/assessments-overview/assessments-overview.component';
import { PlansComponent } from './views/plans/plans.component';
import { ContactusComponent } from './views/contact-us/contact-us.component';
import { ThankYouComponent } from './views/thank-you/thank-you.component';
import { PaymentComponent } from './views/payment/payment.component';
import { RecruitonicForBusinessComponent } from './views/recruitonic-for-business/recruitonic-for-business.component';
import { CoursesComponent } from './views/courses/courses.component';
import { UserpageComponent } from './views/userpage/userpage.component';
import { StartAssessmentComponent } from './views/start-assessment/start-assessment.component';
import { QuestionResultComponent } from './views/question-result/question-result.component';
import { CourseResultComponent } from './views/course-result/course-result.component';
import { QuestionsComponent } from './views/questions/questions.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { VerifiedComponent } from './views/verified/verified.component';
import { TermsAndConditionComponent } from './views/terms-conditions/terms-conditions.component';
import { GroupAssessmentsDetailsComponent } from './views/group-assessments-details/group-assessments-details.component';
import { UserAssessmentDetailsComponent } from './views/user-assessment-details/user-assessment-details.component';
import { BlogpostListComponent } from './views/blog/blogpost-list/blogpost-list.component';
import { BlogpostDetailComponent } from './views/blog/blogpost-detail/blogpost-detail.component';
import {BlogcategoryComponent} from './views/blog/blogcategory/blogcategory.component';
import {FaqComponent } from './views/faq/faq.component';
import {MicroSkillsAnalysisForBusinessComponent} from './views/micro-skills-analysis-for-business/micro-skills-analysis-for-business.component';




export const routes: Routes = [
  // {path: '', redirectTo: 'home', pathMatch: 'full'},

  // {
  //   path: '',
  //   pathMatch: 'full',
  //   component: HomeComponent,
  //
  //   // redirectTo: 'home',
  //   data:{
  //     title:'Digital and Essential Skills Assessments Provider - Automated Screening and Micro Skills Report',
  //     description:'Recruitonic helps organizations on global benchmarking of digital & essential Skills',
  //     ogUrl:'https://recruitonic.com'
  //   }
  // },

  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },

  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },

   {
        path: 'assessment-start/:id/:id2/:id3/:id4',
        component: StartAssessmentComponent,
        data: {
          title: 'Start Assessment'
        }
      },
      {
        path: 'assessment-completed/:id/:id2',
        component: QuestionResultComponent,
        data: {
          title: 'question-result'
        }
      },
      {
        path: 'assessment-final/:id',
        component: CourseResultComponent,
        data: {
          title: 'Assessment Final'
        }
      },


      {
        path: 'assessment-questions/:id/:id2/:id3/:id4',
        component: QuestionsComponent,
        data: {
          title: 'Questions'
        }
      },




  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        data:{
          title:'Digital and Essential Skills Assessments Provider - Automated Screening and Micro Skills Report',
          description:'Recruitonic helps organizations on global benchmarking of digital & essential Skills',
          ogUrl:'https://recruitonic.com'
        }

      },

      {
        path: 'assessments',
        component: AssessmentsComponent,
        data: {
          title: 'Job Wise and skills-wise assessments and certifications for entry to mid-level jobs across industries',
          description: 'Recruitonic will help you improve your company\'s entry to mid-level digital skill level to the industry High Standards And Make Your Organization Stand Out',
          ogUrl: 'https://recruitonic.com/assessments'
        }
      },
{
        path: 'micro-skills-analysis-for-business',
        component: MicroSkillsAnalysisForBusinessComponent,
        data: {
          title: 'Identify Skill-gaps, Create Custom Training Programs and Hire Skilled Candidates using Micro-Skills Report',
          description: 'Recruitonic Report offers customizable insights at the micro-level indicating the following standards of benchmarking and performance measurement in a digital, technical, or a particular professional skill.',
          ogUrl: 'https://recruitonic.com/micro-skills-analysis-for-business',
          keyword:'micro-skills report, micro-skills analysis, recruitment, skilling, upskilling, proficiency level hiring, reassessment, benchmarking with micro-skills report, micro-level indicators, skill proficiency, professional skill assessment, productivity gaps assessment, skill-gap report, Recruitonic assessment report'
        }
      },

      {
        path: 'payment',
        component: PaymentComponent,
        data: {
          title: 'Payment'
        }
      },

      {
        path: 'plans',
        component: PlansComponent,
        data: {
          title: 'Plans'
        }
      },
      {
        path: 'plans/:id',
        component: PlansComponent,
        data: {
          title: 'Plans'
        }
      },

      // {
      //   path: 'sign-up',
      //   component: SignUpComponent,
      //   data: {
      //     title: 'Sign Up'
      //   }
      // },
      {
        path: 'partner',
        component: ContactusComponent,
        data: {
          title: 'Online System for Certification, Benchmarking, and Validating Skills',
          description: 'As a Recruitonic Business Partner, you will get top-notch benefits such as Customised Assessments according to your client needs, Certified Benchmarking of skills for Students/Working Professionals, and many more',
          ogUrl: 'https://recruitonic.com/partner'
        }
      },
      {
        path: 'contact-us',
        component: ContactusComponent,
        data: {
          title: 'Customized Assessments, Benchmarking and Certification of Digital and Essential skills',
          description: 'Contact us to support you in your requirements for Assessments, Benchmarking, and Certification of Digital and Essential skills',
          ogUrl: 'https://recruitonic.com/contact-us'
        }
      },

      {
        path: 'thank-you',
        component: ThankYouComponent,
        data: {
          title: 'Thank You'
        }
      },
      {
        path: 'about-us',
        component: AssessmentsOverviewComponent,
        data: {
          title: 'Benchmarking and Assessing Skills for Employability',
          description: 'Recruitonic is an official member of Education Finland & Authorised Microsoft Global Partner in Education. Provides a unique assessment system of digital skills that offers a micro-skills report that aids in better hiring, employability, and training design',
          ogUrl:'https://recruitonic.com/about-us'
        }
      },
      {
        path: 'assessments-group/:id',
        component: GroupAssessmentsDetailsComponent,
        data: {
          title: 'Group Assessments Details'
        }
      },
      {
        path: 'assessments-details/:id',
        component: AssessmentsDetailsComponent,
        data: {
          title: 'Assessments Details'
        }
      },

      {
        path: 'recruitonic-for-business',
        component: RecruitonicForBusinessComponent,
        data: {
          title: 'Digital Skills Assessments for Hiring, Training, and Productivity',
          description:'Recruitonic has helped corporates & all size businesses by training more than 500,000 people, conducted more than 47,800+ people assessments for better productivity & trained 400+ companies',
          ogUrl: 'https://recruitonic.com/recruitonic-for-business'
        }
      },
      {
        path: 'courses',
        component: CoursesComponent,
        data: {
          title: 'Courses'
        }
      },
      {
        path: 'my-assessment',
        component: UserpageComponent,
        data: {
          title: 'My Assessment'
        }
      },
      {
        path: 'my-assessment/:id',
        component: UserpageComponent,
        data: {
          title: 'My Assessment'
        }
      },
      {
        path: 'user-assessment-details/:id1/:id2',
        component: UserAssessmentDetailsComponent,
        data: {
          title: 'User Assessment Details'
        }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
          title: 'Forgot Password'
        }
      },
      {
        path: 'privacy-policy',
        component: TermsAndConditionComponent,
        data: {
          title: 'Privacy Policy'
        }
      },
	  {
        path: 'faq',
        component: FaqComponent,
        data: {
          title: 'Help and Support - Recruitonic helps you by answering frequently asked questions',
          ogUrl: 'https://www.recruitonic.com/faq',
          description: 'Connect with us for any technical and general issues by emailing us at info@recruitonic.com'
        }
      },
      {
        path: 'blog',
        component: BlogpostListComponent,
        data: {
          title: "Our experts' thoughts on Digital and Essential skills assessments covering MS Excel, Photoshop, UI/UX and more!",
          ogUrl: 'https://www.recruitonic.com/blog',
          description: "Learn from our thought leadership about online assessment platforms and technologies."
        }
      },
      {
        path: 'blog/:id',
        component: BlogpostDetailComponent,
        data: {
          title: 'Blog Page'
        }
      },
      {
        path: 'blog/category/:id',
        component: BlogcategoryComponent,
        data: {
          title: 'Blog Page'
        }
      },
      {
        path: 'user-verify/:id',
        component: VerifiedComponent,
        data: {
          title: 'Verified'
        }
      },
      {
        path: 'reset-password/:id',
        component: ResetPasswordComponent,
        data: {
          title: 'Reset Password'
        }
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
