import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';

import {
	CarouselComponent,
	OwlOptions
} from 'ngx-owl-carousel-o';
import * as AOS from 'aos';

@Component({
	selector: 'app-assessments-overview',
	templateUrl: './assessments-overview.component.html',
	styleUrls: ['./assessments-overview.component.css']
})
export class AssessmentsOverviewComponent implements OnInit {



	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};


	constructor() { }



	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	customOptions: OwlOptions = {
		autoWidth: true,
		loop: false,
		// items: '10',
		margin: 55,
		// slideBy: 'page',
		merge: true,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			900: {
				items: 3
			}

		},
		//stagePadding: 100,
		nav: true
	}
	activeSlides: any;


	ngOnInit() {

		AOS.init({
			once: true
		});

	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}
	// End couse carasouel slider

}