import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { ApiService } from '../../app.service';
import { AppConstants } from '../../constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-assessments',
	templateUrl: './assessments.component.html',
	styleUrls: ['./assessments.component.css']
})
export class AssessmentsComponent implements OnInit {

	assessmentList: any;
	assessment_id: any;
	assessmentInfo: any;
	priceInfo: any;
	subjectList: any;
	subArray = [];
	assessmentListBySubject: any;
	private url = AppConstants.imageURL;
	feturedassessmentList: any;
	groupAssessmentList :any;


	customOptions1: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}

	customOptions2: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}


	customOptions3: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}


	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};


	constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router) {
		this.assessment_id = this.activatedRoute.snapshot.paramMap.get('id');
		this.getAssessmentList();
		this.getSubjectList();
		this.getFeturedAssessmentList();
	}

	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	activeSlides: any;


	ngOnInit() {

		AOS.init({
			once: true
		});

	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}

	getAssessmentList() {
		var data = {
		}
		this.apiService.postwithouttoken('assessmentlistfromweb', data)
			.subscribe(
				result => {
					this.assessmentList = result['assessmentList'];
					console.log("-------000--------", this.assessmentList);
					this.assessmentListBySubject = result['assessmentList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getFeturedAssessmentList() {
		var data = {
		}
		this.apiService.postwithouttoken('feturedassessmentlistfromweb', data)
			.subscribe(
				result => {
					this.feturedassessmentList = result['feturedassessmentList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getAssessmentListBySubject(id) {
  let data = {
               assessment_id: id
             };
  this.apiService.postwithouttoken('getgroupassessments', data).subscribe(
    result => {
					this.assessmentListBySubject = result['groupAssessments'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getSubjectList() {
		var data = {
			internal: 1
		}
		this.apiService.postwithouttoken('parentlistfromweb', data)
			.subscribe(
				result => {
					this.subjectList = result['subjectslist'];
					let cont = 0;
					this.subjectList.forEach(element => {
						if (cont == 0) {
							let obj = { 'id': element.Assessment.id, 'sub_title': element.Assessment.assess_title }
							this.subArray.push(obj);
							cont = 1;
						} else {
							this.subArray.forEach(abc => {
								let found = this.subArray.some(ele => ele.id === element.Assessment.id);
								if(!found){
									let obj = { 'id': element.Assessment.id, 'sub_title': element.Assessment.assess_title }
									this.subArray.push(obj);
								}
							});
						}
					});
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	redirectPage(assessment_id,assess_title) {
		var data = {
			assessment_id: assessment_id
		}
		this.apiService.postwithouttoken('getgroupassessments', data)
			.subscribe(
				result => {
					this.groupAssessmentList = result['groupAssessments'];
					if(this.groupAssessmentList.length > 0){
						this.router.navigate(['/assessments-group/', assess_title.split(" ").join("-")]);
					} else {

						this.router.navigate(['/assessments-details/', assess_title.split(" ").join("-")]);
					}

				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

}
