import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConstants } from '../../constants';
import { DefaultLayoutComponent } from '../../containers';
import {DeviceUUID} from 'device-uuid';

@Component({
	selector: 'app-home',
	templateUrl: 'home.component.html'
})
export class HomeComponent {
	// testimonial carasouel
	@ViewChild('smallModal') public smallModal: ModalDirective;
	@ViewChild('owlMac') owlMac: CarouselComponent;
	@ViewChild('owlCat') owlCat: CarouselComponent;

	private url = AppConstants.imageURL;

	formsubmitted = false;
	loginForm: FormGroup;
	signupForm: FormGroup;
	signIn = false;
	signUp = true;
	feturedcourseList: any;
	feturedassessmentList: any;
	assessmentList: any;
	assessmentArray: any;
	testimonialList: any;
	groupAssessmentList :any;


	categoriesOptions: any = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		center: true,
		autoplay: true,
		autoWidth: false,
		margin: 55,
		startPosition: 0,


		dotsSpeed: 600,
		responsive: {
			0: {
				items: 1,
				loop: true,
			},
			400: {
				items: 4,
				loop: true,
			},
			740: {
				items: 4,
				loop: true,
			},
			940: {
				items: 5,
				loop: true,
			}

		}

	};


	carouselOptions: OwlOptions = {
		loop: true,

		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		startPosition: 0,
		autoplay: true,
		dotsSpeed: 600,
		center: true,
		items: 1,

		// responsive: {
		//   0: {
		//     items: 1,
		//     loop: true,
		//   },
		//   400: {
		//     items: 1,
		//     loop: true,
		//   },
		//   740: {
		//     items: 1,
		//     loop: true,
		//   },
		//   940: {
		//     items: 1,
		//     loop: true,
		//   }
		nav: true
		// }
	};

	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};

	recordId: any;
	modalRef: BsModalRef;
  deviceInfo: any;

	constructor(public mainLayout: DefaultLayoutComponent,private router: Router, private apiService: ApiService, private modalService: BsModalService, private manureload : DefaultLayoutComponent) {

		let token = localStorage.getItem('access_token');
		if (token != null) {
		  this.router.navigate(['/']);
		}
	}

	slideTo(category: string) {
		this.owlMac.moveByDot(category);
	}

	changeSlide($event) {
		if (this.owlCat) {
			// this.category$.next($event.slides[0].id);
			this.owlCat.moveByDot(this.owlCat.dotsData.dots[$event.startPosition].id)
		}
	}

	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	customOptions: OwlOptions = {
		autoWidth: true,
		loop: false,
		// items: '10',
		margin: 55,
		// slideBy: 'page',
		merge: true,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			800: {
				items: 2
			},
			900: {
				items: 3
			}
		},
		//stagePadding: 100,
		nav: true
	}
	activeSlides: any;

	customOptionsslide: OwlOptions = {
		autoWidth: false,
		loop: true,
		//items: '3',
		margin: 0,
		// slideBy: 'page',
		stagePadding: 0,
		merge: false,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: true,
		dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		rewind: false,
		//ltr: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 1,

			},
			740: {
				items: 1,

			},
			940: {
				items: 1,

			}

		},

		nav: true
	}


	ngOnInit() {
		AOS.init({
			once: true
		});
		this.loginForm = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
			password: new FormControl('', [Validators.required])
		});

		this.signupForm = new FormGroup({
			c_name: new FormControl('', [Validators.required, Validators.pattern(/^[^\s].+[^\s]$/)]),
			c_email_id: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
			mobile_no: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
			c_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)]),
			accept_terms: new FormControl(true, [Validators.required]),
			c_image: new FormControl(''),
		});
		// this.getFeturedCourseList();
		this.getFeturedAssessmentList();
		this.getAssessmentList();
		this.getTestimonial();
	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}

	// import Confirm Send modal open
	sendConfirmModal(template: TemplateRef<any>, rowindex) {
		this.recordId = rowindex;
		this.modalRef = this.modalService.show(template);

  }

  openVideoModel(template: TemplateRef<any>) {

		this.modalRef = this.modalService.show(template,{ class: 'md-video-class' });

  }
  openSignInAndSignUpPopUp(template,type){
      this.recordId ='';
      this.mainLayout.openSignUpAndSignIn(type);

  }

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	// Function to login in
	customerLogin() {
		this.formsubmitted = true;
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
    this.deviceInfo = new DeviceUUID().get();

    var data = this.loginForm.value;
    data.device_id = this.deviceInfo;
		this.apiService.postwithouttoken('customerlogin', data)
			.subscribe(
				result => {

					if (result['success']) {
						this.modalRef.hide();
						localStorage.setItem('access_token', result['token']);
						localStorage.setItem('c_email_id', result['customer'].c_email_id)
						localStorage.setItem('c_name', result['customer'].c_name);
						localStorage.setItem('customer_id', result['customer'].id);
						localStorage.setItem('customer_dashboard', result['customer'].created_by);
						this.manureload.reloadManu(result['customer'].c_name);
						this.router.navigate(['/my-assessment']);

					}else{
            this.apiService.toastrMsg(result['error'], 'error');

          }
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}


	// functions for signup
	get g() {
		return this.signupForm.controls;
	}

	signUpCustomer() {
		this.formsubmitted = true;

		if (this.signupForm.invalid) {
			return;
		}
		if (!this.signupForm.value.accept_terms) {
			this.apiService.toastrMsg('Please Accept the Recruitonic Terms of Service', 'error');
			return;
		}
		var data = this.signupForm.value;
		this.apiService.postwithouttoken('savecustomermasterfromweb', data)
			.subscribe(
				result => {
					if (result['success']) {
						this.modalRef.hide();
						this.loginForm.patchValue({
							email: this.signupForm.value.c_email_id,
							password: this.signupForm.value.c_password
						})
						this.customerLogin();
						// this.router.navigate(['/my-assessment']).then(() => {
						// 	window.location.reload();
						// });
					} else {
						this.apiService.toastrMsg(result['error'], 'error');
					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	showSignUp() {
		this.signIn = false;
		this.signUp = true;
	}

	showSignIn() {
		this.signIn = true;
		this.signUp = false;
	}

	ResetModel() {
		this.modalRef.hide();
		this.loginForm.reset();
		this.signupForm.reset();
		this.signIn = false;
		this.signUp = true;
	}

	getFeturedCourseList() {
		var data = {
		}
		this.apiService.postwithouttoken('feturedcourselistfromweb', data)
			.subscribe(
				result => {
					this.feturedcourseList = result['feturedCourseList'];
					console.log("==this.feturedcourseList===", this.feturedcourseList);
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getFeturedAssessmentList() {
		var data = {
		}
		this.apiService.postwithouttoken('feturedassessmentlistfromweb', data)
			.subscribe(
				result => {
					this.feturedassessmentList = result['feturedassessmentList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	getAssessmentList() {
		var data = {
		}
		this.apiService.postwithouttoken('assessmentlistfromweb', data)
			.subscribe(
				result => {
					var start = 0, end = 6;
					let array = [];
					this.assessmentList = result['assessmentList'];
					if (this.assessmentList.length < 6) {
						let comming = [{
							Assessment: {
								id: '',
								assess_logo: '../../../assets/img/all/coming-soon.png',
								assess_title: 'More coming soon...',
								assess_desc: 'More assessments coming soon - Recruitonic is constantly evaluating and adding further critical assessment tests which are essestial to increase productivity. Keep track here...'

							}
						}];
						this.assessmentArray = this.assessmentList.concat(comming);
					} else {
						// array = this.feturedassessmentList.concat(this.assessmentList);
						this.assessmentArray = this.assessmentList.slice(start, end);
					}

				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	getTestimonial() {
		var data = {
		}
		this.apiService.postwithouttoken('gettestimoniallist', data)
			.subscribe(
				result => {
					this.testimonialList = result['testimonialList'];
					console.log("=========this========", this.testimonialList);
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	redirectPage(assessment_id,assess_title) {
		var data = {
			assessment_id: assessment_id
		}
		this.apiService.postwithouttoken('getgroupassessments', data)
			.subscribe(
				result => {
					this.groupAssessmentList = result['groupAssessments'];
					if(this.groupAssessmentList.length > 0){
						// this.router.navigate(['/assessments-group/', assessment_id]);
            this.router.navigate(['/assessments-group/', assess_title.split(" ").join("-")]);

            // alert("====group====");
					} else {
						this.router.navigate(['/assessments-details/', assess_title.split(" ").join("-")]);
						// alert("====assessment details====");
					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
}
