import {Component, NgZone, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../app.service';
import * as AOS from 'aos';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppConstants } from '../../constants';
import * as jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import html2pdf from 'html2pdf.js';
import {NgxSpinnerService} from 'ngx-spinner';
import {FeedbackDialogComponent} from '../../containers/feedback-dialog/feedback-dialog.component';
declare  let Highcharts:any;
import * as $ from 'jquery/dist/jquery.min.js';
import swal from "sweetalert2";
import {DeviceUUID} from 'device-uuid';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-user-assessment-details',
  templateUrl: 'user-assessment-details.component.html',
	styleUrls: ['./user-assessment-details.scss']
})
export class UserAssessmentDetailsComponent implements OnInit{
  modalRef: BsModalRef;
  recordId: any;
  private baseUrl = AppConstants.baseURL;
  private url = AppConstants.imageURL;
  assessment_id: any;
  invited_user_id: any;
  assessmentDetails: any;
  resultInfo: any;
  fresult: any;
  level_result: any;
  customerInfo: any;
  best_five: any;
  least_five: any;
  overall_grade: any;
  assessment_started_date: any;
  overall_percentage: any;
  totalmarks: any;
  isPdf: any = false;
  iscPdf: any = false;
  is_disabled: any;
  created_date: any;
  remaining_attempts: any;
  customer_details: any;
  inviteeDetails: any;
  cognitiveReportDetails: any=[];
  normData: any;
  max = 100;
  isCog:any = 0;
  min = 0;
  disabled = true;
  languageDetails:any;
  numberPattern = /\d+/g;
  constructor(private deviceService: DeviceDetectorService,private spinner: NgxSpinnerService,private modalService: BsModalService, private router: Router, private apiService: ApiService, private activatedRoute: ActivatedRoute, public zone: NgZone) {
    this.assessment_id = this.activatedRoute.snapshot.paramMap.get('id1');
    this.invited_user_id = this.activatedRoute.snapshot.paramMap.get('id2');
    this.is_disabled = 0;
    this.getAssessments(this.invited_user_id);

  }

  ngOnInit() {
    AOS.init({
      once: true
    });

  }
  openVideoModel(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template,{ class: 'md-video-class' });

  }
  // certificate download
  async certificateModal() {

    const div = document.getElementById('certificatetemp');
    div.style.display = 'block';
    var opt = {
      margin:       [36,0,36,0],
      filename:     'Recruitonic_Certificate.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2, dpi: 192, letterRendering: true, scrollX: 0, scrollY: 0 },
      jsPDF:        { unit: 'mm', format: 'c4', orientation: 'portrait' }

    };
    html2pdf().from(div).set(opt).save().then(value => {
        div.style.display = 'none';
      this.spinner.hide();
    });
    // const divHeight = div.clientHeight;
    // const divWidth = div.clientWidth;
    // const options = { background: 'white', width: divWidth, height: divHeight , quality: 100 };
    //  // div.style.height ='0';
    // this.spinner.hide();
    // domtoimage.toPng(div,options).then((imgData) => {
    //   // div.style.display = 'none';
    //   // div.style.height ='auto';
    //   const doc = new jsPDF('p', 'pt','a4',true);
    //   const imgProps = doc.getImageProperties(imgData);
    //   const pdfWidth = doc.internal.pageSize.getWidth();
    //   const pageHeight = doc.internal.pageSize.getHeight();
    //
    //   const widthRatio = pdfWidth / divWidth;
    //   const heightRatio = pageHeight / divHeight;
    //   const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    //
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   const marginX = (pdfWidth - (divWidth*ratio)) / 2;
    //   const marginY = (pageHeight - (divHeight*ratio)) / 2;
    //   doc.addImage(imgData, 'PNG', marginX, marginY, pdfWidth, pdfHeight ,'', 'FAST');
    //   doc.save('Recruitonic_Certificate.pdf');
    //
    //   // this.spinner.hide();
    // });

  }
  // feedback  form
  feedbackAssessment(val){
    this.modalRef = this.modalService.show(FeedbackDialogComponent,

      {

      }
    );
    // add assessment feedback
    this.modalRef.content.onClose.subscribe(result => {
      if(result){
        var data = { assessment_id: val.assessment_id,
                     customer_user_id: val.customer_user_id,
                     customer_assessment_id: val.id,
                     feedbackData: JSON.stringify(result)
                    };
        this.apiService.postwithouttoken('addfeedbacktoassessment', data)
          .subscribe(
            result => {

              if (result['success']) {
                this.apiService.toastrMsg(result['message'], 'success');

                this.getAssessments(this.invited_user_id);
              } else {
                this.apiService.toastrMsg(result['message'], 'error');
              }
            },
            fail => {
              if (fail.status == 401) {
                this.router.navigateByUrl('/');
              } else {
                this.apiService.toastErrorMsg(fail);
              }
            }
          );
      }
    });
	  this.modalRef.setClass('Recruitonic-feedback');

  }

  // get result data
  downloadResult(val,type) {


    this.created_date = val.created_date;
    this.spinner.show();
    var data = {
      assessment_id: val.assessment_id,
      customer_assessment_id: this.invited_user_id,
      no_of_attempted: val.no_of_attempted,
      // obtain_marks : this.final_result
    };

    this.apiService.postwithouttoken('downloadresul', data)
      .subscribe(
        result => {
          if (result['success']) {

            let graphcategories=[];
            let questions=[];
            let answers=[];
            if(result['is_cognitive']==1) {
              this.fresult = result['data'];
              let resultInfo = result['data']['resultInfo'];
              this.level_result = result['level_task_details'];
              this.customerInfo = result['customerInfo'];
              this.inviteeDetails = result['inviteeDetails'];
              this.best_five = result['best_five'];
              // this.best_five = this.best_five.sort((a, b) => a.tp_marks - b.tp_marks);

              this.least_five = result['least_five'];
              this.overall_grade = result['overall_grade'];
              this.assessment_started_date = result['assessment_started_date'];
              resultInfo = resultInfo.sort((a, b) => a.Difficulty_level_master.sort_id - b.Difficulty_level_master.sort_id);
              this.totalmarks = this.fresult.sum_of_total.sum_of_marks_obtain;
              resultInfo.forEach(element => {
                // this.totalmarks = Number(this.totalmarks) + Number(element.mark_obtained);
              let title= element.Assessment.Languages_master.lang_title.includes('हिंदी')?element.Difficulty_level_master.language_1:element.Assessment.Languages_master.lang_title.includes('मराठी')?element.Difficulty_level_master.language_2:element.Assessment.Languages_master.lang_title.includes('ગુજરાતિ')?element.Difficulty_level_master.language_3:element.Difficulty_level_master.difficulty_level;
                graphcategories.push(title + " "+this.languageDetails.question);
                questions.push(element.no_of_question);
                answers.push(parseInt(element.answer_status));
              });
              this.overall_percentage = Math.round((this.totalmarks / this.fresult.sum_of_total.sum_of_total_marks) * 100);
              this.isPdf = true;

              const graphDetails = {categories: graphcategories, questions: questions, answers: answers};
              setTimeout(()=>{
                if(type=='c'){this.certificateModal()}else{
                  if(result['is_cognitive']==1) {
                    this.downloadResultAsPDF(graphDetails);
                  }else{
                    this.downloadCognitiveResultAsPDF();

                  }

                }
              }, 600);
            }else{
              this.cognitiveReportDetails = result['topicDetails'];
              this.normData = result['normData'];

              this.fresult = result['data'];

              this.customerInfo = result['customerInfo'];
              this.inviteeDetails = result['inviteeDetails'];

              this.overall_grade = result['overall_grade'];
              this.assessment_started_date = result['assessment_started_date'];
              this.totalmarks = this.fresult.sum_of_total.sum_of_marks_obtain;
              this.overall_percentage = Math.round((this.totalmarks / this.fresult.sum_of_total.sum_of_total_marks) * 100);
              this.iscPdf = true;
              setTimeout(()=>{
                if(type=='c'){this.certificateModal()}else{

                    this.downloadCognitiveResultAsPDF();



                }
              }, 600);
            }

          }
        },
        fail => {
          this.spinner.hide();
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  // result chart for pdf
  overlapChartSet(graphDetails){
    Highcharts.chart('overlapchart', {
      title: {
        text: ''
      },
      chart: {
        type: 'column',
        inverted: true
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: graphDetails.categories,

      },
      yAxis: [{
        min: 0,
        // max: 9,
        // tickInterval: 1,
        title: {
          text: ''
        }
      }, {
        title: {
          text: ''
        },
        opposite: true
      }],
      legend: {
        shadow: false,
        symbolRadius: 0,
        squareSymbol: false
      },
      tooltip: {
        shared: true
      },

      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
      },
      series: [{
        name: this.languageDetails.no_of_questions,
        color: 'rgba(204,204,204,1)',
        data: graphDetails.questions,
        pointPadding: 0,
        groupPadding: 0.1,
        pointWidth:20,
        pointPlacement: 0,
      }, {
        name: this.languageDetails.answered_correctly,
        color: 'rgba(0,99,219,.9)',
        data: graphDetails.answers,
        pointPadding: 0.2,
        pointPlacement: 0,
        pointWidth:10,

      }]
    });

  }
  // export result as pdf
    downloadResultAsPDF(graphDetails) {

      const div = document.getElementById('pdftemp');
      div.style.display = 'block';

      this.overlapChartSet(graphDetails);
        // this.spinner.hide();
      this.insertBreaks();
    setTimeout(()=> {

        var opt = {
          margin:       [3,0,16,0],
          filename:     'Recruitonic_Assessment_Report.pdf',
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2, dpi: 144, letterRendering: false, scrollX: 0, scrollY: 0 },
          jsPDF:        { unit: 'mm', format: 'c4', orientation: 'portrait' }

        };

      html2pdf().from(div).set(opt).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          let text =''+(i>9?i:'0'+i)+'';
          pdf.setPage(i);
          pdf.setFontSize(13);
          pdf.setTextColor(255);
          if(i>1) {
            pdf.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAAAjCAYAAAANIjHoAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAB7SURBVHhe7dJBDcAwAMSw65CP+foZiUj2JwRytvcbxDx/IcW4JBmXJOOSZFySjEuScUkyLknGJcm4JBmXJOOSZFySjEuScUkyLknGJcm4JBmXJOOSZFySjEuScUkyLknGJcm4JBmXJOOSZFySjEuScUkyLknGJcm4BG0X+1oBxQ8EPkIAAAAASUVORK5CYII=', 'PNG', 0, 310);
            pdf.text(text, 16, pdf.internal.pageSize.getHeight() - 8);
          }
        }

      }).save().then(value => {
              div.style.display = 'none';
          this.spinner.hide();
      });
        // html2pdf().from(div).set(opt).save().then(value => {
        //   div.style.display = 'none';
        //   this.spinner.hide();
        // });

      },1000);




  }
  insertBreaks(){

    const removeElements = (elms) => elms.forEach(el => el.remove());
    removeElements(document.querySelectorAll(".oldelement"));
    //get table rows in html
    [].forEach.call(document.querySelectorAll('.pdfreport-table'), function (el) {
      let current_page_height = 0; //
      let max_page_height = 790;

      if (el.childNodes) {
        let itemsArray = Array.from(el.childNodes);

        let tbodyData =itemsArray.filter(a=>a['localName']=='tbody');
        if(tbodyData.length>0) {
          let trData =tbodyData[0]['children'];

          for (let tr of trData) {
            var row_height = tr.clientHeight;
            current_page_height = current_page_height + row_height;

            //If the sum of page rows heights are bigger thant my limit, then insert break
            if (current_page_height > max_page_height) {
              current_page_height = 0
              $(tr).before('<div class="oldelement html2pdf__page-break"></div>');
            }
          }
        }

      }
    });
    [].forEach.call(document.querySelectorAll('.pdfreport-table-least'), function (el) {
      let current_page_height = 0; //
      let max_page_height = 380;
      if (el.childNodes) {
        let itemsArray = Array.from(el.childNodes);

        let tbodyData =itemsArray.filter(a=>a['localName']=='tbody');
        if(tbodyData.length>0) {
          let trData =tbodyData[0]['children'];

          for (let tr of trData) {
            var row_height = tr.clientHeight;
            current_page_height = current_page_height + row_height;

            //If the sum of page rows heights are bigger thant my limit, then insert break
            if (current_page_height > max_page_height) {
              current_page_height = 0;
              $(tr).before('<div class="oldelement html2pdf__page-break"></div>');

            }
          }
        }

      }
    });


  }
    downloadCognitiveResultAsPDF() {

      const div = document.getElementById('pdfcongnitivetemp');
      div.style.display = 'block';

      //this.spinner.hide();
      // return;
       // this.spinner.hide();
      const pages = Array.from(document.getElementsByClassName('cog'));

    setTimeout(() => {


        var opt = {
          margin:       [3,0,16,0],
          filename:     'Recruitonic_Assessment_Report.pdf',
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2, dpi: 192, letterRendering: true, scrollX: 0, scrollY: 0 },
          jsPDF:        { unit: 'mm', format: 'c4', orientation: 'portrait' },

        };
    // .from(pages[0]).toContainer().get('container')
      let worker = html2pdf().set(opt);
      pages.forEach((page, index) => {
        // console.log(index,'iiiiiiiiiiii');
        worker = worker
          .from(page)
          .toContainer(index + 1)
          .toCanvas()
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            var pages_no= pdf.internal.getNumberOfPages();
            let text = ''+(pages_no>9?pages_no:'0'+pages_no)+'';

              pdf.setPage(pages_no);
              pdf.setFontSize(13);
              pdf.setTextColor(255);
            if(pages_no > 1) {
              pdf.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAAAjCAYAAAANIjHoAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAB7SURBVHhe7dJBDcAwAMSw65CP+foZiUj2JwRytvcbxDx/IcW4JBmXJOOSZFySjEuScUkyLknGJcm4JBmXJOOSZFySjEuScUkyLknGJcm4JBmXJOOSZFySjEuScUkyLknGJcm4JBmXJOOSZFySjEuScUkyLknGJcm4BG0X+1oBxQ8EPkIAAAAASUVORK5CYII=', 'PNG', 0, 310);
              pdf.text(text, 16, pdf.internal.pageSize.getHeight() - 8);
            }

            if (index < pages.length - 1) {

              // dont add last blank page
              pdf.addPage();
            }
          });
      });

      worker = worker.save().then(value => {
        this.zone.run(() => {
          div.style.display = 'none';
          this.spinner.hide();
        });
      });
      // html2pdf().from(div).set(opt).toPdf().get('pdf').then(function (pdf) {
      //   var totalPages = pdf.internal.getNumberOfPages();
      //
      //   for (let i = 1; i <= totalPages; i++) {
      //     let text =''+(i>9?i:'0'+i)+'';
      //     pdf.setPage(i);
      //     pdf.setFontSize(13);
      //     pdf.setTextColor(255);
      //     if(i>1) {
      //       pdf.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAAAjCAIAAACCQKa/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAGfSURBVHhe7ZvdbsIwDEbjRNPe/4pnZWqT2U7ouOimjZV+jviOCg0EUdocOT8uki+XKpJ026W1b6uO4odD7FQ121qxvSypV9Z8+1jWF0mszl/e+NshjubZRz/o+/XaTYfboEix39/cg3G+te/IA0yoQvNm1yctWNlPQfU3D9adkEB+x4xRQfHAMFrcCz0M/j/YvjDTqaB9wdYjdAn6+1rQc9GNNjzIpFGBHA9VIAOqQAZUgQyowvOZZF5DFciAKpABVSADqnAirfXkSV8sjQZVOI/3pX4ZEM8GqnAe17eyXe+RS7XJRZQmoAoAclqbVAsLllBdPKeKT69TBQBViqgHlk8P1ElQBQxlc6BpE/g9OGioAoZ8Hw5iRAeqgOGj+K14RrcAvzhNFYBo8/tMQgcNNm4AQxXg4ONBhyoACTBAuIMq4NBwEGPA2KEKUKxz8LWmAFAFLNkHjCFsoApwdFYpvgINhipAsZAQYqlRoQpoRjzANwRVgGIaeFrSEpVgqAIcjwr9P8FQqAIUWX0+qQ+q8OJYMAjhgSItwDSG4EnpE3GaZ4YYwVnxAAAAAElFTkSuQmCC', 'PNG', 0, 310);
      //       pdf.text(text, 16, pdf.internal.pageSize.getHeight() - 8);
      //     }
      //   }
      //
      // }).save().then(value => {
      //       div.style.display = 'none';
      //     this.spinner.hide();
      // });
        // html2pdf().from(div).set(opt).save().then(value => {
        //   div.style.display = 'none';
        //   this.spinner.hide();
        // });

      },1000);




  }

  getAssessments(invited_user_id) {
    var data = {
      invited_user_id: invited_user_id,
      record: 1
    }
    this.apiService.postwithouttoken('showassessmenttostart', data)  //getmyassessments
      .subscribe(
        result => {
          this.assessmentDetails = result['assessmentInfo']; //myAssessmentsList
          this.getLanguageDetails();
          this.getResultInfo(this.assessmentDetails.Assessment.id, this.assessmentDetails.id, this.assessmentDetails.no_of_attempted);
          this.remaining_attempts = this.assessmentDetails.permitted_attempt - this.assessmentDetails.no_of_attempted;
          if(this.remaining_attempts < 0)
            this.remaining_attempts = 0;
          this.customer_details = result['customer_details'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  getLanguageDetails() {
    var data = {
      language_id: this.assessmentDetails.Assessment.language_id,

    }
    this.apiService.postwithouttoken('getlanguagedetails', data)
      .subscribe(
        result => {
          let data = result['record'];
          this.languageDetails={};
          for(let val of data) {
            this.languageDetails[val.meta_key]  = val.meta_value;
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  getLocations(status, url){
    let me  = this;

    if ( navigator.permissions && navigator.permissions.query) {
      //try permissions APIs first
      navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
        // Will return ['granted', 'prompt', 'denied']
        const permission = result.state;
        if ( permission === 'granted' || permission === 'prompt' ) {
          navigator.geolocation.getCurrentPosition(location=>{
            me.apiService.getGeolocation(location.coords).subscribe(value => {
              if(value['status'] === 'OK' && value['results'][0]) {
                localStorage.setItem('location', value['results'][0].formatted_address);
              }
            });
            me.openTestWindow(status, url);
          },err => {
            localStorage.setItem('location', 'Location disabled');
            me.openTestWindow(status, url);

          });
        } else {
          localStorage.setItem('location', 'Location disabled');
          me.openTestWindow(status, url);

        }
      });
    }
    else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(location => {
        me.apiService.getGeolocation(location.coords).subscribe(value => {
          if(value['status'] === 'OK' && value['results'][0]) {
            localStorage.setItem('location', value['results'][0].formatted_address);
          }
        });
        me.openTestWindow(status, url);
      },err => {
        localStorage.setItem('location', 'Location disabled');
        me.openTestWindow(status, url);
      });
    } else {
      localStorage.setItem('location', 'Location disabled');
      me.openTestWindow(status, url);
    }

  }

  openTestWindow(status, unique_url){
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    var assessmenturl = this.baseUrl + 'assessment-start/' + unique_url + '/' + localStorage.getItem('device_id') + '/' + localStorage.getItem('customer_id') + '/'  + (isMobile ? '1' : (isTablet ? '1' : '2' ));
    if(status == 7)
      assessmenturl = this.baseUrl + 'assessment-questions/' + unique_url + '/' + localStorage.getItem('device_id') + '/' + localStorage.getItem('customer_id') + '/' + (isMobile ? '1' : (isTablet ? '1' : '2' ));
    let me = this;

    try {

      this.router.navigate([]).then(result => {
        var new_window =  window.open(assessmenturl, 'testwindow', ' dialog=yes,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no, width=' + screen.width + ', height=' + screen.height);
        if(new_window!=null){new_window.focus();}
        var timer = setInterval(function() {
          //if(new_window.closed) {
          if(!new_window || new_window.closed || typeof new_window.closed=='undefined') {

            clearInterval(timer);

            me.redirectTodashbord();

          }
        }, 1000);
      });
    }catch (e) {

      this.apiService.toastrMsg('Pop-up may be blocked! Please add this site to your exception list.', 'error');

    }

  }
  startAssessment(assessment_id, invitee_id) {


      var data = {
        invited_user_id: invitee_id,
        assessment_id: assessment_id,
        customer_assessment_id: this.assessmentDetails.id,
        no_of_attempted: this.assessmentDetails.no_of_attempted,
        status: this.assessmentDetails.status
      }
      this.is_disabled = 1;
      this.apiService.postwithouttoken('checksumgenerate', data)
        .subscribe(
          result => {
            let unique = result['unique_url'];
            if (unique != undefined) {
              this.getLocations(data.status, unique);
            } else {
                this.apiService.toastrMsg(result['message'], 'error');
              }
            this.is_disabled = 0;
          },
          fail => {
            this.apiService.toastErrorMsg(fail);
          }
        );


  }
  redirectTodashbord(){
    var me =this;

    this.zone.run(() => {
      me.router.navigate(['/user-assessment-details/' + me.assessment_id + '/' + me.invited_user_id]);
       window.location.reload();
    });
  }
  getResultInfo(assessment_id, customer_assessment_id, no_of_attempted) {
    var data = {
      assessment_id: assessment_id,
      customer_assessment_id: customer_assessment_id,
      no_of_attempted: no_of_attempted
    }

    console.log("========ddddddddd===========", data);

    this.apiService.postwithouttoken('getresultinfo', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.resultInfo = result['data'];
            if(result['data'].length!=0) {
              if (result['data'][0]['Assessment']['assessment_topic_meta'] != null && result['data'][0]['Assessment']['assessment_topic_meta'] != undefined && result['data'][0]['Assessment']['assessment_topic_meta'] != '') {
                this.isCog = 1;
              }else{
                this.isCog = 0;
              }
            }
            console.log("========ddddddddd===========");
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  checkLogin(assessment_id, invitee_id) {
    var data = {device_id:''};
    data.device_id = new DeviceUUID().get();
    this.apiService.postwithouttoken('checkotherdeviceusertest', data)
      .subscribe(
        result => {
          if (result['success']) {
            if(result['is_login']==1) {
              swal.fire({
                title: 'Are you sure?',
                text: 'You are already signed in another device,are you sure to sign out from other device and sign in here ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, sign out!',
                cancelButtonText: 'No, keep it',
                confirmButtonColor: '#FE9900',
                cancelButtonColor: '#ccc',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.apiService.postwithouttoken('logoutuser', {})
                    .subscribe(
                      result => {
                        if (result['success']) {

                          localStorage.clear();

                          this.apiService.toastrMsg('Sign out from other device successfully, Try Sign In', 'success');
                          this.router.navigate(['/']);

                        }
                      },
                      fail => {
                        this.apiService.toastErrorMsg(fail);
                      }
                    );
                }
              });
            } else {
              this.startAssessment(assessment_id, invitee_id);

            }
          }
          });
  }


}
