import { Component, OnInit , Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {

  // @Input() title: string;
  // @Input() message: string;
  public btnOkText: string;
  public btnCancelText: string;
  public onClose: Subject<boolean>;
  feedbackQuestion:any;
  constructor(public modalRef: BsModalRef) {
    this.feedbackQuestion=[
      {questions_text:'Questions were designed to explore knowledge and skills of the subject area',rating:1,is_any_remark:''},
      {questions_text:'Difficulty level of questions',rating:1,is_any_remark:''},
      {questions_text:'Language of the questions was clear and precise',rating:1,is_any_remark:''},
      {questions_text:'Topics in questions were relevant and connected to day to day work',rating:1,is_any_remark:''},
      {questions_text:'Visual presentation of the Assessment ( User Experience )',rating:1,is_any_remark:''},
      {questions_text:'The flow and ease of Recruitonic Questions while taking the test',rating:1,is_any_remark:''},
      {questions_text:'Time was sufficient to complete the assessment',rating:1,is_any_remark:''},
      {questions_text:'Overall experience',rating:1,is_any_remark:''},
      {questions_text:'Remarks, if any',rating:1,is_any_remark:''}
      ];
    // * if changes Remarks, if any text then change this on html also//
  }


  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    console.log(this.feedbackQuestion);
    this.onClose.next(this.feedbackQuestion);
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.modalRef.hide();
  }
}
