import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../../app.service';
import * as AOS from 'aos';
import { AppConstants } from '../../constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DefaultLayoutComponent } from '../../containers';
import {DeviceUUID} from 'device-uuid';

@Component({
  selector: 'app-userpage',
  templateUrl: './userpage.component.html',
  styleUrls: ['./userpage.component.css']
})
export class UserpageComponent implements OnInit {

  @ViewChild('confirmtemplate') confirmtemplate: TemplateRef<any>;

  assessmentList: any;
  caursesList: any;
  private url = AppConstants.imageURL;
  private hide_course = AppConstants.hide_course;
  private baseUrl = AppConstants.baseURL;
  user_name: any;
  subjectList: any;
  yourWishList: any;
  assessmentsWishList: any;
  assessmentListBySubject: any;
  invited_user_id: any;
  verification_code :any;

  feturedassessmentList: any;
  myAssessmentsList: any;

  recordId: any;
  modalRef: BsModalRef;

  formsubmitted = false;
  verificationForm: FormGroup;
  otpForm: FormGroup;
  loginForm: FormGroup;

  assessmentInfo: any;
  questionarray: any;
  successmeg: any;

  userForm: FormGroup;
  invitedUserInfo: any;
  thankyou_display: any;
  user_status :any;
  direct_login = 0;
  assessment_id: any;
  invitee_name: any;
  invitee_email: any;
  customer_dashboard :any;
  email_length : any;

  clicked_otp = false;
  clicked_verificationcode = false;
  groupAssessmentList :any;

  subArray = [];

  email_id : any;

  categories: any = {
    items: [{
      id: 'slide-1',
      displayedName: 'Slide 1'
    },
      {
        id: 'slide-2',
        displayedName: 'Slide 2'
      },
      {
        id: 'slide-3',
        displayedName: 'Slide 3'
      },
      {
        id: 'slide-4',
        displayedName: 'Slide 4'
      },
      {
        id: 'slide-5',
        displayedName: 'Slide 5'
      },
    ]
  };
  deviceInfo: any;
  constructor(private modalService: BsModalService, private apiService: ApiService, private router: Router, private activatedRoute: ActivatedRoute, private manureload : DefaultLayoutComponent) {
    let token = localStorage.getItem('access_token');
    // if (token == null) {
    //   this.router.navigate(['/']);
    // }


    this.verification_code = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.verification_code == null) {
      if (this.apiService.checkTokenExpiry()) {
        this.router.navigate(['/']);
      }
      this.user_name = localStorage.getItem('c_name');
      this.thankyou_display = localStorage.getItem('thankyou_display');
      this.getSubjectList();
      this.getFeturedAssessmentList();
      this.getMyAssessments();
    } else {
      this.checkUserIsVerified(this.verification_code);
    }

  }

  title = 'owl-carousel-libdemo';
  owlNext = '→';
  owlPrev = '←';

  customOptions1: OwlOptions = {
    autoWidth: false,
    loop: false,
    // items: '10',
    margin: 25,
    // slideBy: 'page',
    stagePadding: 3,
    merge: false,
    autoplay: false,
    autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    autoplaySpeed: 2000,
    dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    smartSpeed: 400,

    // fluidSpeed: 499,
    dragEndSpeed: 350,
    // dotsEach: 4,
    center: false,

    // rewind: true,
    // rtl: true,
    startPosition: 0,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2,

      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      }
    },
    //stagePadding: 100,
    nav: true
  }


  customOptions5: OwlOptions = {
    autoWidth: false,
    loop: false,
    // items: '10',
    margin: 25,
    // slideBy: 'page',
    stagePadding: 3,
    merge: false,
    autoplay: false,
    autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    autoplaySpeed: 2000,
    dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    smartSpeed: 400,

    // fluidSpeed: 499,
    dragEndSpeed: 350,
    // dotsEach: 4,
    center: false,

    // rewind: true,
    // rtl: true,
    startPosition: 0,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2,

      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      }
    },
    //stagePadding: 100,
    nav: true
  }


  customOptions2: OwlOptions = {
    autoWidth: false,
    loop: false,
    // items: '10',
    margin: 25,
    // slideBy: 'page',
    stagePadding: 3,
    merge: false,
    autoplay: false,
    autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    autoplaySpeed: 2000,
    dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    smartSpeed: 400,

    // fluidSpeed: 499,
    dragEndSpeed: 350,
    // dotsEach: 4,
    center: false,

    // rewind: true,
    // rtl: true,
    startPosition: 0,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    responsive: {
      0: {
        items: 1,

      },
      400: {
        items: 2,

      },
      740: {
        items: 3,

      },
      940: {
        items: 4,
      }
    },
    nav: true
  }

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: false,
    // items: '10',
    margin: 55,
    // slideBy: 'page',
    merge: true,
    autoplay: false,
    autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    autoplaySpeed: 2000,
    dotsSpeed: 500,
    dots: false,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    smartSpeed: 400,

    // fluidSpeed: 499,
    dragEndSpeed: 350,
    // dotsEach: 4,
    center: false,

    // rewind: true,
    // rtl: true,
    startPosition: 0,
    navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }

    },
    nav: true
  }
  activeSlides: any;

  ngOnInit() {
    AOS.init({
      once: true
    });

    this.verificationForm = new FormGroup({
      verification_code: new FormControl('', [Validators.required])
    });
    this.otpForm = new FormGroup({
      otp: new FormControl('', [Validators.required])
    });
    this.userForm = new FormGroup({
      c_name: new FormControl('', [Validators.required]),
      c_email_id: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      mobile_no: new FormControl('', [Validators.required]),
      c_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)]),
    });
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    if (this.verification_code == null) {
      this.getAssessmentList();
      this.getCaursesList();
      this.assessmentWishList();
    }

    // this.getAssessmentsForTest(this.assessment_id);
  }

  ngAfterViewInit() {
    if (this.assessmentInfo == undefined && this.user_name == null) {
      this.modalRef = this.modalService.show(this.confirmtemplate);
      this.modalRef.setClass('modal-selected');
    }
  }

  getAssessmentList() {
    var data = {
      internal: 1
    }
    this.apiService.postwithouttoken('assessmentlistfromweb', data)
      .subscribe(
        result => {
          this.assessmentList = result['assessmentList'];
          this.assessmentListBySubject = result['assessmentList'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  getCaursesList() {
    var data = {
      internal: 1
    }
    this.apiService.postwithouttoken('courselistfromweb', data)
      .subscribe(
        result => {
          this.caursesList = result['courseList'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  getSubjectList() {
    var data = {
      internal: 1
    }
    this.apiService.postwithouttoken('parentlistfromweb', data)
      .subscribe(
        result => {
          this.subjectList = result['subjectslist'];
          let cont = 0;
          this.subjectList.forEach(element => {
            if (cont == 0) {
              let obj = { 'id': element.Assessment.id, 'sub_title': element.Assessment.assess_title }
              this.subArray.push(obj);
              cont = 1;
            } else {
              this.subArray.forEach(abc => {
                let found = this.subArray.some(ele => ele.id === element.Assessment.id);
                if(!found){
                  let obj = { 'id': element.Assessment.id, 'sub_title': element.Assessment.assess_title }
                  this.subArray.push(obj);
                }
              });
            }
          });
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  assessmentWishList() {
    var data = {
      internal: 1
    }
    this.apiService.postwithouttoken('getwishlistofassessments', data)
      .subscribe(
        result => {
          this.assessmentsWishList = result['wishlistlist'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  getAssessmentListBySubject(id) {
    var data = {
      assessment_id: id
    }
    this.apiService.postwithouttoken('getgroupassessments', data).subscribe(
      result => {
        this.assessmentListBySubject = result['groupAssessments'];
      },
      fail => {
        this.apiService.toastErrorMsg(fail);
      }
    );
  }

  ResetModel() {
    this.modalRef.hide();
    this.router.navigate(['/']);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.verificationForm.controls;
  }

  verificationCode() {
    this.formsubmitted = true;
    // stop here if form is invalid
    if (this.verificationForm.invalid) {
      return;
    }
    var data = this.verificationForm.value;
    data.verification_code = this.verification_code;

    this.apiService.postwithouttoken('verificationcode', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.clicked_verificationcode = true;

            if (result['message'] == 'Invelid Code') {
              this.apiService.toastrMsg(result['message'], 'error');
            } else if (result['message'] == 'Assessmenst already completed') {
              this.apiService.toastrMsg(result['message'], 'error');
            } else if (result['message'] == 'Assessments is expired') {
              this.apiService.toastrMsg(result['message'], 'error');
            }
            else if (result['Verified']) {
              this.invitedUserInfo = result['Verified'];
              this.userForm.patchValue({
                c_name: this.invitedUserInfo.invitee_name,
                c_email_id: this.invitedUserInfo.invitee_email,
                mobile_no: this.invitedUserInfo.invitee_mobile_no
              });
              // this.verificationForm.reset();
              this.user_status = result['status'];
              this.successmeg = result['message'];
              // this.apiService.toastrMsg(result['message'], 'success');
            } else {
              this.apiService.toastrMsg(result['message'], 'error');
            }
          }
          else {
            this.apiService.toastrMsg(result['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  resendVerificationCode() {

    var data = this.verificationForm.value;
    data.verification_code = this.verification_code;
    data.invited_user_id = this.invited_user_id;
    this.apiService.postwithouttoken('verificationcode', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.clicked_verificationcode = true;

            if (result['message'] == 'Invelid Code') {
              this.apiService.toastrMsg(result['message'], 'error');
            } else if (result['message'] == 'Assessmenst already completed') {
              this.apiService.toastrMsg(result['message'], 'error');
            } else if (result['message'] == 'Assessments is expired') {
              this.apiService.toastrMsg(result['message'], 'error');
            }
            else if (result['Verified']) {
              this.invitedUserInfo = result['Verified'];
              this.userForm.patchValue({
                c_name: this.invitedUserInfo.invitee_name,
                c_email_id: this.invitedUserInfo.invitee_email,
                mobile_no: this.invitedUserInfo.invitee_mobile_no
              });
              // this.verificationForm.reset();
              this.user_status = result['status'];
              this.successmeg = result['message'];
              this.apiService.toastrMsg("OTP sent successfully", 'success');
            } else {
              this.apiService.toastrMsg(result['message'], 'error');
            }
          }
          else {
            this.apiService.toastrMsg(result['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

   // redirect to forgot password
  gotToForgotPassword(){
    this.router.navigate(['/forgot-password']);

  }
  // convenience getter for easy access to form fields
  get h() {
    return this.otpForm.controls;
  }

  otpSend() {
    this.formsubmitted = true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }
    var data = this.otpForm.value;
    this.user_name = localStorage.getItem('c_name');
    data.user_name = this.user_name;

    data.verification_code = this.verification_code;
    this.apiService.postwithouttoken('sendotp', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.clicked_otp = true;
            this.user_status = result['status'];
            this.apiService.toastrMsg(result['message'], 'success');
            this.invitedUserInfo = result['userInfo'];
            if(result['existUser']) {
              this.user_status = 5;
              this.invited_user_id = result['userInfo'].id;

              this.loginForm.patchValue({
                email: result['existUser'].c_email_id,
              });
              // if(this.user_name != undefined && this.user_name != '') {
              //   this.modalRef.hide();
              //   this.router.navigate(['/my-assessment']);
              // } else {
              //   this.loginForm.patchValue({
              //     email: result['existUser'].c_email_id,
              //   });
              // }

              // this.modalRef = this.modalService.show(this.confirmtemplate);
              // this.modalRef.setClass('modal-selected');
            }else{
              this.userForm.patchValue({
                c_name: this.invitedUserInfo.invitee_name,
                c_email_id: this.invitedUserInfo.invitee_email,
                mobile_no: this.invitedUserInfo.invitee_mobile_no
              });
            }
          } else {
            this.apiService.toastrMsg(result['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  // import Confirm Send modal open
  signupModel(signupmodel: TemplateRef<any>) {
    this.modalRef = this.modalService.show(signupmodel);
  }

  // convenience getter for easy access to form fields
  get g() {
    return this.userForm.controls;
  }

  userSave() {
    this.formsubmitted = true;

    if (this.userForm.invalid) {
      return;
    }


    const password = this.userForm.value.c_password;

    var data = this.userForm.value;
    data.invited_user_id = this.invited_user_id;
    data.customer_id = this.invitedUserInfo.created_by;
    data.c_password = window.btoa(password);

    this.apiService.postwithouttoken('saveuserascustomer', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.modalRef.hide();
            this.customerLogin(this.userForm.value.c_email_id, window.atob(this.userForm.value.c_password));
            this.router.navigate(['/user-assessment-details/' + this.assessment_id + '/' + this.invited_user_id]);

          } else {
            this.apiService.toastrMsg(result['error'], 'error');
            if (result['error'] == 'Already exists please login.') {
              this.user_status = 5;
            }
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  // convenience getter for easy access to form fields
  get l() {
    return this.loginForm.controls;
  }

  // Function to login in
  customerLogin(email, password) {
    this.formsubmitted = true;
    this.deviceInfo = new DeviceUUID().get();

    var data = {
      email: email,
      password: window.btoa(password),
      device_id: this.deviceInfo
    };
    this.apiService.postwithouttoken('customerlogin', data)
      .subscribe(
        result => {
          if (result['success']) {
            localStorage.setItem('device_id', this.deviceInfo);

            if(result['message']!= 'already_signed') {

              localStorage.setItem('c_name', result['customer'].c_name);
              localStorage.setItem('access_token', result['token']);
              localStorage.setItem('login_token', result['token']);
              localStorage.setItem('c_email_id', result['customer'].c_email_id);
              localStorage.setItem('customer_id', result['customer'].id);
              let is_customer_dashboard = result['customer'].customer_id == null?'0':'1';
              localStorage.setItem('customer_dashboard',is_customer_dashboard);
              // this.router.navigate(['/my-assessment', this.invited_user_id]);
              // this.router.navigate(['/my-assessment', this.invited_user_id]).then(() => {
              // 	window.location.reload();
              // });
              this.manureload.reloadManu(result['customer'].c_name);
              // this.router.navigate(['/my-assessment']);
              this.getAssessmentsForTest(this.invitedUserInfo.assessment_id);
              this.router.navigate(['/user-assessment-details/' + this.assessment_id + '/' + this.invited_user_id]);
            }else{
              this.apiService.toastrMsg('You are already signed in another device', 'error');


            }
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  customerLogin2() {
    this.formsubmitted = true;
    this.deviceInfo = new DeviceUUID().get();

    var data = this.loginForm.value;
    data.device_id = this.deviceInfo;
    data.password = window.btoa(this.loginForm.value.password);

    this.apiService.postwithouttoken('customerlogin', data)
      .subscribe(
        result => {
          if (result['success']) {
            localStorage.setItem('device_id', this.deviceInfo);

            if(result['message']!='already_signed') {

            localStorage.setItem('c_name', result['customer'].c_name);
            localStorage.setItem('access_token', result['token']);
            localStorage.setItem('c_email_id', result['customer'].c_email_id);
            localStorage.setItem('customer_id', result['customer'].id);
            localStorage.setItem('customer_dashboard', result['customer'].created_by);
            this.updateAssesmentStatusWhenLogin();
            this.modalRef.hide();

            this.manureload.reloadManu(result['customer'].c_name);
            this.router.navigate(['/my-assessment']);

            this.getAssessmentsForTest(this.assessment_id);
            }else{
              this.apiService.toastrMsg('You are already signed in another device', 'error');

            }
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  // update Assesment Status 5 When Login
  updateAssesmentStatusWhenLogin() {
    var data = {
      invited_user_id: this.invited_user_id,
    };
    this.apiService.postwithouttoken('updatecustomerassessmentstatus', data)
      .subscribe(
        result => {
          if (result['success']) {
          }
        },
        fail => {
        }
      );
  }
  getAssessmentsForTest(assess_id) {
    let email = localStorage.getItem('c_email_id');
    var data = {
      assessment_id: assess_id,
      email: email
    }
    this.apiService.postwithouttoken('showassessmenttostart', data)
      .subscribe(
        result => {
          if (result['success']) {
            this.assessmentInfo = result['assessmentInfo'];
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  getFeturedAssessmentList() {
    var data = {
    }
    this.apiService.postwithouttoken('feturedassessmentlistfromweb', data)
      .subscribe(
        result => {
          this.feturedassessmentList = result['feturedassessmentList'];
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  getMyAssessments() {
    let email = localStorage.getItem('c_email_id')
    var data = {
      // search: '',
      // assessment_id: assess_id,
      email: email
    }
    this.apiService.postwithouttoken('showassessmenttostart', data)  //getmyassessments
      .subscribe(
        result => {
          this.myAssessmentsList = result['assessmentInfo']; //myAssessmentsList
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  checkUserIsVerified(verification_code) {
    var data = {
      verification_code: verification_code
    }

    this.apiService.postwithouttoken('checkuserisverified', data)
      .subscribe(
        result => {
          this.user_status = 0;


          if (result['invited_user'] != null) {
            let user = result['invited_user'];
            let customer_user_det = result['customer_user_det'];
            if(user.status == 2){
              this.user_status = 4;
              // this.apiService.toastrMsg('Already registered please login', 'success');
            }

            // Check if logged in user and the request link user are same or not. If not, the clear the localstorage
            var stored_cust_id = localStorage.getItem('customer_id');
            console.log(stored_cust_id, customer_user_det);
            if(customer_user_det) {
              if (stored_cust_id != customer_user_det.id) {
                localStorage.clear();
              }
            }
            this.invitee_name = user.invitee_name;
            this.invitee_email = user.invitee_email;
            this.invited_user_id = user.id;
            this.email_length = this.invitee_email.split('@');
            // email fotmating
            var emilString = this.invitee_email;
            var splitEmail = emilString.split("@");
            var domain = splitEmail[1];
            var name = splitEmail[0];
            this.email_id = name.length > 3 ? name.substring(0, 3).concat(Array(name.length - 3).join("*")).concat("@").concat(domain) : name.substring(0, 1).concat(Array(name.length - 1).join("*")).concat("@").concat(domain);

            this.assessment_id = user.assessment_id;
            this.user_status = user.status == 2 ? 4 : user.status;
            this.getAssessmentsForTest(this.assessment_id);
          } else {
            this.modalRef.hide();
            this.apiService.toastrMsg(result['message'], 'error');
            this.router.navigate(['/']);
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  // viewAssessmentDetails(item){
  // 	console.log("========viewAssessmentDetails=======", item);
  // 	this.router.navigate(['/user-assessment-details/{{item.Assessment.id}}' ]);
  // 	// routerLink="/user-assessment-details/{{item.Assessment.id}}"
  // }

  startAssessment(assessment_id, invitee_id) {

    var data = {
      invited_user_id: invitee_id,
      assessment_id: assessment_id
    }
    this.apiService.postwithouttoken('checksumgenerate', data)
      .subscribe(
        result => {
          let unique = result['unique_url'];
          if (unique != undefined) {
            //  var assessmenturl = 'http://localhost:4200/assessment-start/' + unique;
            //  this.router.navigate([]).then(result => {  window.open(assessmenturl, 'testwindow', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, width='+screen.width+', height='+screen.height); });
            //  this.router.navigate([]).then(result => { window.open(assessmenturl, '_blank'); });

            var assessmenturl = this.baseUrl+'assessment-start/' + unique;
            this.router.navigate([]).then(result => { window.open(assessmenturl, 'testwindow', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, width=' + screen.width + ', height=' + screen.height); });

          } else {
            this.apiService.toastrMsg(result['message'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  redirectPage(assessment_id,assess_title) {
    var data = {
      assessment_id: assessment_id
    }
    this.apiService.postwithouttoken('getgroupassessments', data)
      .subscribe(
        result => {
          this.groupAssessmentList = result['groupAssessments'];
          if(this.groupAssessmentList.length > 0){
            this.router.navigate(['/assessments-group/', assessment_id]);
            // alert("====group====");
          } else {
            this.router.navigate(['/assessments-details/', assess_title.split(" ").join("-")]);
            // alert("====assessment details====");
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
}
