import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

   panelOpenState = false;
  search:any;

  faQData:any=[];
  filteredOptions : any = [];

  constructor() {
    this.faQData=[
      {title:'I did not receive an invitation email for the test?',ans:'Please check your spam folder along with your inbox. If you still couldn’t find it, then contact your assessment provider to resend you the invite to take the test'},
      {title:'How to start the assessment?',ans:'You’ll receive an invitation to take the test on your registered email account. Read the instructions, verify the assessment, and Sign up to start the assessment'},
      {title:'I cannot see the assessment test after Signing in',ans:'Please cross-check if you have verified the test link through an OTP from the invite email or please contact your assessment provider'},
      {title:'The message says the ‘The test link is no longer valid. What should I do now?',ans:'Please contact your assessment provider to receive a new invitation to reappear for the test'},
      {title:'I forgot my password, how can I Sign In to start the assessment?',ans:'Please click on the forgot password link you will see on the Sign-In pop-up. After you enter a valid email id, you will receive an email to verify and reset the password'},
      {title:'Did not receive OTP verification code email',ans:'Please click on Resend OTP button to verify your assessment on the OTP verification pop-up. if you still dint receive OTP then please contact us at info@recruitonic.com'},
      {title:'I am unable to sign-in',ans:'Please check your internet connection and close the browser window and try to sign in again'},
      {title:'Can I skip a question and answer it before submitting the test?',ans:'Yes, you can skip question/s and choose to answer them using the edit option at the end of the assessment'},
      {title:'Can I reappear for the test?',ans:'Yes, you can reappear for the test as currently, our system permits you 2 attempts by default or more if your organization offers it. Should you for any reason need more than the [ermitted attempts, you may please contact your assessment provider\n' +
          '\n'},
      {title:'How can I download my Recruitonic report?',ans:'Step 1: Please sign in with your registered email id.\n' +
          'Step 2: Go to the respective assessment page you want to download.\n' +
          'Step 3: Under the Assessment summary section includes an option to download your assessment report'},
      {title:'What should be my computer system’s configuration to take the test?',ans:'You can appear for the test with the least minimum system configuration but should have a good internet connection and a browser that is up to date.'},
      {title:'How to start the assessment?',ans:'You can appear for the test with the least minimum system configuration but should have a good internet connection and a browser that is up to date.\n' +
          '\n'},
      {title:'Which browser I can use to take the test?',ans:'You can use popular browsers like Google Chrome or Mozilla Firefox to take the test\n' +
          '\n'},
      {title:'Can I take the Recruitonic test on my mobile?',ans:'Yes, you can take the test on any Android mobile device with the latest version\n' +
          '\n'},
      {title:'Where can I see my score?',ans:'Download the report once you complete the test to check your score\n' +
          '\n'},
      {title:'How soon can I get my report and certificate?',ans:'You will be able to download the report and certificate immediately after you complete the test\n' +
          '\n'},
      {title:'For how long will my score be valid?',ans:'Score and report will be valid as long as you choose to reappear for the same assessment\n' +
          '\n'},
      {title:'How to display the assessment results on my resume?',ans:'Download the report and the certificate and upload them on your Job portal sites\n' +
          '\n'},
      {title:'How is my score calculated?',ans:'Recruitonic uses a unique and intelligent benchmarking design system to calculate your score and assign you an overall grade that combines your performance at different proficiency levels of a particular area of skill\n' +
          '\n'},
      {title:'Can I get the assessment report through email?',ans:'No, the assessment report is downloadable from your user account only\n' +
          '\n'},



    ];
	}


  ngOnInit() {
    this.filteredOptions= this.faQData;
  }

  searchEnter(value: string) {
    const filterValue = value.toLowerCase();

    this.filteredOptions= this.faQData.filter(option => option.title.toLowerCase().includes(filterValue));
  }



}
