import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { AppConstants } from '../../constants';
import { ApiService } from '../../app.service';

@Component({
	selector: 'app-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

	caursesList: any;
	subjectList: any;
	courseListBySubject: any;
	private url = AppConstants.imageURL;
	feturedcourseList: any;

	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};


	constructor(private apiService: ApiService) {

	}




	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';


	customOptions1: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}

	customOptions2: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}

	customOptions3: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}


	activeSlides: any;


	ngOnInit() {
		AOS.init({
			once: true
		});
		this.getCaursesList();
		this.getSubjectList();
		this.getFeturedCourseList();
	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}

	getCaursesList() {
		var data = {
		}
		this.apiService.postwithouttoken('courselistfromweb', data)
			.subscribe(
				result => {
					this.caursesList = result['courseList'];
					this.courseListBySubject = result['courseList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getSubjectList() {
		var data = {
			internal: 1
		}
		this.apiService.postwithouttoken('subjectlistfromweb', data)
			.subscribe(
				result => {
					this.subjectList = result['subjectslist'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	getAssessmentListBySubject(id) {
		var data = {
			subject_id: id
		}
		this.apiService.postwithouttoken('coursebysubjectid', data)
			.subscribe(
				result => {
					this.courseListBySubject = result['courseList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getFeturedCourseList() {
		var data = {
		}
		this.apiService.postwithouttoken('feturedcourselistfromweb', data)
			.subscribe(
				result => {
					this.feturedcourseList = result['feturedCourseList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
}
