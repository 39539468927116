import { Component } from '@angular/core';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'verified.component.html'
})
export class VerifiedComponent {


  formsubmitted1 = false;
  verifiedCodeForm: FormGroup;
  verification_code: any;

  constructor(private router: Router, private apiService: ApiService, private activatedRoute: ActivatedRoute) {
    this.verification_code = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.verifiedCodeForm = new FormGroup({
      verified_code: new FormControl(this.verification_code, [Validators.required])
    })
  }

  get v() {
    return this.verifiedCodeForm.controls;
  }

  verifiedCode() {
    this.formsubmitted1 = true;

    if (this.verifiedCodeForm.invalid) {
      return;
    }
    var data = this.verifiedCodeForm.value;
    this.apiService.postwithouttoken('verifyuser', data)
      .subscribe(
        result => {
          if (result['success']) {
            let data = result['user'];
            if (data != null) {
              localStorage.setItem('email_id', data.c_email_id)
              this.router.navigate(['/reset-password']);
            } else {
              this.apiService.toastrMsg('User not found', 'error');
            }
          } else {
            this.apiService.toastrMsg(result['error']['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

}
