import { Component, OnInit, TemplateRef, ElementRef, HostListener, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'jspdf-autotable';
import { JsonPipe } from '@angular/common';
import * as jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import html2pdf from 'html2pdf.js';
declare  let Highcharts:any;
import * as $ from 'jquery/dist/jquery.min.js';

import {NgxSpinnerService} from 'ngx-spinner';
import {FeedbackDialogComponent} from '../../containers/feedback-dialog/feedback-dialog.component';
@Component({
	selector: 'app-course-result',
	templateUrl: './course-result.component.html',
	styleUrls: ['./course-result.component.scss']
})
export class CourseResultComponent implements OnInit {


	// Keyboard event restrictions
	// @HostListener('document:keydown', ['$event'])
	// handleKeyboardEvent(event: KeyboardEvent) {
	// 	let keycodearr = [8, 18, 44, 116, 123]

	// 	if (keycodearr.includes(event.keyCode)) { // Specific keys restriction
	// 		event.returnValue = false;
	// 		event.preventDefault();
	// 	} else if ((event.ctrlKey || event.metaKey) && (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 82 || event.keyCode == 80 || event.keyCode == 79 || event.keyCode == 83)) { // Ctrl+C, Ctrl+V, Ctrl+R, Ctrl+P, Ctrl+O, Ctrl+S
	// 		event.returnValue = false;
	// 		event.preventDefault();
	// 	} else if (event.ctrlKey && event.shiftKey && event.keyCode == 74) { // Ctrl+Shift+J
	// 		event.returnValue = false;
	// 		event.preventDefault();
	// 	}
	// }

	// // Right click event disable
	// @HostListener('contextmenu', ['$event'])
	// onRightClick(event) {
	// 	event.preventDefault();
	// }


	@ViewChild('pdfTable') pdfTable: ElementRef;
	modalRef: BsModalRef;
	unique_url: any;
	assessmentInfo: any;
	questionarray: any;
	answerArray: any;
	total_questions: any;
	CustomerAssessmentId: any;
	question_easy: any;
	question_hard: any;
	question_medium: any;
	hard_array: any;
	medium_array: any;
	easy_array: any;
	types: any;
	hard_q_marks: any;
	easy_q_marks: any;
	medium_q_marks: any;
	hard_correct_ans: any;
	easy_correct_ans: any;
	medium_correct_ans: any;
	easy_correct_attempt: any;
	hard_correct_attempt: any;
	medium_correct_attempt: any;
	total: any;
	result: any;
	final_result: any;
	fresult: any;
	question_type_meta :any;
	resultInfo:any;
	level_result :any;
	level_1_array: any;
	level_2_array: any;
	level_3_array: any;
	level_4_array : any;
	level_5_array: any;
	customerInfo :any;
	overall_percentage :any;
	totalmarks = 0;
	best_five: any;
	least_five: any;
	overall_grade: any;
	assessment_started_date: any;
  graphDetails: any;
  customerAssessmentInfo:any;
  inviteeDetails:any;
  iscPdf: any = false;
  cognitiveReportDetails: any;
  normData: any;
  max = 100;
  min = 0;
  disabled = true;
  languageDetails:any;
  isPdf: any = true;
  numberPattern = /\d+/g;
  constructor(private spinner: NgxSpinnerService,private modalService: BsModalService, private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {
		this.unique_url = this.activatedRoute.snapshot.paramMap.get('id');
		this.getAssessmentsForTest(this.unique_url);
    localStorage.removeItem('countdown');
	}

	ngOnInit() {

	}
  // export result as pdf
      downloadResultAsPDF() {
      this.spinner.show();
        const div = document.getElementById('pdftemp');
        div.style.display = 'block';

        this.overlapChartSet(this.graphDetails);
        this.insertBreaks();
        setTimeout(()=> {
          var opt = {
            margin:       [3,0,16,0],
            filename:     'Recruitonic_Assessment_Report.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 1.2, dpi: 192, letterRendering: false, scrollX: 0, scrollY: 0 },
            jsPDF:        { unit: 'mm', format: 'c4', orientation: 'portrait' }
          };
          html2pdf().from(div).set(opt).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
              let text =''+(i>9?i:'0'+i)+'';
              pdf.setPage(i);
              pdf.setFontSize(13);
              pdf.setTextColor(255);
              if(i>1) {
                pdf.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAAAjCAIAAACCQKa/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAGfSURBVHhe7ZvdbsIwDEbjRNPe/4pnZWqT2U7ouOimjZV+jviOCg0EUdocOT8uki+XKpJ026W1b6uO4odD7FQ121qxvSypV9Z8+1jWF0mszl/e+NshjubZRz/o+/XaTYfboEix39/cg3G+te/IA0yoQvNm1yctWNlPQfU3D9adkEB+x4xRQfHAMFrcCz0M/j/YvjDTqaB9wdYjdAn6+1rQc9GNNjzIpFGBHA9VIAOqQAZUgQyowvOZZF5DFciAKpABVSADqnAirfXkSV8sjQZVOI/3pX4ZEM8GqnAe17eyXe+RS7XJRZQmoAoAclqbVAsLllBdPKeKT69TBQBViqgHlk8P1ElQBQxlc6BpE/g9OGioAoZ8Hw5iRAeqgOGj+K14RrcAvzhNFYBo8/tMQgcNNm4AQxXg4ONBhyoACTBAuIMq4NBwEGPA2KEKUKxz8LWmAFAFLNkHjCFsoApwdFYpvgINhipAsZAQYqlRoQpoRjzANwRVgGIaeFrSEpVgqAIcjwr9P8FQqAIUWX0+qQ+q8OJYMAjhgSItwDSG4EnpE3GaZ4YYwVnxAAAAAElFTkSuQmCC', 'PNG', 0, 310);
                pdf.text(text, 16, pdf.internal.pageSize.getHeight() - 8);
              }
            }

          }).save().then(value => {
            div.style.display = 'none';
            this.spinner.hide();
          });

        },1000);





      }
  insertBreaks(){

    const removeElements = (elms) => elms.forEach(el => el.remove());
    removeElements(document.querySelectorAll(".oldelement"));
    //get table rows in html
    [].forEach.call(document.querySelectorAll('.pdfreport-table'), function (el) {
      let current_page_height = 0; //
      let max_page_height = 790;

      if (el.childNodes) {
        let itemsArray = Array.from(el.childNodes);

        let tbodyData =itemsArray.filter(a=>a['localName']=='tbody');
        if(tbodyData.length>0) {
          let trData =tbodyData[0]['children'];

          for (let tr of trData) {
            var row_height = tr.clientHeight;
            current_page_height = current_page_height + row_height;

            //If the sum of page rows heights are bigger thant my limit, then insert break
            if (current_page_height > max_page_height) {
              current_page_height = 0
              $(tr).before('<div class="oldelement html2pdf__page-break"></div>');
            }
          }
        }

      }
    });
    [].forEach.call(document.querySelectorAll('.pdfreport-table-least'), function (el) {
      let current_page_height = 0; //
      let max_page_height = 380;
      if (el.childNodes) {
        let itemsArray = Array.from(el.childNodes);

        let tbodyData =itemsArray.filter(a=>a['localName']=='tbody');
        if(tbodyData.length>0) {
          let trData =tbodyData[0]['children'];

          for (let tr of trData) {
            var row_height = tr.clientHeight;
            current_page_height = current_page_height + row_height;

            //If the sum of page rows heights are bigger thant my limit, then insert break
            if (current_page_height > max_page_height) {
              current_page_height = 0;
              $(tr).before('<div class="oldelement html2pdf__page-break"></div>');

            }
          }
        }

      }
    });

  }
  downloadCResultAsPDF() {
    this.spinner.show();
    const div = document.getElementById('pdfcongnitivetemp');
    div.style.display = 'block';

    setTimeout(()=> {
      var opt = {
        margin:       [3,0,16,0],
        filename:     'Recruitonic_Assessment_Report.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2, dpi: 192, letterRendering: true, scrollX: 0, scrollY: 0 },
        jsPDF:        { unit: 'mm', format: 'c4', orientation: 'portrait' }

      };
      html2pdf().from(div).set(opt).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          let text =''+(i>9?i:'0'+i)+'';
          pdf.setPage(i);
          pdf.setFontSize(13);
          pdf.setTextColor(255);
          if(i>1) {
            pdf.addImage('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAAAjCAIAAACCQKa/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAGfSURBVHhe7ZvdbsIwDEbjRNPe/4pnZWqT2U7ouOimjZV+jviOCg0EUdocOT8uki+XKpJ026W1b6uO4odD7FQ121qxvSypV9Z8+1jWF0mszl/e+NshjubZRz/o+/XaTYfboEix39/cg3G+te/IA0yoQvNm1yctWNlPQfU3D9adkEB+x4xRQfHAMFrcCz0M/j/YvjDTqaB9wdYjdAn6+1rQc9GNNjzIpFGBHA9VIAOqQAZUgQyowvOZZF5DFciAKpABVSADqnAirfXkSV8sjQZVOI/3pX4ZEM8GqnAe17eyXe+RS7XJRZQmoAoAclqbVAsLllBdPKeKT69TBQBViqgHlk8P1ElQBQxlc6BpE/g9OGioAoZ8Hw5iRAeqgOGj+K14RrcAvzhNFYBo8/tMQgcNNm4AQxXg4ONBhyoACTBAuIMq4NBwEGPA2KEKUKxz8LWmAFAFLNkHjCFsoApwdFYpvgINhipAsZAQYqlRoQpoRjzANwRVgGIaeFrSEpVgqAIcjwr9P8FQqAIUWX0+qQ+q8OJYMAjhgSItwDSG4EnpE3GaZ4YYwVnxAAAAAElFTkSuQmCC', 'PNG', 0, 310);
            pdf.text(text, 16, pdf.internal.pageSize.getHeight() - 8);
          }
        }

      }).save().then(value => {
        div.style.display = 'none';
        this.spinner.hide();
      });

    },1000);





  }
  // result chart for pdf
  overlapChartSet(graphDetails){
    Highcharts.chart('overlapchart', {
      title: {
        text: ''
      },
      chart: {
        type: 'column',
        inverted: true
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: graphDetails.categories,

      },
      yAxis: [{
        min: 0,
        // max: 9,
        // tickInterval: 1,
        title: {
          text: ''
        }
      }, {
        title: {
          text: ''
        },
        opposite: true
      }],
      legend: {
        shadow: false,
        symbolRadius: 0,
        squareSymbol: false
      },
      tooltip: {
        shared: true
      },

      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
      },
      series: [{
        name: this.languageDetails.no_of_questions,
        color: 'rgba(204,204,204,1)',
        data: graphDetails.questions,
        pointPadding: 0,
        groupPadding: 0.1,
        pointWidth:20,
        pointPlacement: 0,
      }, {
        name: this.languageDetails.answered_correctly,
        color: 'rgba(41,157,95,.9)',
        data: graphDetails.answers,
        pointPadding: 0.2,
        pointPlacement: 0,
        pointWidth:10,

      }]
    });

  }
   pdfModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
		this.modalRef.setClass('recruitonic-reports-show');

    // const div = document.getElementById('pdftemp');
    // div.style.display = 'block';
    // const divHeight = div.clientHeight;
    // const divWidth = div.clientWidth;
    // const options = { background: 'white', width: divWidth, height: divHeight , quality: 0.95 };
    //
    // div.style.height ='0';
    // domtoimage.toPng(div,options).then((imgData) => {
    //   div.style.display = 'none';
    //   div.style.height ='auto';
    //   const doc = new jsPDF('p', 'mm', [divWidth, divHeight]);
    //   const imgProps = doc.getImageProperties(imgData);
    //   const pdfWidth = doc.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   doc.save('result.pdf');
    // });
	}

  certificateModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
		this.modalRef.setClass('Recruitonic-certificate-show');
	}


// feedback  form
  feedbackAssessment(val){
    this.modalRef = this.modalService.show(FeedbackDialogComponent,

      {

      }
    );
    // add assessment feedback
    this.modalRef.content.onClose.subscribe(result => {
      if(result){
        var data = { assessment_id: val.assessment_id,
          customer_user_id: val.customer_user_id,
          customer_assessment_id: val.id,
          feedbackData: JSON.stringify(result)
        };
        this.apiService.postwithouttoken('addfeedbacktoassessment', data)
          .subscribe(
            result => {

              if (result['success']) {
                this.apiService.toastrMsg(result['message'], 'success');
                this.customerAssessmentInfo.is_feedback=1;
                if(this.iscPdf){
                  this.downloadCResultAsPDF();

                }else{
                  this.downloadResultAsPDF();

                }

              } else {
                this.apiService.toastrMsg(result['message'], 'error');
              }
            },
            fail => {
              if (fail.status == 401) {
                this.router.navigateByUrl('/');
              } else {
                this.apiService.toastErrorMsg(fail);
              }
            }
          );
      }
    });
    this.modalRef.setClass('Recruitonic-feedback');

  }
	getAssessmentsForTest(url) {
    this.spinner.show();
		var data = {
			is_final: true,
			unique_url: url
		}
		this.apiService.postwithouttoken('getanswerlist', data)
			.subscribe(
				result => {
          this.spinner.hide();
					if (result['success']) {
						if (result['assessmentInfo'] == undefined || result['questionarray'] == undefined) {
							this.apiService.toastrMsg("Invalid", 'error');
							this.router.navigate(['/']);
						} else {
							this.assessmentInfo = result['assessmentInfo'];
							this.questionarray = result['questionarray'];
							this.answerArray = result['answerInfo'];
							this.types = result['types'];
							this.total_questions = this.questionarray.length;
							this.CustomerAssessmentId = result['CustomerAssessmentId'];
                            this.getLanguageDetails();
							this.getResult(this.assessmentInfo.id, this.CustomerAssessmentId, this.answerArray[0].no_of_attempted);
							this.downloadResult();
						}
					}
				},
				fail => {
          this.spinner.hide();
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
    getLanguageDetails() {
        var data = {
            language_id: this.assessmentInfo.language_id,

        }
        this.apiService.postwithouttoken('getlanguagedetails', data)
            .subscribe(
                result => {
                    let data = result['record'];
                    this.languageDetails={};
                    for(let val of data) {
                        this.languageDetails[val.meta_key]  = val.meta_value;
                    }
                },
                fail => {
                    this.apiService.toastErrorMsg(fail);
                }
            );
    }
	getResult(assessment_id, customer_assessment_id, no_of_attempted) {
		var data = {
			assessment_id: assessment_id,
			customer_assessment_id: customer_assessment_id,
			no_of_attempted: no_of_attempted
		}

		this.apiService.postwithouttoken('getfinalresult', data)
			.subscribe(
				result => {
					if (result['success']) {

						this.customerAssessmentInfo = result['customerAssessments'];
						this.result = result['data'];
                         this.result = this.result.sort((a, b) => a.Difficulty_level_master.sort_id - b.Difficulty_level_master.sort_id);

						this.result.forEach(element => {
							this.totalmarks = Number(this.totalmarks) + Number(element.mark_obtained);
						});
						this.question_type_meta = JSON.parse(this.assessmentInfo.question_type_meta);

					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	downloadResult() {
		var data = {
			assessment_id: this.assessmentInfo.id,
			customer_assessment_id: this.CustomerAssessmentId,
			no_of_attempted: this.answerArray[0].no_of_attempted,
			// obtain_marks : this.final_result
		}

		this.apiService.postwithouttoken('downloadresul', data)
			.subscribe(
				result => {

          if (result['success']) {

            let graphcategories=[];
            let questions=[];
            let answers=[];
            if(result['is_cognitive']==1) {
            this.fresult = result['data'];
            this.level_result = result['level_task_details'];
            this.customerInfo = result['customerInfo'];
            this.inviteeDetails = result['inviteeDetails'];

            this.best_five = result['best_five'];
            // this.best_five = this.best_five.sort((a, b) => a.tp_marks - b.tp_marks);

            this.least_five = result['least_five'];
            this.overall_grade = result['overall_grade'];
            this.assessment_started_date = result['assessment_started_date'];
            let resultInfo = result['data']['resultInfo'];
            this.totalmarks = this.fresult.sum_of_total.sum_of_marks_obtain;

            this.overall_percentage = Math.round((this.totalmarks/this.fresult.sum_of_total.sum_of_total_marks)*100);
            resultInfo = resultInfo.sort((a, b) => a.Difficulty_level_master.sort_id - b.Difficulty_level_master.sort_id);


            resultInfo.forEach(element => {
              let title= element.Assessment.Languages_master.lang_title.includes('हिंदी')?element.Difficulty_level_master.language_1:element.Assessment.Languages_master.lang_title.includes('मराठी')?element.Difficulty_level_master.language_2:element.Assessment.Languages_master.lang_title.includes('ગુજરાતિ')?element.Difficulty_level_master.language_3:element.Difficulty_level_master.difficulty_level;
              graphcategories.push(title + " "+this.languageDetails.question);
              questions.push(element.no_of_question);
              answers.push(parseInt(element.answer_status));
            });
            this.graphDetails = {categories:graphcategories,questions:questions,answers:answers};

            }
            else {
              let graphcategories = [];
              let questions = [];
              let answers = [];
              this.cognitiveReportDetails = result['topicDetails'];
              this.normData = result['normData'];

              this.customerInfo = result['customerInfo'];
              this.inviteeDetails = result['inviteeDetails'];

              this.overall_grade = result['overall_grade'];
              this.assessment_started_date = result['assessment_started_date'];
              this.totalmarks = this.fresult.sum_of_total.sum_of_marks_obtain;

              this.overall_percentage = Math.round((this.totalmarks / this.fresult.sum_of_total.sum_of_total_marks) * 100);

              this.iscPdf = true;
            }

          }
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}


	downloadAsPDF() {
		const doc = new jsPDF()
		console.log("============", this.resultInfo);
		let bodyData = [];
		for (let i = 0; i < this.resultInfo.length; i++) {
			let rowData = {
				Difficulty_level : this.resultInfo[i].Difficulty_level_master.difficulty_level,
				marks_per_ques : this.resultInfo[i].each_ques_mark,
				total_ques : this.resultInfo[i].no_of_question,
				passing_mark : this.resultInfo[i].passing_mark,
				mark_obtained : this.resultInfo[i].mark_obtained
			};
			bodyData.push(rowData);
		}

		doc.autoTable({
			head: [['Difficulty Level', 'Mark / Question','Total Que', 'Passing Mark','Total Correct']],
			body: [
				['Easy', 3, 4, 3],
				['Medium', 3, 3, 3],
				['Hard', 4, 4, 4],
				['Final Result', '', '', 44]
				// ...
			],
		})
		doc.save('Result.pdf');
	}

	closeWindow() {
		window.close();
		// this.router.navigate(['/my-assessment']);
	}


}
