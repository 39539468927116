import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { ApiService } from '../../../app.service';
import { AppConstants } from '../../../constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blogpost-list',
  templateUrl: './blogpost-list.component.html',
  styleUrls: ['./blogpost-list.component.css']
})
export class BlogpostListComponent implements OnInit {

	blog_id: any;
	blogList: any= [];
  featuredBlog: any= [];
	subArray = [];
	private url = AppConstants.imageURL;

  pageno: any = 0;
  pageSize = 10;
	constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router) {
		this.blog_id = this.activatedRoute.snapshot.paramMap.get('id');
		this.getBlogList();

	}

	ngOnInit(): void {
		AOS.init({
			once: true
		});
	}
  goToDetails(blog_title,id){
	  localStorage.setItem('blog-id',id);
    this.router.navigate(['/blog/', blog_title.split(" ").join("-")]);

  }
  goToCategory(blog_title){
    this.router.navigate(['/blog/category/', blog_title.split(" ").join("-")]);

  }
  changePage(event) {
    this.pageno = event.pageIndex;
    this.pageSize = event.pageSize;

    this.getBlogList();
  }
	getBlogList() {
		var data = {
      pageno: this.pageno,
      pageSize: this.pageSize,
		}
		this.apiService.postwithouttoken('bloglist', data)
			.subscribe(
				result => {
					this.blogList = result['bloglist'];
					this.featuredBlog = result['featuredBlog'];

				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}


  }
