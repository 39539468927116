import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name:'momentPipe',
  pure:false
})
export class MomentPipe implements PipeTransform {
  constructor() {
  }
  transform(value: any, dateFormat:any): any {
    return moment(value).format(dateFormat);
  }

}
