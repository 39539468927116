import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { ApiService } from '../../app.service';
import { AppConstants } from '../../constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DefaultLayoutComponent } from '../../containers';
import {SeoService} from '../../seo.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
	selector: 'app-group-assessments-details',
	templateUrl: './group-assessments-details.component.html',
	styleUrls: ['./group-assessments-details.component.css']
})
export class GroupAssessmentsDetailsComponent implements OnInit {


	assessmentList: any;
	assessment_id: any;
	assessmentInfo: any;
	priceInfo: any;
	subjectList: any;
	assessmentListBySubject: any;
	// cartInfo: any;
	wishListInfo: any;
	private url = AppConstants.imageURL;

	recordId: any;
	modalRef: BsModalRef;
	signIn = true;
	signUp = false;
	formsubmitted = false;
	login_customer_id: any;

	loginForm: FormGroup;
	signupForm: FormGroup;
	assessment_completed: any;
	subArray = [];
	groupAssessmentList :any;

	categories: any = {
		items: [{
			id: 'slide-1',
			displayedName: 'Slide 1'
		},
		{
			id: 'slide-2',
			displayedName: 'Slide 2'
		},
		{
			id: 'slide-3',
			displayedName: 'Slide 3'
		},
		{
			id: 'slide-4',
			displayedName: 'Slide 4'
		},
		{
			id: 'slide-5',
			displayedName: 'Slide 5'
		},
			// {
			//   id: 'slide-6',
			//   displayedName: 'Slide 6'
			// },
			// {
			//   id: 'slide-7',
			//   displayedName: 'Slide 7'
			// },
			// {
			//   id: 'slide-8',
			//   displayedName: 'Slide 8'
			// }
		]

	};

  design:any=0;
	constructor(private spinner: NgxSpinnerService, public _seoService: SeoService, private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private modalService: BsModalService, private manureload : DefaultLayoutComponent) {
		let token = localStorage.getItem('access_token');
		// if (token != null) {
		//   this.router.navigate(['/']);
		// }
		this.login_customer_id = localStorage.getItem('customer_id');
		if (this.login_customer_id == null) {
			this.login_customer_id = 0;
		} else {

			this.login_customer_id = this.login_customer_id;
		}
		this.assessment_id = this.activatedRoute.snapshot.paramMap.get('id');
		this.getAssessmentByid(this.assessment_id);

		this.getSubjectList();

	}



	// End testimonial carasouel

	// couse carasouel slider


	title = 'owl-carousel-libdemo';
	owlNext = '→';
	owlPrev = '←';



	customOptions1: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}



	customOptions2: OwlOptions = {
		autoWidth: false,
		loop: false,
		// items: '10',
		margin: 25,
		// slideBy: 'page',
		stagePadding: 3,
		merge: false,
		autoplay: false,
		autoplayTimeout: 3000,
		// autoplayHoverPause: true,
		autoplaySpeed: 2000,
		dotsSpeed: 500,
		dots: false,
		// dotsData: true,
		// mouseDrag: false,
		// touchDrag: false,
		// pullDrag: false,
		smartSpeed: 400,

		// fluidSpeed: 499,
		dragEndSpeed: 350,
		// dotsEach: 4,
		center: false,

		// rewind: true,
		// rtl: true,
		startPosition: 0,
		navText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
		responsive: {
			0: {
				items: 1,

			},
			400: {
				items: 2,

			},
			740: {
				items: 3,

			},
			940: {
				items: 4,

			}

		},
		//stagePadding: 100,
		nav: true
	}
	activeSlides: any;


	ngOnInit() {

		AOS.init({
			once: true
		});

		this.loginForm = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
			password: new FormControl('', [Validators.required])
		});

		this.signupForm = new FormGroup({
			c_name: new FormControl('', [Validators.required]),
			c_email_id: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
			mobile_no: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
			c_password: new FormControl('', [Validators.required]),
			c_image: new FormControl(''),
			accept_terms: new FormControl(true, [Validators.required])
		});

		this.getAssessmentList()
	}

	getPassedData(data: any) {
		this.activeSlides = data;
	}

	getAssessmentList() {
		var data = {
			internal: 1
		}
		this.apiService.post('assessmentlistfromweb', data)
			.subscribe(
				result => {
					this.assessmentList = result['assessmentList'];
          this.assessmentList = this.assessmentList.filter(a=>a.Assessment.id != this.assessmentInfo.id);

          this.assessmentListBySubject = result['assessmentList'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getAssessmentByid(assess_title) {

    assess_title = assess_title.split("-").join(" ");

    var data = {
      // assessment_id: assessment_id,
      assess_title:assess_title
    }
    this.apiService.postwithouttoken('getassessmentbyidfromwebbytitle', data)
      .subscribe(
        result => {
          this.assessmentInfo = result['assessmentInfo'];
          this.priceInfo = result['PriceInfo'];
          this.assessment_completed = Number(result['assessment_completed'].no_of_attempted) + Number(this.assessmentInfo.display_sales);
          if(this.assessmentInfo.Subject_master.sub_title.toLowerCase().includes('cognitive')){
            this.design = 1;

          }else if(this.assessmentInfo.Subject_master.sub_title.toLowerCase().includes('graphics')){
            this.design = 2;
          }else{
            this.design = 3;
          }
          this.router.navigate(['/assessments-group/', assess_title.split(" ").join("-")]);
          this.updateMetaAssessment(assess_title.split(" ").join("-"),this.assessmentInfo);

          this.wishListInfo = this.priceInfo.wishInfo;
          this.getGroupAssessments(this.assessmentInfo.id);
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );

		// var data = {
		// 	assessment_id: assessment_id
		// }
		// this.apiService.postwithouttoken('getassessmentbyidfromweb', data)
		// 	.subscribe(
		// 		result => {
		// 			this.assessmentInfo = result['assessmentInfo'];
		// 			this.priceInfo = result['PriceInfo'];
		// 			this.assessment_completed = Number(result['assessment_completed'].no_of_attempted) + Number(this.assessmentInfo.display_sales);
    //       if(this.assessmentInfo.Subject_master.sub_title.toLowerCase().includes('cognitive')){
    //         this.design = 1;
    //
    //       }else if(this.assessmentInfo.Subject_master.sub_title.toLowerCase().includes('graphics')){
    //         this.design = 2;
    //       }else{
    //         this.design = 3;
    //       }
		// 			this.router.navigate(['/assessments-group/', assessment_id]);
		// 			this.wishListInfo = this.priceInfo.wishInfo;
		// 		},
		// 		fail => {
    //       this.apiService.toastErrorMsg(fail);
		// 		}
		// 	);
	}
  goToDetails(assessment_id,assess_title){
    this.spinner.show();
    var data = {
      assessment_id: assessment_id
    }
    this.apiService.postwithouttoken('getgroupassessments', data)
      .subscribe(
        result => {
          this.groupAssessmentList = result['groupAssessments'];
          if(this.groupAssessmentList.length > 0){
            this.router.navigate(['/assessments-group/', assess_title.split(" ").join("-")]).then(result => {
              window.location.reload();
              this.spinner.hide();
            });
          } else {
            this.spinner.hide();
            this.router.navigate(['/assessments-details/', assess_title.split(" ").join("-")]);
          }

        },
        fail => {
          this.spinner.hide();
          this.apiService.toastErrorMsg(fail);
        }
      );
    // this.router.navigate(['/assessments-details/', assess_title.split(" ").join("-")]);

  }
  updateMetaAssessment(assess_title,details){
    let title,ogUrl,description,updateKeys;
    title = details.meta_title ? details.meta_title : '';
    description = details.meta_desc ? details.meta_desc : '';
    updateKeys = details.meta_keywords ? details.meta_keywords : '';
    ogUrl = 'https://recruitonic.com/assessments-group/' + assess_title;

    // if(assess_title == 'Cognitive-Ability-Skills'){
    //   title ='Cognitive Ability Skills Assessment - Pre-Employement Test - Hiring Test - Apptitute Test';
    //   ogUrl ='https://recruitonic.com/assessments-group/Cognitive-Ability-Skills';
    //   description ='Cognitive Skills Assessments are frequently used as part of a hiring/pre-employment assessment or for learning and development purposes';
    // }else if(assess_title == 'Design-and-Animation-Skills'){
    //   title ='Design Apptitute Test - UIUX Design Assessment hire Designers with proven skillsets';
    //   ogUrl ='https://recruitonic.com/assessments-group/Design-and-Animation-Skills';
    //   description ='Recruitonic’s 3D Graphics and Visual Design assessment tools are deeply researched and designed to assess the design IQ and technical skills accurately to match the industry requirements for hiring and training purposes.';
    // }
    this._seoService.updateTitle(title);
    this._seoService.updateOgUrl(ogUrl);
    this._seoService.updateDescription(description);
    this._seoService.updateKeys(updateKeys);

  }
	getGroupAssessments(assessment_id) {
		var data = {
			assessment_id: assessment_id
		}
		this.apiService.postwithouttoken('getgroupassessments', data)
			.subscribe(
				result => {
					this.groupAssessmentList = result['groupAssessments'];
					console.log("======this.groupAssessmentList======",this.groupAssessmentList );
					// if(this.groupAssessmentList.length > 0){
					// 	this.router.navigate(['/assessments-group/', assessment_id]);
					// } else {
					// 	this.router.navigate(['/assessments-details/', assessment_id]);
					// }
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	getAssessmentListBySubject(id) {
		var data = {
			subject_id: id
		}
		this.apiService.postwithouttoken('assessmentsbysubjectid', data)
			.subscribe(
				result => {
					this.assessmentListBySubject = result['assessmentListBySubject'];
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	getSubjectList() {
		var data = {
			internal: 1
		}
		this.apiService.postwithouttoken('subjectlistfromweb', data)
			.subscribe(
				result => {
					this.subjectList = result['subjectslist'];
					let cont = 0;
					this.subjectList.forEach(element => {
						if (cont == 0) {
							let obj = { 'id': element.Assessment.Subject_master.id, 'sub_title': element.Assessment.Subject_master.sub_title }
							this.subArray.push(obj);
							cont = 1;
						} else {
							this.subArray.forEach(abc => {
								let found = this.subArray.some(ele => ele.id === element.Assessment.Subject_master.id);
								if(!found){
									let obj = { 'id': element.Assessment.Subject_master.id, 'sub_title': element.Assessment.Subject_master.sub_title }
									this.subArray.push(obj);
								}
							});
						}
					});

				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}


	AddToWishList(assessment_id) {
		let customer_id = localStorage.getItem('customer_id');
		if (customer_id == null) {
			this.apiService.toastrMsg('Please Login First', 'success');
			this.router.navigate(['/']);
		} else {
			var data = {
				assessment_id: assessment_id,
				customer_id: customer_id
			}
			this.apiService.postwithouttoken('addtowishlist', data)
				.subscribe(
					result => {
						this.apiService.toastrMsg(result['message'], 'success');
						this.getAssessmentByid(assessment_id);
					},
					fail => {
            this.apiService.toastErrorMsg(fail);
					}
				);
		}
	}
	RemoveToWushList(assessment_id, wish_id) {
		var data = {
			wish_id: wish_id
		}
		this.apiService.postwithouttoken('removetowishlist', data)
			.subscribe(
				result => {
					this.apiService.toastrMsg(result['message'], 'success');
					this.getAssessmentByid(assessment_id);
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

	// import Confirm Send modal open
	sendConfirmModal(template: TemplateRef<any>, rowindex, test) {
		this.recordId = rowindex;
		this.modalRef = this.modalService.show(template);
		if (test == 'signup') {
			this.signIn = false;
			this.signUp = true;
		}
		else {
			this.signIn = true;
			this.signUp = false;
		}
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	// Function to login in
	customerLogin() {
		this.formsubmitted = true;
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
		var data = this.loginForm.value;
		this.apiService.postwithouttoken('customerlogin', data)
			.subscribe(
				result => {
					if (result['success']) {
						this.modalRef.hide();
						localStorage.setItem('c_name', result['customer'].c_name);
						localStorage.setItem('access_token', result['token']);
						localStorage.setItem('c_email_id', result['customer'].c_email_id);
						localStorage.setItem('customer_id', result['customer'].id);
						localStorage.setItem('customer_dashboard', result['customer'].created_by);
						// this.router.navigate(['/plans', this.assessment_id]).then(() => {
						// 	window.location.reload();
						// });
						this.manureload.reloadManu(result['customer'].c_name);
						this.router.navigate(['/plans', this.assessment_id ]);
					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}


	// functions for signup
	get g() {
		return this.signupForm.controls;
	}

	signUpCustomer() {
		this.formsubmitted = true;
		if (this.signupForm.invalid) {
			return;
		}
		console.log("===========", this.signupForm.value.accept_terms);
		if (!this.signupForm.value.accept_terms) {
			this.apiService.toastrMsg('Please Accept the Recruitonic Terms of Service', 'error');
			return;
		}
		var data = this.signupForm.value;
		this.apiService.postwithouttoken('savecustomermasterfromweb', data)
			.subscribe(
				result => {
					if (result['success']) {
						this.modalRef.hide();
						this.loginForm.patchValue({
							email: this.signupForm.value.c_email_id,
							password: this.signupForm.value.c_password
						})
						this.customerLogin();
						// this.router.navigate(['/my-assessment']).then(() => {
						// 	window.location.reload();
						// });
					} else {
						this.apiService.toastrMsg(result['error'], 'error');
					}
				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}
	showSignUp() {
		this.signIn = false;
		this.signUp = true;
	}

	showSignIn() {
		this.signIn = true;
		this.signUp = false;
	}

	ResetModel() {
		this.modalRef.hide();
		this.signIn = true;
		this.signUp = false;
	}

	redirectPage(assessment_id) {
		var data = {
			assessment_id: assessment_id
		}
		this.apiService.postwithouttoken('getgroupassessments', data)
			.subscribe(
				result => {
					this.groupAssessmentList = result['groupAssessments'];
					if(this.groupAssessmentList.length > 0){
						this.router.navigate(['/assessments-group/', assessment_id]);
					} else {
						this.router.navigate(['/assessments-details/', assessment_id]);
					}

				},
				fail => {
          this.apiService.toastErrorMsg(fail);
				}
			);
	}

}

	// addToCart(assessment_id) {
	// 	let customer_id = localStorage.getItem('customer_id');
	// 	var data = {
	// 		assessment_id: assessment_id,
	// 		customer_id: customer_id
	// 	}
	// 	this.apiService.post('addtocart', data)
	// 		.subscribe(
	// 			result => {
	// 				this.getAssessmentByid(assessment_id);
	// 				this.apiService.toastrMsg(result['message'], 'success');
	// 			},
	// 			fail => {
	// 				this.apiService.toastrMsg(fail['error']['error'], 'error');
	// 			}
	// 		);
	// }

	// RemoveToCart(assessment_id, cart_id) {
	// 	var data = {
	// 		cart_id: cart_id
	// 	}
	// 	this.apiService.post('removefromcart', data)
	// 		.subscribe(
	// 			result => {
	// 				this.apiService.toastrMsg(result['message'], 'success');
	// 				this.getAssessmentByid(assessment_id);
	// 			},
	// 			fail => {
	// 				this.apiService.toastrMsg(fail['error']['error'], 'error');
	// 			}
	// 		);
	// }
