import { Component, OnInit, ViewChild, TemplateRef, Injectable } from '@angular/core';
import { navItems } from '../../_nav';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../app.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppConstants } from '../../constants';
import {DeviceUUID} from 'device-uuid';
import swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  private url = AppConstants.liveURL;
  public sidebarMinimized = false;
  public navItems = navItems;
  user_name: any;
  nameArray: any;

  recordId: any;
  modalRef: BsModalRef;

  formsubmitted = false;
  loginForm: FormGroup;
  signupForm: FormGroup;
  signIn = true;
  signUp = false;
  first_name: any;
  last_name: any;
  customer_dashboard: any;
  activeurl: any;
  deviceInfo: any;
  otp: any;
  is_verified: any=true;
  verification_code : any;
  loginInfo : any;
  customerOTP = false;
  custom_nav=[];
  @ViewChild('confirmtemplate') confirmtemplate: TemplateRef<any>;
  constructor(private router: Router, private apiService: ApiService, private modalService: BsModalService, private activatedRoute: ActivatedRoute) {

    let token = localStorage.getItem('access_token');
    /*if (token != null) {
      this.router.navigate(['/home']);
    }*/
    this.activeurl = this.router.url;
    this.user_name = localStorage.getItem('c_name');
    this.customer_dashboard = localStorage.getItem('customer_dashboard');
    if (this.user_name != null) {
      this.user_name = localStorage.getItem('c_name');
      this.nameArray = this.user_name.split(' ');
      // this.nameArray = this.user_name;
      this.first_name = this.nameArray[0] == undefined ? '' : this.nameArray[0].charAt(0);
      this.last_name = this.nameArray[1] == undefined ? '' : this.nameArray[1].charAt(0);
    }
     this.changeNaveMenus();
  }
  changeNaveMenus(){
    for (let i in this.navItems) {

      if(!this.user_name) {
        if (this.navItems[i].name != 'My Assesssments') {
          this.custom_nav.push({
            name: this.navItems[i].name,
            url: this.navItems[i].url,
            icon: this.navItems[i].icon
          });

        }
      }else{
        if (this.navItems[i].name != 'Sign in' && this.navItems[i].name != 'Sign up') {
          this.custom_nav.push({
            name: this.navItems[i].name,
            url: this.navItems[i].url,
            icon: this.navItems[i].icon
          });

        }
      }

    }

    this.navItems = this.custom_nav;
  }
  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      password: new FormControl('', [Validators.required])
    });


    this.signupForm = new FormGroup({
      c_name: new FormControl('', [Validators.required]),
      c_business_name: new FormControl('', [Validators.required]),
      c_email_id: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      mobile_no: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      c_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)]),
      accept_terms: new FormControl(true, [Validators.required]),
      // verification_code: new FormControl('', [Validators.required]),
      c_image: new FormControl(''),
    });
  }
  verifyCode (email){
    this.apiService.postwithouttoken('sendotptoemailforcustomersignup', {email:email})
      .subscribe(
        result => {
          if (result['success']==true) {
           this.otp  =  result['Verified'];
            this.apiService.toastrMsg(result['message'],'success');

          }else{
            this.apiService.toastrMsg(result['error'],'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  verifyOTPCode (code){
    this.formsubmitted  = true;
   if(code!=this.otp){
     this.apiService.toastrMsg('Invalid OTP', 'error');

   }else{

     this.loginForm.patchValue({
       email: this.loginInfo.c_email_id,
       password: this.loginInfo.c_password
     });
     console.log(this.loginInfo,'aaa');
     this.modalRef.hide();
     this.apiService.postwithouttoken('changeverifiedstatusforcustomer', {verfied_user_id:this.loginInfo.customer_id})
       .subscribe(
         result => {
           if (result['success']==true) {
             this.apiService.toastrMsg('OTP verified successfully', 'success');
             this.customerLogin();
             this.customerOTP = false;
           }
         },
         fail => {
           this.apiService.toastErrorMsg(fail);
         }
       );


   }
  }
  reloadManu(user_name) {
    this.user_name = user_name;
    this.nameArray = this.user_name.split(' ');
    // this.nameArray = this.user_name;
    this.first_name = this.nameArray[0] == undefined ? '' : this.nameArray[0].charAt(0);
    this.last_name = this.nameArray[1] == undefined ? '' : this.nameArray[1].charAt(0);

    this.changeNaveMenus();
  }

  ActiveCall() {
    this.activeurl = this.router.url;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  // Logout user
  logOut() {
    this.user_name = '';
    this.apiService.postwithouttoken('logoutuser', {})
        .subscribe(
            result => {
              if (result['success']) {
                localStorage.clear();
                this.router.navigate(['/']);

              }
            },
            fail => {
              this.apiService.toastErrorMsg(fail);
            }
        );
    this.changeNaveMenus();
  }
  getMenuClick(e){
    console.log('b',e,e.value);
  }
  // import Confirm Send modal open
  sendConfirmModal(template: TemplateRef<any>, rowindex, test) {
    this.recordId = rowindex;

    this.formsubmitted = false;
    this.modalRef = this.modalService.show(template);
    if (test == 'signup') {
      this.signIn = false;
      this.signUp = true;
    }
    else {
      this.signIn = true;
      this.signUp = false;
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  // Function to login in
  customerLogin() {
    this.formsubmitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.deviceInfo = new DeviceUUID().get();

    var data = this.loginForm.value;
    data.device_id = this.deviceInfo;
    console.log(this.loginForm.value.password,'Login');
    data.password = window.btoa(this.loginForm.value.password);

    this.apiService.postwithouttoken('customerlogin', data)
      .subscribe(
        result => {
          if (result['success']) {
            // this.formsubmitted = false;
            localStorage.setItem('device_id', this.deviceInfo);

            if(result['message']!= 'already_signed') {
              if (result['customer'].customer_id == null) {

                localStorage.setItem('c_name', result['customer'].c_name);
                localStorage.setItem('access_token', result['token']);
                localStorage.setItem('c_email_id', result['customer'].c_email_id);
                localStorage.setItem('customer_id', result['customer'].id);
                localStorage.setItem('customer_dashboard', result['customer'].created_by);
                this.modalRef.hide();

                this.user_name = result['customer'].c_name;
                this.nameArray = this.user_name.split(' ');
                // this.nameArray = this.user_name;
                this.first_name = this.nameArray[0] == undefined ? '' : this.nameArray[0].charAt(0);
                this.last_name = this.nameArray[1] == undefined ? '' : this.nameArray[1].charAt(0);

                this.reloadManu(result['customer'].c_name);
                this.router.navigate(['/my-assessment']);

              } else {
                if (result['customerMaster'].is_verified == 1) {
                  localStorage.setItem('c_name', result['customer'].c_name);
                  localStorage.setItem('access_token', result['token']);
                  localStorage.setItem('login_token', result['token']);
                  localStorage.setItem('c_email_id', result['customer'].c_email_id);
                  localStorage.setItem('mobile_no', result['customer'].mobile_no);
                  localStorage.setItem('c_business_name', result['customer'].c_business_name);
                  localStorage.setItem('customer_id', result['customer'].id);
                  let is_customer_dashboard = result['customer'].customer_id == null?'0':'1';
                  localStorage.setItem('customer_dashboard',is_customer_dashboard);

                  window.location.href = this.url;
                  return true;
                } else {

                  this.loginInfo = this.loginForm.value;
                  this.loginInfo.c_email_id = this.loginForm.value.email;
                  this.loginInfo.c_password = this.loginForm.value.password;
                  this.loginInfo.customer_id = result['customer'].customer_id;
                  this.customerOTP = true;
                  this.signIn = false;
                  // this.apiService.toastrMsg('Email verification pending', 'error');

                  this.verifyCode(result['customer'].c_email_id);
                }
              }
            }else{
                localStorage.setItem('customer_id', result['customer'].id);
                swal.fire({
                  title: 'Are you sure?',
                  text: 'You are already signed in another device,are you sure to sign out from other device and sign in here ?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, sign out!',
                  cancelButtonText: 'No, keep it',
                  confirmButtonColor: '#FE9900',
                  cancelButtonColor: '#ccc',
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.apiService.postwithouttoken('logoutuser', {})
                      .subscribe(
                        result => {
                          if (result['success']) {
                            localStorage.clear();
                            this.apiService.toastrMsg('Sign out from other device successfully, Try Sign In', 'success');

                          }
                        },
                        fail => {
                          this.apiService.toastErrorMsg(fail);
                        }
                      );
                  }else{
                    this.modalRef.hide();
                  }
                });

            }
          } else {

              this.apiService.toastrMsg(result['error'], 'error');

          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }


  // functions for signup
  get g() {
    return this.signupForm.controls;
  }

  signUpCustomer() {
    this.formsubmitted = true;
       console.log(this.signupForm);
    if (this.signupForm.invalid) {
      return;
    }
    if (!this.signupForm.value.accept_terms) {
      this.apiService.toastrMsg('Please Accept the Recruitonic Terms of Service', 'error');
      return;
    }
    const password = this.signupForm.value.c_password;
    let data = this.signupForm.value;
    data.is_verified = 1;
    data.c_password = window.btoa(password);
    this.apiService.postwithouttoken('savecustomermasterfromweb', data)
      .subscribe(
        result => {
          if (result['success']) {
            // this.formsubmitted = false;
            // this.signupForm.reset(); //clear form
            // this.modalRef.hide();
            this.signUp = false;
            this.customerOTP = true;
            this.loginInfo = this.signupForm.value;
            this.loginInfo.c_password = window.atob(this.loginInfo.c_password);
            this.loginInfo.customer_id = result['customer'].customer_id;
            this.verifyCode(data.c_email_id);

          } else {
            this.apiService.toastrMsg(result['error'], 'error');
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  showSignUp() {
    this.formsubmitted = false;
    this.signIn = false;
    this.signUp = true;
  }

  showSignIn() {
    this.formsubmitted = false;
    this.signIn = true;
    this.signUp = false;
  }

  ResetModel() {
    this.modalRef.hide();
    this.loginForm.reset();
    this.signupForm.reset();
    this.signUp = false;
    this.signIn = false;
    this.formsubmitted = false;
  }

  ForgotPassword() {
    this.modalRef.hide();
    this.loginForm.reset();
    this.signupForm.reset();
    this.signIn = true;
    this.signUp = false;
    this.router.navigate(['/forgot-password']);
  }
  openSignUpAndSignIn(test) {
    this.recordId = '';

    this.formsubmitted = false;
    if (test == 'signup') {
      this.signIn = false;
      this.signUp = true;
    }
    else  {
      this.signIn = true;
      this.signUp = false;
    }
    this.modalRef = this.modalService.show(this.confirmtemplate);

  }
}
