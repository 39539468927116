import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from './constants';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import domtoimage from 'dom-to-image';
import {DeviceUUID} from 'device-uuid/lib/device-uuid.js';
import {error} from '@angular/compiler/src/util';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class ApiService {
  ip:any;
	constructor(private http: HttpClient, public platfrm: PlatformLocation, private router: Router, private toastr: ToastrService) {



  }
  getIP() {
    return this.http.get('http://ipinfo.io');//...errors if any
  }
	private url = AppConstants.apiURL;

	private serializeObj(obj: any) {
		var result = [];
		for (var property in obj) {
			if (obj[property] instanceof Array) {
				var arr = JSON.stringify(obj[property]);
				result.push(encodeURIComponent(property) + "=" + encodeURIComponent(arr));
			} else {
				var arr = '';
				if (obj[property] instanceof Object) {
					arr = JSON.stringify(obj[property]);
				} else {
					arr = obj[property];
				}
				result.push(encodeURIComponent(property) + "=" + encodeURIComponent(arr));
			}
		}
		return result.join("&");
	}


	post(path, body) {
    if (window.navigator.onLine) {
      const headers = new HttpHeaders().set('Authorization', localStorage.getItem('access_token')).set('Accept', 'application/json').set('Content-Type', 'application/x-www-form-urlencoded');
      body.customer_id = localStorage.getItem('customer_id');
      return this.http.post(this.url + 'mainroutes/' + path, this.serializeObj(body));
    }else{

      return throwError({network_message:'Check your Internet Connection'});
    }
	}

	postwithouttoken(path, body) {
    if (window.navigator.onLine) {
      this.storeDeviceDetails();

      const headers = new HttpHeaders().set('Authorization', localStorage.getItem('access_token')).set('Accept', 'application/json').set('Content-Type', 'application/x-www-form-urlencoded');
      body.customer_id = localStorage.getItem('customer_id');
      return this.http.post(this.url + 'mainroutes/' + path, body);
    }else{

      return throwError({network_message:'Check your Internet Connection'});
    }
	}

	postwithtoken(path, body) {
    if (window.navigator.onLine) {
      const headers = new HttpHeaders().set('Authorization', localStorage.getItem('access_token')).set('Accept', 'application/json').set('Content-Type', 'application/x-www-form-urlencoded');
      body.customer_id = localStorage.getItem('customer_id');
      return this.http.post(this.url + 'mainroutes/' + path, this.serializeObj(body), {headers: headers});
    }else{
      return throwError({network_message:'Check your Internet Connection'});

    }
	}


	// post_image(path, body) {
	// 	return this.http.post(this.url + 'adminroutes/' + path , body )
	// }

	checkTokenExpiry(): any {
		let access_token = localStorage.getItem('access_token');
		if (access_token == null) {
			return 1;
		} else {
			let date = new Date();
			let timestamp = date.getTime();
			if (timestamp > timestamp +1) {
				return 1;
			} else {
				return 0;
			}
		}
	}

	toastrMsg(message, type) {
		if (type == "success") {
			this.toastr.success(message, '');
		}
		else if (type == "error")
			this.toastr.error(message, '');
		else if (type == "warning")
			this.toastr.warning(message, '');
		else
			this.toastr.info(message, '');
	}

  toastErrorMsg(error) {
	     this.toastr.clear();
      if (error['error']) {
        if (error['error']['error']) {
          this.toastr.error(error['error']['error'], '');
        }else{
          this.toastr.error('Something went wrong', '');

        }

      } else if (error['network_message']) {
        this.toastr.error(error['network_message'], '');
      } else {
        this.toastr.error('Something went wrong', '');

      }


  }

  async  convertToPng(page) {
    return await domtoimage.toPng(page);
  }

   storeDeviceDetails(){
     this.getIPAndGeolocation().subscribe(value => {
       this.ip = value['ip'];
       localStorage.setItem('ip',this.ip);

     });

   }
   getDeviceDetails() {

    var du = new DeviceUUID().parse();
    var dua = [
      du.language,
      du.platform,
      du.os,
      du.cpuCores,
      du.isAuthoritative,
      du.silkAccelerated,
      du.isKindleFire,
      du.isDesktop,
      du.isMobile,
      du.isTablet,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.isiPad,
      du.isiPhone,
      du.isiPod,
      du.isSmartTV,
      du.pixelDepth,
      du.isTouchScreen
    ];
    var uuid = du.hashMD5(dua.join(':'));
     du.geoIp = localStorage.getItem('ip') + '/' + (localStorage.getItem('location') ? localStorage.getItem('location') : '' );

    return  du ? JSON.stringify(du) : '';
  }

  getIPAndGeolocation() {
  	return  this.http.get('https://api.ipify.org/?format=json');
  }
  getGeolocation(data) {
	  let uri = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + data.latitude + ',' + data.longitude + '&key=AIzaSyAcORd4LFjFCmiLlw89hi8pbTr86fTtGEs';
    return  this.http.get(uri);
  }

}
