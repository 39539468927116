import { Component, OnInit, TemplateRef, ElementRef, HostListener, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConstants } from '../../constants';
import { ApiService } from '../../app.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { readSync } from 'fs';
import { threadId } from 'worker_threads';
import { element } from 'protractor';
import { CountdownComponent } from 'ngx-countdown';
import {DeviceUUID} from 'device-uuid';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)',
    '(window:blur)': 'onMovingWindow($event)'
  }
})
export class QuestionsComponent implements OnInit {

  // // Keyboard event restrictions
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keycodearr = [8, 18, 44, 116, 123];
    if (event.keyCode == 44) {
      this.stopPrntScr();
    }
    if (keycodearr.includes(event.keyCode)) { // Specific keys restriction
      event.returnValue = false;
      event.preventDefault();
    } else if ((event.ctrlKey || event.metaKey) && (event.code == '67' || event.code == '86' || event.code == '82' || event.code == '80' || event.code == '79' || event.code == '83')) { // Ctrl+C, Ctrl+V, Ctrl+R, Ctrl+P, Ctrl+O, Ctrl+S
      event.returnValue = false;
      event.preventDefault();
    } else if (event.ctrlKey && event.shiftKey && event.code == '74') { // Ctrl+Shift+J
      event.returnValue = false;
      event.preventDefault();
    }
  }

  // // Right click event disable
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
     event.preventDefault();
  }



  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
  //
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
  //
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }


  private url = AppConstants.imageURL;
  isMeridian = false;
  showSpinners = true;
  myTime: Date = new Date();
  currentDate = new Date();
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  modalRef: BsModalRef;
  recordId: any;
  assessmentInfo: any;
  questionarray: any;
  assessment_id: any;
  total_questions: any;
  ques_index = 0;
  unique_url: any;
  next_button = true;
  back_button = false;
  submit_button = false;
  option: any;
  scale = [];
  answerForm: FormGroup;
  CustomerAssessmentId: any;
  multi_answer = [];
  countdown: any;
  leftTime: any;
  right = [];
  left = [];
  sequenceType = [];
  answer_status: any;
  // assessment_marks = 0;
  matchingAnswerArray: any;
  sequencyAnswerArray: any;
  // marks = 0;
  parse_answer: any;
  single_answer: any;
  liker_scale_answer: any;
  sequence_answer: any;
  matching_answer: any;
  no_of_attempted: any;
  first_index = false;
  getTime: any;
  seconds: any;
  time_taken: any;
  all_time = 0;
  last_time_taken: 0;
  easy_per_ques_mark = 0;
  medium_per_ques_mark = 0;
  hard_per_ques_mark = 0;
  congnitive_per_ques_mark = 1;
  test_status = 'Time Out';

  @ViewChild('getTime', { static: false }) private countdown1: CountdownComponent;
  device_id:any;
  customer_id:any;
  is_mobile:any;
  question_score: any = 0 ;
  languageDetails:any;
  constructor(private spinner: NgxSpinnerService,private modalService: BsModalService, private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];
    this.unique_url = this.activatedRoute.snapshot.paramMap.get('id');
    this.device_id = this.activatedRoute.snapshot.paramMap.get('id2');
    this.customer_id = this.activatedRoute.snapshot.paramMap.get('id3');
    this.is_mobile = this.activatedRoute.snapshot.paramMap.get('id4');

    localStorage.setItem('customer_id',this.customer_id);
    console.log(this.unique_url,this.device_id,this.customer_id,this.is_mobile);

    this.getAssessmentsForTest(this.unique_url);
  }
  stopPrntScr() {

    let inpFld = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove();
  }

  onCheat(){
    this.test_status  = 'Security Break';
    if(this.is_mobile == '2') {
      this.getAssessmentsTestDetails(this.no_of_attempted);
    }
  }
  onResize(event){

    window.resizeTo(window.screen.availWidth, window.screen.availHeight);
    event.preventDefault();
     this.onCheat();
  }
  onMovingWindow(event) {

    event.preventDefault();
    this.onCheat();
  }
  dropSequence(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sequenceType, event.previousIndex, event.currentIndex);
    console.log("========-------", this.sequenceType);
    console.log("========-option------", this.option);
    this.scoreCalculate(this.option[0],1);
    this.sequencyAnswerArray = this.sequenceType;
    this.sequence_answer = this.sequenceType;

  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.matchingAnswerArray = event.container.data;
    this.matching_answer = event.container.data;
    this.scoreCalculate(this.option[0],1);
  }

  ngOnInit() {
    this.answerForm = new FormGroup({
      answer: new FormControl('')
    })
  }
  checkTime(){
    var time = "00:"+this.assessmentInfo.time+":00"; // If countdown1 is not available, then set the default minutes based on the assesssment time
    if(this.countdown1 !== undefined) {
      time = this.countdown1.i.text;
    }

    var array = time.split(":");
    this.seconds = (parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60) + parseInt(array[2], 10)

    if (this.seconds == 0) {
      // submit test
      this.apiService.toastrMsg("Your allocated time is over", 'error');
      this.getAssessmentsTestDetails(this.no_of_attempted);
    }

  }
  getAssessmentsForTest(url) {
    this.spinner.show();
    var data = {
      unique_url: url
    }
    this.apiService.postwithouttoken('getassessmentfortest', data)
      .subscribe(
        result => {
          this.spinner.hide();
          if (result['success']) {
            if (result['assessmentInfo'] == undefined || result['questionarray'] == undefined) {
              this.apiService.toastrMsg("Invalid", 'error');
              this.router.navigate(['/']);
            } else {
              this.assessmentInfo = result['assessmentInfo'];
              this.questionarray = result['questionarray'];
              this.total_questions = this.questionarray.length;
              this.CustomerAssessmentId = result['customer_assessment_id'];
              this.no_of_attempted = result['no_of_attempted'] + 1;
              this.option = JSON.parse(this.questionarray[0].options);
              this.countdown = (result['time'] * 60) - result['total_time_taken']; // time needs to be updated here

              ////let question_type_meta = JSON.parse(this.assessmentInfo.question_type_meta);
              //let obtained_mark;
              //console.log("======answer_status=====", this.answer_status,);
              // console.log("===questionarray===", this.questionarray[current_index]);

              /*this.easy_per_ques_mark = this.assessmentInfo.easy_ques_marks / this.assessmentInfo.total_easy_ques;
              this.medium_per_ques_mark = this.assessmentInfo.hard_ques_marks / this.assessmentInfo.total_hard_ques;
              this.hard_per_ques_mark = this.assessmentInfo.medium_ques_marks / this.assessmentInfo.total_medium_ques;*/

              this.easy_per_ques_mark = this.assessmentInfo.easy_ques_marks;
              this.medium_per_ques_mark = this.assessmentInfo.hard_ques_marks;
              this.hard_per_ques_mark = this.assessmentInfo.medium_ques_marks;
              this.congnitive_per_ques_mark = this.assessmentInfo.congnitive_per_ques_mark;

              console.log("=============", this.countdown);
              let c_index = result['c_index'];
              let next_index = c_index;
              if(c_index ==0)
                next_index = 0;
              console.log(c_index, next_index)
              /*Changes to be brought here for resume from specific question */
              this.saveAnswer(c_index, next_index, 0); // Set default value of third param: is_skipped to 0
              if(c_index > 0) {
                this.back_button = true;
              }
              else {
                this.back_button = false;
                this.first_index = false;
              }
              this.getLanguageDetails();

            }
          }
        },
        fail => {
          this.spinner.hide();
          this.apiService.toastErrorMsg(fail);
        }
      );
  }
  getLanguageDetails() {
    var data = {
      language_id: this.assessmentInfo.language_id,

    }
    this.apiService.postwithouttoken('getlanguagedetails', data)
      .subscribe(
        result => {
          let data = result['record'];
          this.languageDetails={};
          for(let val of data) {
            this.languageDetails[val.meta_key]  = val.meta_value;
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  terminate() {
    var data = {
      unique_url: this.unique_url
    }
    this.apiService.postwithouttoken('terminate', data)
      .subscribe(
        result => {
          if (result['success']) {
            window.close();
            // this.router.navigate(['/']);
          }
        }
      );
  }
  Next(current_index, pre_next_index, qstatus){
     var data = {device_id:''};
     data.device_id = this.device_id;

     this.apiService.postwithouttoken('checkotherdeviceusertest', data)
       .subscribe(
         result => {
           if (result['success']) {
             if (result['is_login'] == 1) {
               this.onCheat();
             } else {
               this.NextWithLoginCheck(current_index, pre_next_index, qstatus);
             }
           } else {
             this.NextWithLoginCheck(current_index, pre_next_index, qstatus);

           }
         },
         fail => {
           this.NextWithLoginCheck(current_index, pre_next_index, qstatus);

         });
   }
  NextWithLoginCheck(current_index, pre_next_index, qstatus) {

    const element = document.querySelector('#questionSection');
    element.scrollIntoView();

    if ((pre_next_index + 1) == this.total_questions) {
      this.next_button = false;
      this.back_button = true;
      // var url = 'assessment-completed/' + this.unique_url;
      // this.router.navigate([url]);
      this.answerChecking(current_index, pre_next_index, qstatus);
    } else if (pre_next_index == 0) {
      this.back_button = false;
      this.next_button = true;
      this.answerChecking(current_index, pre_next_index, qstatus);
    } else {
      this.back_button = true;
      this.next_button = true;
      this.answerChecking(current_index, pre_next_index, qstatus);
    }
  }

  answerChecking(current_index, pre_next_index, qstatus) {
    console.log("==================current_index======", current_index);
    this.first_index = current_index == 0 ? true : false;
    if (this.questionarray[current_index].q_type == 1) {
      this.saveAnswer(current_index, pre_next_index, qstatus);
    }
    else if (this.questionarray[current_index].q_type == 2) {
      console.log(this.questionarray[current_index]);
      // answer checking here
      let answers_array = [];
      this.option.forEach(element => {
        if (element.answer == true) {
          answers_array.push(element.option);
        }
      });
      if (this.multi_answer.length == 0) {
        this.option.forEach(element => {
          if (this.parse_answer != undefined) {
            if (this.parse_answer.includes(element.option)) {
              this.SelectMultipleAns(element.option, element.answer);
            }
          }
        });
      }
      this.multi_answer.forEach((e1, i) => {
        console.log("================ssssssssssssss==",);
        if (e1 == answers_array[i]) {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.multi_answer)
          })
          this.answer_status = 1;
        } else {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.multi_answer)
          })
          this.answer_status = 0;
        }
      })
      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else if (this.questionarray[current_index].q_type == 3) {

      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else if (this.questionarray[current_index].q_type == 4) {
      // answer checking here

      let ans = this.answerForm.value.answer;
      let as = this.option[0].answer;
      console.log("-------------radio-------",ans ,'==', this.option[0][as].toLowerCase() );
      if (ans == '') {
        this.answerForm.patchValue({
          answer: ''
        })
        this.answer_status = 0;
      } else if (ans == this.option[0][as].toLowerCase()) {
        /*this.answerForm.patchValue({
          answer: true
        })*/
        this.answer_status = 1;
        // this.assessment_marks = this.assessment_marks + this.questionarray[current_index].marks
      } else {
        /*this.answerForm.patchValue({
          answer: false
        })*/
        this.answer_status = 0;
      }
      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else if (this.questionarray[current_index].q_type == 5) {
      // answer checking here
      let ans = this.option[0].answer;
      if (ans == this.answerForm.value.answer) {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 1;
      } else {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 0;
      }
      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else if (this.questionarray[current_index].q_type == 6) {
      let key = 0;
      if (this.sequencyAnswerArray == undefined) {
        this.answerForm.patchValue({
          answer: ''
        })
        this.answer_status = 0;
      } else {

        this.option.forEach((element, i) => {
          this.sequencyAnswerArray = this.sequencyAnswerArray == undefined ? this.sequence_answer : this.sequencyAnswerArray
          if(this.option[element.answer - 1]) {
            if (this.option[element.answer - 1].option == (this.sequencyAnswerArray == undefined ? '' : this.sequencyAnswerArray[i])) {
            } else {
              key++;
            }
          }
        });
        if (key == 0) {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.sequencyAnswerArray)
          })
          this.answer_status = 1;
        } else {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.sequencyAnswerArray)
          })
          this.answer_status = 0;
        }
      }
      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else if (this.questionarray[current_index].q_type == 7) {
      let ans = this.option[0].answer;

      if (ans == this.answerForm.value.answer) {
        this.answer_status = 1;
      } else {
        this.answer_status = 0;
      }
      this.answerForm.patchValue({
        answer: this.answerForm.value.answer
      })
      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else if (this.questionarray[current_index].q_type == 8) {

      // answer checking here
      let ans = this.option[0].answer;
      if (ans == this.answerForm.value.answer) {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 1;
      } else {
        this.answerForm.patchValue({
          answer: this.answerForm.value.answer
        })
        this.answer_status = 0;
      }
      this.saveAnswer(current_index, pre_next_index, qstatus);

    }
    else { // if (this.questionarray[current_index].q_type == 9)
      // answer checking here
      let key = 0;
      console.log("===matching===", this.matchingAnswerArray);
      if (this.matchingAnswerArray != undefined) {
        this.matchingAnswerArray = this.matchingAnswerArray == undefined ? this.matching_answer : this.matchingAnswerArray
        this.matchingAnswerArray.forEach((element, i) => {
          if (element == this.option[i].answer) {

          } else {
            key++;
          }
        });
        if (key == 0) {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.matchingAnswerArray)
          })
          this.answer_status = 1;
        } else {
          this.answerForm.patchValue({
            answer: JSON.stringify(this.matchingAnswerArray)
          })
          this.answer_status = 0;
        }
      }
      this.saveAnswer(current_index, pre_next_index, qstatus);
    }
  }

  questionFormating(next_index, answer) {
    next_index = next_index == -1 ? 0 : next_index;
    this.option = JSON.parse(this.questionarray[next_index].options);
    this.question_score = 0 ;
    if (this.questionarray[next_index].q_type == 1) {

      this.single_answer = '';
      if (answer != null) {
        this.single_answer = answer.answer;
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }
    } else if (this.questionarray[next_index].q_type == 2) {
      console.log("==parse_answer===", answer);
      if (answer != null) {
        this.parse_answer = answer.answer == '' ? '' : JSON.parse(answer.answer);
        this.answerForm.patchValue({
          answer: this.parse_answer
        })
      }else{
        this.parse_answer ='';
      }
    } else if (this.questionarray[next_index].q_type == 3) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }
      console.log("======", this.answerForm.value);
    } else if (this.questionarray[next_index].q_type == 4) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }
    }
    else if (this.questionarray[next_index].q_type == 5) {
      if (answer != null) {
        this.liker_scale_answer = answer.answer == '' ? null : answer.answer;
      }
      console.log("===44444444444444444444444444444==", this.liker_scale_answer);
      this.scale = [];
      let s_s = this.option[0].start_scale;
      let e_s = this.option[0].end_scale;
      for (let i = s_s; i <= e_s; i++) {
        // console.log("========this.scale===",i,s_s,e_s);
        this.scale.push(i);
      }
      console.log("========this.scale===", this.scale);
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }

    }
    else if (this.questionarray[next_index].q_type == 6) {
      let option;
      if (answer != null) {
        this.sequence_answer = answer.answer != '' ? JSON.parse(answer.answer) : '';
        option = answer.answer != '' ? JSON.parse(answer.answer) : '';
        this.sequenceType = [];
        this.option.forEach(element => {
          this.sequenceType.push(element.option);
        });
      } else {
        this.sequence_answer = '';
        this.sequenceType = [];
        this.option.forEach(element => {
          this.sequenceType.push(element.option);
        });
      }


    }
    else if (this.questionarray[next_index].q_type == 7) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }
      console.log("====888====", this.answerForm.value);
    }
    else if (this.questionarray[next_index].q_type == 8) {
      if (answer != null) {
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }
    } else { // if (this.questionarray[next_index].q_type == 9)
      this.left = [];
      this.right = [];
      this.option.forEach(element => {
        this.left.push(element.left_option);
        this.right.push(element.right_option);
      });
      this.matching_answer = '';
      if (answer != null) {
        this.matching_answer = answer.answer == '' ? '' : JSON.parse(answer.answer);
        this.answerForm.patchValue({
          answer: answer.answer
        })
      }

    }
  }

  saveAnswer(current_index, pre_next_index, qstatus) {
    let question_type_meta = this.assessmentInfo.is_congnitive == 1 ? JSON.parse(this.assessmentInfo.question_type_meta) : JSON.parse(this.assessmentInfo.assessment_topic_meta);
    let obtained_mark;
   if(this.assessmentInfo.is_congnitive== 1) {
     if (this.questionarray[current_index].difficulty_level_id == 2) {
       obtained_mark = this.answer_status == 1 ? (this.easy_per_ques_mark).toFixed(3) : 0;
     } else if (this.questionarray[current_index].difficulty_level_id == 4) {
       obtained_mark = this.answer_status == 1 ? (this.medium_per_ques_mark).toFixed(3) : 0;
     } else {
       obtained_mark = this.answer_status == 1 ? (this.hard_per_ques_mark).toFixed(3) : 0;
     }
   }else{
     if(this.assessmentInfo.assessment_type == 2 ) {
       if (this.questionarray[current_index].difficulty_level_id == 2) {
         obtained_mark = this.answer_status == 1 ? (this.congnitive_per_ques_mark).toFixed(3) : 0;
       } else if (this.questionarray[current_index].difficulty_level_id == 4) {
         obtained_mark = this.answer_status == 1 ? (this.congnitive_per_ques_mark).toFixed(3) : 0;
       } else {
         obtained_mark = this.answer_status == 1 ? (this.congnitive_per_ques_mark).toFixed(3) : 0;
       }
     } else {
       obtained_mark = this.question_score;
     }
   }


    if (pre_next_index == this.total_questions) {
      pre_next_index = -1;
    }
    var data = {};

    var time = "00:"+this.assessmentInfo.time+":00"; // If countdown1 is not available, then set the default minutes based on the assesssment time
    if(this.countdown1 !== undefined) {
      time = this.countdown1.i.text;
    }

    var array = time.split(":");
    this.seconds = (parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60) + parseInt(array[2], 10)

    if (this.seconds == 0) {
           // submit test

      this.getAssessmentsTestDetails(this.no_of_attempted);
    }

    this.time_taken = this.countdown - this.seconds - this.all_time;
    this.all_time = this.time_taken + this.all_time;

    // Get individual question mark and store it under assessment_mark for each question
    let ind_question_mark = 0;
    if(this.assessmentInfo.is_congnitive== 1) {

      if (this.questionarray[current_index].difficulty_level_id == 4) {
        ind_question_mark = this.assessmentInfo.hard_ques_marks;
      } else if (this.questionarray[current_index].difficulty_level_id == 5) {
        ind_question_mark = this.assessmentInfo.medium_ques_marks;
      } else {
        ind_question_mark = this.assessmentInfo.easy_ques_marks;
      }
    } else {
      if(this.assessmentInfo.assessment_type == 2 ) {
        if (this.questionarray[current_index].difficulty_level_id == 4) {
          ind_question_mark = this.congnitive_per_ques_mark;
        } else if (this.questionarray[current_index].difficulty_level_id == 5) {
          ind_question_mark = this.congnitive_per_ques_mark;
        } else {
          ind_question_mark = this.congnitive_per_ques_mark;
        }
      } else {
        ind_question_mark = this.question_score;

      }
    }

    if (pre_next_index != -1) {
      data = {
        answer: this.answerForm.value.answer,
        assessment_id: this.assessmentInfo.id,
        customer_assessment_id: this.CustomerAssessmentId,
        question_id: this.questionarray[current_index].id,
        answer_status: this.answer_status,
        time: this.time_taken,//this.countdown1,
        no_of_attempted: this.no_of_attempted,
        pre_next_question_id: this.questionarray[pre_next_index].id,
        first_time: this.first_index,
        assessment_mark: ind_question_mark,
        difficulty_level_id: this.questionarray[current_index].difficulty_level_id,
        task_performance_id: this.assessmentInfo.is_congnitive == 1 ? this.questionarray[current_index].task_performance_id :'',
        mark_obtained: obtained_mark,
        is_skipped: qstatus
      }
    }
    else {
      data = {
        answer: this.answerForm.value.answer,
        assessment_id: this.assessmentInfo.id,
        customer_assessment_id: this.CustomerAssessmentId,
        question_id: this.questionarray[current_index].id,
        answer_status: this.answer_status,
        time: this.time_taken, //this.countdown1,
        no_of_attempted: this.no_of_attempted,
        first_time: this.first_index,
        assessment_mark: ind_question_mark,
        difficulty_level_id: this.questionarray[current_index].difficulty_level_id,
        task_performance_id: this.assessmentInfo.is_congnitive== 1? this.questionarray[current_index].task_performance_id :'',
        mark_obtained: obtained_mark,
        is_skipped: qstatus
        // pre_next_question_id: this.questionarray[pre_next_index].id
      }
    }


    this.apiService.postwithouttoken('saveanswer', data)
      .subscribe(
        result => {
          if (result['success']) {
            let answer = result['nextQuestionInfo']
            // this.last_time_taken = answer.time;
            this.ques_index = pre_next_index;
            // this.first_index = false;
            this.answerForm.patchValue({answer: ""});
            if (pre_next_index == -1) {

              var time = "00:"+this.assessmentInfo.time+":00";
              if(this.countdown1 !== undefined) {
                time = this.countdown1.i.text;
              }
              var array = time.split(":");
              var seconds = (parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60) + parseInt(array[2], 10)
              var countdown =seconds;

              localStorage.setItem('countdown', JSON.stringify(countdown));
              var url = 'assessment-completed/' + this.unique_url + '/' + this.is_mobile;
              this.router.navigate([url]);
            }

            if ((this.ques_index + 1) == this.total_questions) {
              this.submit_button = true;
              this.questionFormating(this.ques_index, answer);
            } else {
              this.submit_button = false;
              this.questionFormating(this.ques_index, answer);
            }
            return;
          } else {
            this.ques_index = pre_next_index;
            let answer = null;
            this.questionFormating(this.ques_index, answer);
            return;
          }
        }
      );
  }



  SelectMultipleAns(option, answer) {
    console.log("--this.option[0].answer---", option, answer, this.option);
    let i = 0;
    let k;
    this.question_score = 0;
    this.multi_answer.forEach(function (value, j) {
      if (value == option.option) {
        i = 1;
        k = j;
        this.scoreCalculate(option,2);

      }
    });
    if (k != undefined) {
      this.multi_answer.splice(k, 1);
    }
    if (i != 1) {
      this.multi_answer.push(option.option);
    }
    let answer_array = [];
    this.option.forEach(element => {
      if (element.answer == true) {
        answer_array.push(element.option)
      }
    });
    console.log("---answer_array---", answer_array);
  }

  scoreCalculate(option,type){
    if(type == 1) {
      this.question_score = option.score ? option.score : 0;
    }else{
      this.question_score += option.score ? option.score : 0;

    }

  }
  SelectSingleAns(option, answer) {
    let check = true;
    this.scoreCalculate(option,1);
    console.log("==option,==", option, answer, answer == true);
    if (answer == true) {
      this.answerForm.patchValue({
        answer: option.option
      })
      this.answer_status = 1;
      console.log("==this.answer_status=", this.answer_status);
    } else {
      this.answerForm.patchValue({
        answer: option.option
      })
      this.answer_status = 0;
      console.log("==thielsewwwwwwwwwwatus=", this.answer_status);
    }
  }

  dropdownAns() {
    let ans;
    this.option.forEach(element => {
      if(this.answerForm.value.answer== element.option) {
        this.scoreCalculate(element, 1);
      }
      if (element.answer == true) {
        ans = element.option;

      }
    });
    if (ans == this.answerForm.value.answer) {
      this.answerForm.patchValue({
        answer: this.answerForm.value.answer
      })
      this.answer_status = 1;
    } else {
      this.answerForm.patchValue({
        answer: this.answerForm.value.answer
      })
      this.answer_status = 0;
    }
  }

  trueFalseAnsMatch(ans) {
    console.log("=========ans======", ans);

    let as = this.option[0].answer;
    if (ans == this.option[0][as]) {
      /*this.answerForm.patchValue({
        answer: 'true'
      })*/
      this.answer_status = 1;
      this.scoreCalculate(this.option[0],1);

      // this.assessment_marks = this.assessment_marks + this.questionarray[this.ques_index].marks
    } else {
      /*this.answerForm.patchValue({
        answer: 'false'
      })*/
      this.answer_status = 0;
      this.scoreCalculate(this.option[0],1);

    }
  }

  likerScaleAnsLine(event) {
    let ans = event.value;

    this.liker_scale_answer = ans;
    this.scoreCalculate(this.option[0],1);
    if (ans == this.answerForm.value.answer) {
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 1;
    } else {
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 0;
    }
  }

  likerScaleAnsPoint(ans) {
    // let ans = this.option[0].answer;
    console.log("======likerScaleAns===", ans, this.liker_scale_answer)
    this.scoreCalculate(this.option[0],1);
    this.liker_scale_answer = ans;
    if (ans == this.answerForm.value.answer) {
      console.log("=====if====");
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 1;
    } else {
      console.log("=====else=====");
      this.answerForm.patchValue({
        answer: ans
      })
      this.answer_status = 0;
    }
  }

  EssayAnswer() {
    this.scoreCalculate(this.option[0],1);
    this.answerForm.patchValue({
      answer: this.answerForm.value.answer
    })
    this.answer_status = 1;
  }

  // get details for submit total marks
  getAssessmentsTestDetails(no_of_attempted) {
    var data = {
      is_final : false,
      unique_url: this.unique_url
    }
    this.apiService.postwithouttoken('getanswerlist', data)
      .subscribe(
        result => {
          if (result['success']) {
            if (result['assessmentInfo'] == undefined || result['questionarray'] == undefined) {
              this.apiService.toastrMsg("Invalid", 'error');
              this.router.navigate(['/']);
            } else {
              const answerArray = result['answerInfo'];
              this.SubmitAssessment(no_of_attempted,answerArray);

            }
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

  SubmitAssessment(no_of_attempted,answerArray) {
    let total_marks_obtained = 0;
    let customer_user_id;
    if(answerArray.length!=0){
      for(let val of answerArray) {
        customer_user_id = val.customer_user_id;
        total_marks_obtained += parseInt(val.mark_obtained);
      }
    }
    var data = {
      unique: this.unique_url,
      assessment_id: this.assessmentInfo.id,
      customer_assessment_id: this.CustomerAssessmentId,
      no_of_attempted: no_of_attempted,
      assessment_mark : this.assessmentInfo.assessment_type == 3 ? total_marks_obtained : this.assessmentInfo.total_mark_of_assessment,
      customer_user_id : customer_user_id,
      total_marks_obtained : total_marks_obtained,
      total_time : this.countdown,
      total_questions : this.total_questions,
      devices_details: this.apiService.getDeviceDetails(),
      test_status: this.test_status

    }

    this.apiService.postwithouttoken('marksupdate', data)
      .subscribe(
        result => {
          if (result['success']) {

            if(this.assessmentInfo.is_congnitive == 1) {
              if (this.test_status != 'Security Break') {
                var url = 'assessment-final/' + this.unique_url;
                this.router.navigate([url]);
              } else {
                window.close();
              }
            }else{
              if (this.test_status != 'Security Break') {
                this.apiService.toastrMsg('You have submitted the assessment successfully', 'success');
                window.close();
              } else {
                // this.apiService.toastrMsg('You have submitted the assessment successfully', 'success');
                window.close();
              }

            }
          }
        },
        fail => {
          this.apiService.toastErrorMsg(fail);
        }
      );
  }

}
